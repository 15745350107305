<!--大屏-关键指标-->
<template>
  <div class="key-indicator">
    <template v-if="isChengdu">
      <el-row>
        <div style="font-size: 16rem;font-weight:bold;float:left;  display: flex;align-items: center;height:40rem;"><img style="width:20rem;margin-right: 3rem;padding-left:10rem;" src="./img/left.png" /><span>事件状态</span></div>
      </el-row>
      <el-row class="box1" v-loading="loading" element-loading-background="rgb(19, 31, 54)">
        <el-col :span="6">
          <div @click="handleTypeChange('待整改')">
            <template v-if ="healthCode == 0">
              <div style="color: #ff8d00;font-weight:bold;font-size: 20rem;text-align: center">0</div>
            </template>
            <template v-else>
            <count-to :startVal='0'
              :endVal='healthCode'
              :duration='healthCode'
              :separator='null'
              style="color:#ff8d00;font-weight:bold;font-size: 18rem;text-align: center"/><span style="font-size:12rem;">件</span>
            </template>
            <div><img style="width:72rem;height:20rem;" src="./img/bing.png" /></div>
            <div style="font-size: 12rem;">待整改</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div @click="handleTypeChange('整改中')">
            <template v-if ="handleOn == 0">
              <div style="color: #ff8d00;font-weight:bold;font-size: 20rem;text-align: center">0</div>
            </template>
            <template v-else>
              <count-to :startVal='0'
                :endVal='handleOn'
                :duration='handleOn'
                :separator='null'
                style="color:  #ff8d00;font-weight:bold;font-size: 18rem;;text-align: center"/><span style="font-size:12rem;">件</span>
            </template>
            <div><img style="width:72rem;height:20rem;" src="./img/bing.png" /></div>
            <div style="font-size: 12rem;">整改中</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div @click="handleTypeChange('整改成功')">
            <template v-if ="handleFinish == 0">
              <div style="color: #ff8d00;font-weight:bold;font-size: 20rem;text-align: center">0</div>
            </template>
            <template v-else>
              <count-to :startVal='0'
                :endVal='handleFinish'
                :duration='handleFinish'
                :separator='null'
                style="color:  #ff8d00;font-weight:bold;font-size: 18rem;;text-align: center"/><span style="font-size:12rem;">件</span>
            </template>
            <div><img style="width:72rem;height:20rem;" src="./img/bing.png" /></div>
            <div style="font-size: 12rem;">整改成功</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div @click="handleTypeChange('执法中')">
            <template v-if ="waitHandle == 0">
              <div style="color: #ff8d00;font-weight:bold;font-size: 20rem;text-align: center">0</div>
            </template>
            <template v-else>
              <count-to :startVal='0'
                :endVal='waitHandle'
                :duration='waitHandle'
                :separator='null'
                style="color:  #ff8d00;font-weight:bold;font-size: 18rem;;text-align: center"/><span style="font-size:12rem;">件</span>
            </template>
            <div><img style="width:72rem;height:20rem;" src="./img/bing.png" /></div>
            <div style="font-size: 12rem;">执法中</div>
          </div>
        </el-col>
      </el-row>
      <el-row>
      <div style="font-size: 16rem;font-weight:bold;float:left;  display: flex;align-items: center;height:40rem;"><img style="width:20rem;margin-right: 3rem;padding-left:10rem;" src="./img/left.png" /><span>在线及达标情况</span></div>
      </el-row>
      <el-row class="box" v-loading="loading" element-loading-background="rgb(19, 31, 53)">
          <el-radio-group size="mini" v-model="dateType" style="float: right;margin-right: 20px">
            <el-radio-button label="日" @click.native="switchData(true)"/>
            <el-radio-button label="月" @click.native="switchData(false)"/>
          </el-radio-group>
        <div id="keyChart" class="key-echarts"/>
      </el-row>
    </template>
    <template v-else>
      <el-row>
        <div style="font-size: 16rem;font-weight:bold;float:left;  display: flex;align-items: center;height:40rem;"><img style="width:20rem;margin-right: 3rem;padding-left:10rem;" src="./img/data.png" /><span>关键指标</span></div>
      </el-row>
      <el-row style="text-align: center" v-loading="loading" element-loading-background="rgb(44, 75, 147)">
        <el-col :span="8">
          <div @click="handleTypeChange('监测点数')">
            <div style="font-size: 14rem;">昨日在线数</div>
            <count-to :startVal='0'
              :endVal='onlineData.length!=0?onlineData[onlineData.length-1].OnlineDeviceCount : 0'
              :duration='onlineData.length!=0 ?onlineData[onlineData.length-1].OnlineDeviceCount+1: 0'
              :separator='null'
              style="color: white;font-weight:bold;font-size: calc(100vw * 25 / 1920);text-align: center"/>

          </div>
        </el-col>
        <el-col :span="8">
          <div @click="handleTypeChange('在线率')">
            <div style="font-size: 14rem;">在线率</div>
            <count-to :startVal='0'
              :endVal='onlineData.length!=0 ?onlineData[onlineData.length-1].OnlinePercent: 0'
              :duration='onlineData.length!=0 ?onlineData[onlineData.length-1].OnlinePercent+1: 0'
              :separator='null'
              :decimals=2
              suffix="%"
              style="color: #2ee3ff;font-weight:bold;font-size: calc(100vw * 25 / 1920);text-align: center"/>
          </div>

        </el-col>
        <el-col :span="8">
          <div @click="handleTypeChange('达标率')">
            <div style="font-size: 14rem;">达标率</div>
            <count-to :startVal='0'
              :endVal='onlineData.length!=0?onlineData[onlineData.length-1].ComplianceDevicePercentage:0'
              :duration='onlineData.length!=0?onlineData[onlineData.length-1].ComplianceDevicePercentage+1:0'
              :separator='null'
              :decimals=2
              suffix="%"
              style="color: #06b204;font-weight:bold;font-size: calc(100vw * 25 / 1920);text-align: center"/>
          </div>
        </el-col>
      </el-row>
      <el-row style="padding-top: 15px" v-loading="loading" element-loading-background="rgb(44, 75, 147)">
        <div>
          <span class="key-title">在线及达标情况</span>
          <el-radio-group size="mini" v-model="dateType" style="float: right;margin-right: 20px">
            <el-radio-button label="日" @click.native="switchData(true)"/>
            <el-radio-button label="月" @click.native="switchData(false)"/>
          </el-radio-group>
        </div>
        <div id="keyChart" class="key-echarts"/>
      </el-row>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import countTo from "vue-count-to";
import { Chart } from '@antv/g2';
import {
  getUserInfo
} from '@/util/index'

export default {
  name: 'keyIndicator',
  props: {
    sumData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    hourData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    eventData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    sumData: {
      handler: function () {
        this.loading = true
        this.getData()
      },
      deep: true
    },
    hourData: {
      handler: function () {
        this.getData()
      },
      deep: true
    },
    eventData: {
      handler: function () {
        this.getData()
      },
      deep: true
    }
  },
  components: {
    countTo
  },
  data() {
    return {
      key: 1,
      dateType: '日',
      chart: null,
      showDaysData: false,
      onlineData: [],
      onlineReachDataHours: [],
      loading: true,
      isChengdu: false,
      handleOn: 0,
      handleFinish: 0,
      healthCode: 0,
      waitHandle: 0,
    }
  },
  created() {
    this.user = getUserInfo()
    if (this.user.Info && this.user.Info.pid && this.user.Info.pid.slice(0,6) == "/1199/") {
      this.isChengdu = true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getData();
      moment.locale('zh-cn');
    })
  },
  methods: {
    getData() {
      if (this.chart == null) {
        this.chart = new Chart({
          container: document.getElementById('keyChart'),
          autoFit: true
        })
      }
      if (this.hourData) {
        this.onlineReachDataHours = this.hourData.onlineAndComplianceDeviceCountByHour
      }
      if (this.sumData) {
        this.onlineData = this.sumData.onlineDeviceCountByDate
        this.init();
        this.loading = false
      }
      if (this.eventData) {
        this.handleFinish = this.eventData.handle_finish_number
        this.handleOn = this.eventData.handle_on_number
        this.healthCode = this.eventData.handle_wait_number
        this.waitHandle = this.eventData.handle_force_number
      }
    },
    init() {
      if (this.chart) {
        this.setMonthData()
      }
    },
    setDaysData() {
      if (this.showDaysData && this.chart) {
        let dateData = []
        //处理数据
        for (const onlineDataKey in this.onlineReachDataHours) {
          let onlineData = {}
          let reachData = {}
          try {
            //每日在线率
            onlineData.CalculateDate = this.onlineReachDataHours[onlineDataKey].CalculateDate
            onlineData.type = '在线率'
            onlineData.value = this.onlineReachDataHours[onlineDataKey].OnlinePercent
            //每日达标率
            reachData.CalculateDate = this.onlineReachDataHours[onlineDataKey].CalculateDate
            reachData.type = '达标率'
            reachData.value = this.onlineReachDataHours[onlineDataKey].ComplianceDevicePercentage
            dateData.push(onlineData)
            dateData.push(reachData)
          } catch (e) {
            console.error(e)
          }

        }
        //绑定图表数据
        this.chart.data(dateData)
        //设定数据范围
        this.chart.scale({
          value: {
            nice: true
          }
        })
        this.chart.tooltip({
          showCrosshairs: true,
          shared: true,
        })
        this.chart
          .line()
          .position('CalculateDate*value')
          .tooltip('type*value', (name, value) => {
            return {
              name: name,
              value: value + '%'
            }
          })
          .color('type')
          .shape('smooth');
        this.chart
          .point()
          .position('CalculateDate*value')
          .tooltip(false)
          .color('type')
          .shape('smooth');
        this.chart.render();
        this.loading = false
        this.key++
      }
    },
    setMonthData() {
      this.dateType = '月'
      if (this.chart) {
        let dateData = []
        for (const onlineDataKey in this.onlineData) {
          let onlineData = {}
          let reachData = {}
          if (this.onlineData.length !== 0) {
            onlineData.CalculateDate = this.onlineData[onlineDataKey].CalculateDate
            onlineData.type = '在线率'
            onlineData.value = this.onlineData[onlineDataKey].OnlinePercent
            dateData.push(onlineData)
          }
          if (this.onlineData.length !== 0) {
            reachData.CalculateDate = this.onlineData[onlineDataKey].CalculateDate
            reachData.type = '达标率'
            reachData.value = this.onlineData[onlineDataKey].ComplianceDevicePercentage
            dateData.push(reachData)
          }
        }
        //绑定图表数据
        this.chart.data(dateData)
        //设定数据范围
        this.chart.scale({
          value: {
            nice: true
          }
        })
        this.chart.legend('type', {
          itemName: {
            style: {
              fill: '#f1f1f1',
            }
          },
        });
        this.chart.axis('value', {
          label: {
            style: {
              fill: '#f1f1f1',
            },
          },
        });
        this.chart.axis('CalculateDate', {
          label: {
            style: {
              fill: '#f1f1f1',
            },
          },
        });
        this.chart.tooltip({
          showCrosshairs: true,
          shared: true,
        })
        this.chart
          .line()
          .position('CalculateDate*value')
          .tooltip('type*value', (name, value) => {
            return {
              name: name,
              value: value + '%'
            }
          })
          .color('type')
          .shape('smooth');
        this.chart
          .point()
          .position('CalculateDate*value')
          .tooltip(false)
          .color('type')
          .shape('smooth');
        this.chart.render();
        this.loading = false
        this.key++
      }
    },
    switchData(showDaysData) {
      this.loading = true
      this.showDaysData = showDaysData
      if (this.showDaysData) {
        this.setDaysData()
      } else {
        this.setMonthData()
      }
    },
    handleTypeChange(type) {
      this.$emit('type-change', type)
    },
  }
}
</script>

<style lang="scss">
.box1{
  background: linear-gradient(#19253e, #223e61);  
  border-radius:16px;
  text-align: center;
  display: flex;
  align-items: center;
  height: 80px;
  margin-top: 5px;
}
.box1:hover {
  box-shadow: inset 0 0 32px #25a6ff;
}
.box{
  margin-top: 5px;
  padding-top: 15px;
  background: linear-gradient(#19253e, #223e61);  
  border-radius:16px;
  .el-radio-button__inner:focus.is-checked,
  .el-radio-button__inner.is-checked {
    border-color: #104D88 !important; /* 修改边框颜色 */
    box-shadow: -1px 0 0 0 #104D88 inset !important; /* 修改内阴影颜色 */
    color: #104D88 !important; /* 修改文本颜色 */
  }
  
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: #104D88 !important; /* 修改背景颜色 */
    border-color: #104D88 !important; /* 修改边框颜色 */
  }

}
.box:hover {
  box-shadow: inset 0 0 32px #25a6ff;
}
.key-indicator {
  box-sizing: border-box;
  width: 100%;
  z-index: 5;
}

.key-title {
  padding-left: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 16rem;
  font-weight:bold;
}

.key-echarts {
  padding-top: 40px;
  height: 190px;
  width: 100%;
}


</style>
