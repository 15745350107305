<template>
  <div class="wrapper">
    <div class="echarts" id="chartMap" ref="chartMap"></div>
  </div>
</template>

<script>
import {getUserInfo} from "@/util/index";
import {mapState} from 'vuex'
import greenCircle from '@/asset/img/MapIcon/SmallGreen.png'
import redCircle from '@/asset/img/MapIcon/SmallRed.png'
import orangeCircle from '@/asset/img/MapIcon/ExceedOrange.png'
import blackCircle from '@/asset/img/MapIcon/SmallBlack.png'
import greyCircle from '@/asset/img/MapIcon/SmallGrey.png'
import {gxxq, gxnq, gxdq,tfxq,gxq} from '../../assets/gaoxinqu.js'

import decBlockTitle from '../decorations/blockTitle2'
import {defineLocale} from 'moment'

const iconSize = new AMap.Size(12, 12)
const largeIconSize = new AMap.Size(14, 14)
const greenCircleIcon = new AMap.Icon({
  size: iconSize,
  image: greenCircle,
  imageSize: iconSize
})

const redCircleIcon = new AMap.Icon({
  size: iconSize,
  image: redCircle,
  imageSize: iconSize
})

const orangeCircleIcon = new AMap.Icon({
  size: largeIconSize,
  image: orangeCircle,
  imageSize: largeIconSize
})

const greyCircleIcon = new AMap.Icon({
  size: largeIconSize,
  image: greyCircle,
  imageSize: largeIconSize
})

const blackCircleIcon = new AMap.Icon({
  size: largeIconSize,
  image: blackCircle,
  imageSize: largeIconSize
})

const infoWindow = new AMap.InfoWindow({
  isCustom: true,
  content: null,
  anchor: 'top-left',
  offset: new AMap.Pixel(16, -45),
  closeWhenClickMap: true
})

export default {
  name: 'dotMapDistrict',
  components: {
    decBlockTitle
  },
  props: {
    contentData: {
      type: Object,
      default: () => {
        return {
          areaId: null,
          code: ''
        }
      }
    },
    isShowExceed: {
      type: Boolean
    },
    isShowEmphasisDot: {
      type: Object
    },
    onlineDevice: {
      type: Object,
      default: () => {
        return {}
      }
    },
    countyLevelData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      myCharts: null,
      aliMap: null,
      map: {},
      parentJson: [],
      areaPath: [],
      geoJsonData: {},
      mapData: [],
      parentCode: ['100000'],
      dotColor: ['#FF001A', '#78ff79', '#FEFF65', '#AEAEAE', '#000000'], //红 绿色 黄色 灰色 黑色
      config: {
        data: [],
        showValue: true,
        colors: ['#37a2da']
      },
      dotsCollection: [],
      key: 1,
      showMore: false,
      tableKey: 1,
      lat: '',
      lng: '',
    }
  },
  mounted() {

  },
  computed: {
    ...mapState(['topBoardData'])
  },
  methods: {
    addPolygon(data) {
      this.aliMap = new AMap.Map('chartMap', {
        resizeEnable: true,
        center: [104.18554097414017,30.32420946420142],
        mapStyle: 'amap://styles/db776e87dd712097ad645ad2fbf86a6b'
      })
      let polygon = new AMap.Polygon({
        path: data,
        strokeColor: '#CC66CC',
        fillOpacity: 0.2,
        fillColor: '#CCF3FF',
        strokeOpacity: 1,
        strokeWeight: 1,
      });
      this.aliMap.add(polygon);
      this.aliMap.setFitView()
      this.drawAliMap()
    },
    initData() {
      this.getData()
      var user = getUserInfo().Info
      if (user && user.name && user.name == "成都高新区在线油烟监管") {
        this.addPolygon(gxq);
      } else {
        if (this.contentData.code == "gxnq") {
          this.addPolygon(gxnq);
        } else if (this.contentData.code == "gxxq") {
          this.addPolygon(gxxq);
        } else if (this.contentData.code == "gxdq") {
          this.addPolygon(gxdq);
        } else if ((this.contentData.code == "tfxq")) {
          this.addPolygon(tfxq);
        } else {
          this.initAliMap(this.contentData.code)
        }
      }

    },
    calcTableHeight() {
      this.$nextTick(() => {
        if (this.$refs.table) {
          this.tableHeight = this.$refs.table.$el.parentElement.offsetHeight;
        }
      })
    },
    showDot(type) {
      this.dotsCollection.forEach(marker => {
        if (type == '0') { //全部显示
          marker.show()
        } else if (type == '1') { //显示红码
          if (marker.getExtData().HealthCodeColor == "Red") {
            marker.show()
          } else {
            marker.hide()
          }
        } else if (type == '2') { //显示绿码
          if (marker.getExtData().HealthCodeColor == "Green") {
            marker.show()
          } else {
            marker.hide()
          }
        } else if (type == '8') { //显示绿码
          if (marker.getExtData().HealthCodeColor == "Yellow") {
            marker.show()
          } else {
            marker.hide()
          }
        } else if (type == '3') { //显示在线
          if (marker.getExtData().Status != "OFF" && marker.getExtData().Status != "DOWN") {
            marker.show()
          } else {
            marker.hide()
          }
        } else if (type == '4') { //显示达标
          if (marker.getExtData().Status == "NORMAL") {
            marker.show()
          } else {
            marker.hide()
          }
        }  else {  //显示超标
          if (marker.getExtData().Status == "EXCESS") {
            marker.show()
          } else {
            marker.hide()
          }
        }
      }) 
    },
    eventLocaleShow(mn){
      var exist = false
      this.dotsCollection.forEach(marker => {
        const targetMn = marker.getExtData().Mn
        if (targetMn === mn) {
          exist = true
          infoWindow.setContent(marker.getExtData().Content)
          marker.show()
          infoWindow.open(this.aliMap, marker.getPosition())
        } else {
          marker.hide()
        }
      }) 
      if (!exist) {
        infoWindow.setContent()
      }
    },
    
    getDistance(lat1, lng1, lat2, lng2) {
      let EARTH_RADIUS = 6378.137;
      let radLat1 = lat1 * Math.PI / 180.0; 
      let radLat2 = lat2 * Math.PI / 180.0;
      let a = radLat1 - radLat2;
      let b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
      let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
      s = s * EARTH_RADIUS; 
      s = Math.round(s * 10000) / 10000;
      return s.toFixed(2)
    },

    initAliMap(areaCode) {
      const that = this
      that.aliMap = new AMap.Map('chartMap', {
        resizeEnable: true,
        mapStyle: 'amap://styles/db776e87dd712097ad645ad2fbf86a6b'
      })
      const polygon = new AMap.Polygon({
        strokeWeight: 1,
        strokeColor: '#CC66CC',
        fillOpacity: 0.2,
        fillColor: '#CCF3FF',
        zIndex: 50,
      })
      // done用于district.search异步操作
      const done = function () {
        // 地图自适应
        that.aliMap.add(polygon)
        that.aliMap.setFitView()
        that.drawAliMap()
      }
      let district
      AMap.plugin('AMap.DistrictSearch', function () {
        // 创建行政区查询对象
        district = new AMap.DistrictSearch({
          // 返回行政区边界坐标等具体信息
          extensions: 'all',
          // 设置查询行政区级别为 区
          level: areaCode.length <= 6?'district':'biz_area',
        })
      })
      district.search(areaCode.substring(0, 6), function (status, result) {
        // 获取区的边界信息
        if(result.districtList) {
          that.lat = result.districtList[0].center["lat"]
          that.lng = result.districtList[0].center["lng"]
          polygon.setPath(result.districtList[0].boundaries)
        } else {
          that.lat = ""
          that.lng = ""
        }
        done()
      })
      console.log('aliMap done!')
    },
    stoveNumFormatter(StoveNum) {
      let r
      if (StoveNum > 6) {
        r = '大'
      } else if (StoveNum > 3) {
        r = '中'
      } else {
        r = '小'
      }
      return r
    },
    dotColorAndStatusFormatter(Status) {
      let r = []
      switch (Status) {
        case 'NORMAL':
          r = [1, '达标']
          break
        case 'EXCESS':
          r = [1, '超标']
          break
        case 'DOWN':
          r = [3, '离线']
          break
        case 'OFF':
          r = [4, '异常离线']
          break
        case 'ALARM':
          r = [1, '预警']
          break
        default:
          r = [4, '未知状态']
      }
      return r
    },
    dotColorStatusExeFormatter(Status) {
      var r = 3
      switch (Status) {
        case 'NORMAL':
          r = 1
          break
        case 'EXCESS':
          r = 0
          break
        case 'DOWN':
          r = 3
          break
        case 'OFF':
          r = 4
          break
        case 'ALARM':
          r = 2
          break
      }
      return r
    },
    dotColorFormatter(Status) {
      var r = blackCircleIcon
      switch (Status) {
        case 'NORMAL':
          r = greenCircleIcon
          break
        case 'EXCESS':
          r = redCircleIcon
          break
        case 'DOWN':
          r = greyCircleIcon
          break
        case 'OFF':
          r = blackCircleIcon
          break
        case 'ALARM':
          r = greenCircleIcon
          break
      }
      return r
    },
    drawAliMap() {
      const that = this
      const deviceDetail = this.onlineDevice && this.onlineDevice.listDevice && this.onlineDevice.listDevice.length > 0?this.onlineDevice.listDevice:  []
      for (var i = 0; i < deviceDetail.length; i++) {
        //剔除超范围的经纬度
        if (this.lat != "" && this.lng != "") {
          var dist = this.getDistance(this.lat, this.lng, deviceDetail[i].Lat,deviceDetail[i].Lng)
          if (dist > 50) {
            continue;
          }
        }
        var item = deviceDetail[i]
        var healthStatusColor = that.dotColor[this.dotColorStatusExeFormatter(item.Status)]
        var healthColor = item.HealthCodeColor === 'Green' ? '#78ff79' :  (item.HealthCodeColor  === 'Red' ? '#FF001A': '#FEFF65')
        var scale = that.stoveNumFormatter(item.StoveNum)
        var r = that.dotColorAndStatusFormatter(item.Status)
        var intimeColor = that.dotColor[r[0]]
        var content = `
          <div style="border:1px solid #03608C;padding:8rem 16rem;background-color:#012954;opacity:0.8;font-size: 14rem;min-width: 240rem;max-width:300rem;">
            <p>
            <span style="color:#c7c7cc">店铺名称：</span>
            <span style="color:#c7c7cc">${item.CustomerName}</span>
            </p>
            <p>
              <div style="display:inline-block;color:#c7c7cc">实时状态：</div>
              <div style="width:10px;height:10px;background-color:${intimeColor};display:inline-block;border-radius:50%;margin-right:5px"></div>
              <span style="color:#c7c7cc">(${r[1]})</span>
              <div style="display:inline-block;color:#c7c7cc">排放状态码：</div>
              <div style="width:10px;height:10px;background-color:${healthStatusColor};display:inline-block;border-radius:50%;margin-right:5px"></div>
            </p>
            <p>
              <div style="display:inline-block;color:#c7c7cc">红绿码状态：</div>
              <div style="width:10px;height:10px;background-color:${healthColor};display:inline-block;border-radius:50%;margin-right:5px"></div>
            </p>
            <p>
              <span style="color:#c7c7cc">店铺规模：</span>
              <span style="color:#c7c7cc">${scale}</span>
              <span style="margin-left:4px;color:#c7c7cc">灶头数：</span>
              <span style="color:#c7c7cc">${item.StoveNum}</span>
            </p>
            <p>
              <span style="color:#c7c7cc;">联系人：</span>
              <span style="color:#c7c7cc;">${item.CustomerName}</span>
            </p>
            <p>
              <span style="margin-left:4px;color:#c7c7cc">联系电话：</span>
              <span style="color:#c7c7cc">${item.CustomerMobile}</span>
            </p>
            <h5 style="color:#c7c7cc;font-size:14px;margin:5px 0 0;">监测点信息</h5>
            <p>
              <span style="color:#c7c7cc">监测点名称：</span>
              <span style="color:#c7c7cc">${item.LocaleName}</span>
            </p>
            <p>
              <span style="color:#c7c7cc">所属单位：</span>
              <span style="color:#c7c7cc">${item.OwnerName}</span>
            </p>
            <p>
              <span style="color:#c7c7cc">菜系：</span>
              <span style="color:#c7c7cc">${item.CuisineName}</span>
            </p>
            <p>
              <span style="color:#c7c7cc">地址：</span>
              <span style="color:#c7c7cc">${item.Addr}</span>
            </p>
            <p>
              <span style="color:#c7c7cc">设备：</span>
              <span style="color:#c7c7cc">${item.MN}</span>
            </p>
          </div>
        `
        const marker = new AMap.Marker({
          position: [item.Lng, item.Lat],
          zIndex: 1,
          icon:  this.dotColorFormatter(item.Status),
          extData: {
            Status: item.Status,
            HealthCodeColor: item.HealthCodeColor,
            Mn: item.MN,
            Content: content
          }
        })
        that.dotsCollection.push(marker)
        marker.on('mouseover', function (content) {
          return  function () {
            infoWindow.setContent(content)
            infoWindow.open(that.aliMap, marker.getPosition())
          }
        }(content))

        marker.on('mouseout', function (content) {
          return  function () {
            infoWindow.setContent("")
            infoWindow.open(that.aliMap, marker.getPosition())
          }
        }(content))


        marker.setMap(that.aliMap)
      }
      if (that.lng != "" && that.lat != "") {
        that.aliMap.setCenter([that.lng, that.lat])
      }
    },
    getData() {
      const installData = []
      const complianceData = []
      const dataMap = complianceData.map((item) => {
        installData.map((vo) => {
          if (item.K.split('/').join('') === vo.K.split('/').join('')) {
            item.name = vo.N
            item.value = item.V
          }
        })
        return item
      })
      this.$set(this.config, 'data', dataMap)
      this.key++
    },
    boardDataChange(data) {
      this.$store.commit('setNewBoardData', {res: data, dataObj: this.contentData})
    },
  },
  watch: {
    onlineDevice: {
      handler: function () {
        this.initData()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.echarts {
  width: 100%;
  height: 100%;
  position: relative;
}

.large {
  width: 23vw;
  height: 38vh;
}

.exceed_content {
  position: absolute;
  bottom: 180rem;
  right: 440rem;
  font-size: 12px;
  color: #c7c7cc;
  min-width: 12vw;
  background: #011d24;
  opacity: 0.8;
  background: rgba(21, 55, 135, 0.8);

  .ul_head {
    margin: 0;
    padding: 0;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #c7c7cc;
    }

    .li_item {
      display: flex;
      justify-content: space-around;
      height: 4vh;
      line-height: 4vh;

      span {
        text-align: center;
        display: inline-block;
      }

      .item1 {
        width: 40%;
      }

      .item2,
      .item3 {
        width: 30%;
      }

      .item-head {
        cursor: pointer;
      }
    }
  }

  .ul_cont {
    margin: 0;
    padding: 0;
    height: 28vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #c7c7cc;
    }

    .li_item {
      display: flex;
      justify-content: space-around;
      height: 4vh;
      line-height: 4vh;

      span {
        text-align: center;
        display: inline-block;
      }

      .item1 {
        width: 40%;
      }

      .item2,
      .item3 {
        width: 40%;
      }
    }
  }

  .exceed_content:hover {
    cursor: pointer;
  }
}
.el-table {
  opacity: 0.8;
  /deep/ .el-table__body-wrapper::-webkit-scrollbar {
    width: 7px; /*滚动条宽度*/
    height: 10px; /*滚动条高度*/
  }

  /*定义滚动条轨道 内阴影+圆角*/
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: 0px 1px 3px #071e4a inset; /*滚动条的背景区域的内阴影*/
    border-radius: 10px; /*滚动条的背景区域的圆角*/
    background-color: #071e4a; /*滚动条的背景颜色*/
  }
  
  /*定义滑块 内阴影+圆角*/
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
    box-shadow: 0px 1px 3px rgb(21, 55, 135) inset; /*滚动条的内阴影*/
    border-radius: 10px; /*滚动条的圆角*/
    background-color: rgb(21, 55, 135); /*滚动条的背景颜色*/
  }
}
</style>
