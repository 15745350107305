<template>
  <div class="region_compare_dis" :class="isChengdu?'box_compare':''">
    <span class="compare-title">
      <template v-if="isChengdu">
        <div style="font-size: 16rem;font-weight:bold;float:left;  display: flex;align-items: center;height:40rem;"><img style="width:20rem;margin-right: 3rem;padding-left:10rem;" src="./img/left.png" /><span>报警率</span></div>
      </template>
      <template v-else>
        <template v-if="isDevice">
          <div style="font-size: 16rem;font-weight:bold;float:left;  display: flex;align-items: center;height:40rem;"><img style="width:20rem;margin-right: 3rem;padding-left:10rem;" src="./img/per.png" /><span>企业类型对比</span></div>
        </template>
        <template v-else>
          <div style="font-size: 16rem;font-weight:bold;float:left;  display: flex;align-items: center;height:40rem;"><img style="width:20rem;margin-right: 3rem;padding-left:10rem;" src="./img/record.png" /><span>报警次数对比</span></div>
        </template>
      </template>
    </span>
    <template v-if="isChengdu">
      <div ref="chartBar" class="compare-charts"   v-loading="loading" element-loading-background="rgb(19, 31, 54)"/>
    </template>
    <template v-else>
      <div id="alarmEcharts" class="compare-charts" v-loading="loading" element-loading-background="rgb(44, 75, 147)"></div>
    </template>
  </div>
</template>

<script>
import echarts from 'echarts'
import decBlockTitle from '../decorations/blockTitle2'
import {Chart,Util} from "@antv/g2";
import {mapState} from "vuex";
import {
  getUserInfo, isEmptyObj
} from '@/util/index'

export default {
  name: "alarmCompare",
  components: {
    decBlockTitle,
  },
  props: {
    countyData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    streetData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    countyData: {
      handler: function (val) {
        this.getData()
      },
      deep: true
    },
    streetData: {
      handler: function (val) {
        this.getData()
      },
      deep: true
    }
  },
  created() {
    this.user = getUserInfo()
    if (this.user.Info && this.user.Info.pid && this.user.Info.pid.slice(0,6) == "/1199/") {
      this.isChengdu = true
    }
  },
  computed: {
    ...mapState(['bigScreenDataStack'])
  },
  mounted() {
    this.getData()
  },
  data() {
    return {
      option: {},
      charts: null,
      barData: [],
      mainData: [],
      mainSum: 0,
      subSum: 0,
      isDevice: false,
      subData: [],
      loading: true,
      isChengdu: false,
    };
  },
  methods: {
    getData() {
      this.loading = true
      if (this.isChengdu) {
        if (isEmptyObj(this.countyData) || isEmptyObj(this.streetData))  {
          return
        }
        this.initLine()
      } else {
        if (isEmptyObj(this.countyData)) {
          return
        }
        this.isDevice = false
        if (this.countyData.alarmData) {
          this.barData = this.countyData.alarmData
        }
        if (this.countyData.enterprise) {
          for(var i = 0; i < this.countyData.enterprise.length; i++) {
            this.countyData.enterprise[i].Number = this.countyData.enterprise[i].V
            this.countyData.enterprise[i].Name = this.countyData.enterprise[i].K
          }
          this.barData = this.countyData.enterprise
          this.isDevice = true
        }
        this.$nextTick(() => {
          if (this.charts != null) {
            this.charts.clear()
          } else {
            this.charts = new Chart({
              container: 'alarmEcharts',
              autoFit: true,
              padding: 10
            })
          }
          this.init()
        })
      }
    },
    initLine() {
      var myChart = echarts.init(this.$refs.chartBar)
      for (var j = 0; j < this.countyData.alarmData.length; j++) {
        this.countyData.alarmData[j].OnlineDeviceCount = 0
        for (var i = 0; i < this.streetData.streetData.length; i++) {
          if (this.streetData.streetData[i].AreaId == this.countyData.alarmData[j].AreaId) {
            this.countyData.alarmData[j].OnlineDeviceCount = this.streetData.streetData[i].OnlineDeviceCount
            break
          }
        }
      }
      var dataStyle = {
        normal: {
          label : {
              show: true,
              formatter: '{c}%'
          }
        }
      };
      this.option = {
          tooltip : {
            trigger: 'axis',
            axisPointer : {
              type : 'shadow'
            }
          },
          legend: {
            data:['报警率', '合格率']
          },
          calculable : true,
          xAxis : [
            {
              min: 0,
              max: 100,
              type : 'value',
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false // 这里设置 X 轴分割线不显示
              },
              axisLabel: {
                formatter: '{value} %',
                color:'#D0D0D0'
              },
            }
          ],
          yAxis : [
            {
              axisLine: {
                lineStyle:{
                  color: '#324D4D',
                }
              },
              axisTick: {
                alignWithLabel: true,
                lineStyle:{
                  color: '#324D4D',
                }
              },
              axisLabel: {
                color:'#D0D0D0'
              },
              type : 'category',
              data : this.countyData.alarmData.filter((item, index) => index < 4).map(item => {
                return item.Name
              }),
            }
          ],
          series : [
            {
              name:'报警率',
              type:'bar',
              itemStyle : dataStyle,
              stack: 'total',
              data: this.countyData.alarmData.filter((item, index) => index < 4).map(item => {
                return item.OnlineDeviceCount == 0 ? 0: (item.Number * 100 / item.OnlineDeviceCount).toFixed(2)
              }),
              color: [
                '#E57C37',
              ]
            },
            {
              name:'合格率',
              type:'bar',
              itemStyle : dataStyle,
              stack: 'total',
              data: this.countyData.alarmData.filter((item, index) => index < 7).map(item => {
                return item.OnlineDeviceCount == 0 ? 100 : ((item.OnlineDeviceCount - item.Number) * 100 / item.OnlineDeviceCount).toFixed(2)
              }),
              color: [
                '#5EBCC7',
              ]
            },
          ],
          legend: {
            textStyle: {
              color: '#D0D0D0'
            }
          },
          grid: {
            left: 10,
            top: 30,
            right: 20,
            bottom: 10,
            containLabel: true
          }
      };
      myChart.setOption(this.option, true)
      this.loading = false
    },
    init() {
      this.mainData = []
      this.mainSum = 0
      if (!this.barData) {
        return false
      }
      this.charts.coordinate('theta', {
        radius: 0.75,
      });
      for (let i = 0; i < this.barData.length; i++) {
        if (this.barData[i].Number > 0) {
          this.mainSum += this.barData[i].Number
        }
      }
      for (let i = 0; i < this.barData.length; i++) {
        if (this.barData[i].Number > 0) {
          this.mainData.push({
            value: this.barData[i].Number,
            name: this.barData[i].Name,
            tem: (this.barData[i].Number / this.mainSum * 100).toFixed(2)
          })
        }
      }
      this.drawChart(this.dataType)
    },
    drawChart(type) {
      let sum
      this.charts.clear()
      this.charts.data(this.mainData)
      sum = this.mainSum
      this.charts.scale('value');
      this.charts.tooltip({
        position: 'right',
        showTitle: true,
        containerTpl: `<div class="g2-tooltip" ><p class="g2-tooltip-title"></p><ul class="g2-tooltip-list"></ul></div>`,
        itemTpl: `
        <ul class="g2-tooltip-list">
          <li class="g2-tooltip-list-item">
            <span class="g2-tooltip-name">报警总数</span>:<span class="g2-tooltip-value">{value}</span>
          </li>
          <li class="g2-tooltip-list-item">
            <span class="g2-tooltip-name"></span>报警占比<span class="g2-tooltip-value">{tem}%</span>
          </li>
        </ul>
        `,
      });
      this.charts.legend(false)
      this.charts
        .interval()
        .position('value')
        .color('name', [
          "#a0d911",
          "#eb2f95",
          "#ef4764",
          "#6235ff",
          "#b620e0",
          "#ff8c00",
          "#facc14",
          "#3f8ffd",
          "#11c2c1",
        ])
        .label('value', {
          layout: [{type: 'pie-spider'}, {type: 'hide-overlap'}, {type: 'limit-in-plot', cfg: {action: 'ellipsis'}}],
          labelHeight: 15,
          content: (data) => {
            return `${data.name}(${data.tem}%)`;
          },
          labelStyle: {
            style: {
              lineWidth: 1
            }
          },
          style: {
            fill: '#ffffff'
          }
        })
        .state({
          active: {
            style: (element) => {
              const shape = element.shape;
              return {
                matrix: Util.zoom(shape, 1.1),
              }
            }
          }
        })
        .adjust('stack')
        .tooltip('name*value*tem', function (name,value,tem) { // tooltip的第一个参数写上需要显示的字段'item1*item2*...'；第二个参数为一个函数，该函数的参数为需要显示的字段。
          return {
            name: name,
            value: value,
            tem:  tem
          }
        })
      this.charts.interaction('element-active');
      this.charts.render();
      this.loading = false
    }
  },

};
</script>

<style lang="scss">
.region_compare_dis {
  width: 100%;
  box-sizing: border-box;
  margin-top: 10rem;
  padding-top: 10rem;
  margin-bottom: 10rem;
}

.compare-title {
  padding-left: 20px;
  padding-top: 10px;
  font-size: 16rem;
  font-weight:bold;
}

.compare-charts {
  height: 213rem;
}

.box_compare{
  background: linear-gradient(#19253e, #223e61);
  border-radius:16px;
}
.box_compare:hover {
  box-shadow: inset 0 0 32px #25a6ff;
}

</style>
