<template>
  <div class="center_block">
    <div class="map_block">
      <template v-if="contentData.level === 'country'|| contentData.level === 'province' || contentData.level ==='city'">
        <dot-map
          :content-data="contentData"
          :online-device="onlineDevice"
          :top-list="topList"
          :sum-data="sumData"
          :board-list="boardList"
          :isShowExceed="isShowExceed"
          :isShowEmphasisDot="emphasisSomeDot"
          @echartsMapClick="areaCodeChange"
        ></dot-map>
      </template>
      <template v-else>
        <dot-map-district
          ref="eventLocaleShow"
          :content-data="contentData"
          :online-device="onlineDevice"
          :isShowExceed="isShowExceed"
          :isShowEmphasisDot="emphasisSomeDot"
        ></dot-map-district>
      </template>
    </div>
  </div>
</template>

<script>
import dotMap from './sctterMap'
import dotMapDistrict from './sctterMapQu'
import {mapState} from 'vuex'
import {isEmptyObj} from "@/util";

export default {
  name: 'center',
  components: {
    dotMap,
    dotMapDistrict
  },
  props: ['contentData', 'sumData', 'onlineDevice', 'topList', 'boardList', 'countyLevel'],
   watch: {
    sumData: {
      handler: function () {
        this.init()
      },
      deep: true
    },
    onlineDevice: {
      handler: function () {
        this.init()
      },
      deep: true
    },
    topList: {
      handler: function () {
        this.init()
      },
      deep: true
    },
    boardList: {
      handler: function () {
        this.init()
      },
      deep: true
    }
  },
  data() {
    return {
      timer: null, // 时间对象
      curArea: null, // 当前区域
      isShowExceed: false,
      emphasisSomeDot: {
        show: false,
        type: ''
      },
      showHoursData0: 0,
      showHoursData1: 0,
      loading: true
    }
  },
  computed: {
    ...mapState(['districtInfoType'])
  },
  methods: {
    init() {
      this.loading = true
      if (isEmptyObj(this.sumData) || isEmptyObj(this.onlineDevice) || isEmptyObj(this.topList) || isEmptyObj(this.boardList)){
        return
      }
      this.loading = false
    },
    showEventData(mn){
      this.$refs.eventLocaleShow.eventLocaleShow(mn)
    },
    showPointData(type) {
      this.$refs.eventLocaleShow.showDot(type)
    },
    switchEmphasisDot(type, e) {
      const centerTop = document.getElementById('center-top')
      const clickedList = centerTop.getElementsByClassName('switch-emphasis-dot clicked')
      const clickableList = centerTop.getElementsByClassName('clickable')
      const index = Array.from(clickableList).indexOf(e.currentTarget)
      for (let i = 1; i < clickableList.length; i++) {
        if (i !== index) {
          clickableList[i].classList.remove('clicked')
        }
      }
      e.currentTarget.classList.toggle('clicked')
      this.emphasisSomeDot = {
        show: clickedList.length ? true : false,
        type: type
      }
    },

    areaCodeChange(param) {
      this.$emit('cityChange', param)
    },
    switchShowExceed() {
      this.isShowExceed = !this.isShowExceed
    },
  }
}
</script>

<style lang="scss">
.center_block {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  .map_block {
    flex: 1;
    height: 60vh;
  }

  .bottom_block {
    display: flex;
    height: 13vh;

    > div {
      flex: 1;
    }
  }

}
</style>
