<template>
  <div class="key-indicator" @click="handleTypeChange('运维情况')">
    <el-row>
      <div style="font-size: 16rem;font-weight:bold;float:left;  display: flex;align-items: center;height:40rem;"><img style="width:20rem;margin-right: 3rem;padding-left:10rem;" src="./img/left.png" /><span>昨日运维情况统计</span></div>
    </el-row>
    <div style="float:left;width:100%;" class="box2" v-loading="loading" element-loading-background="rgb(19, 31, 54)">
      <div style="float:left;width:100%;">
        <div class="box-center">
          <div>
            <span style="padding-left:16rem;font-size:14rem;"> 报警 </span>
          </div>
          <div style="padding-right:16rem;">
            <template v-if ="recodeNumber == 0">
              <div style="font-weight:bold;font-size: 18rem;text-align: center;color:#3ca2f8">0<span style="font-size:12rem;margin-left:6rem;color:#ffffff">个</span></div>
            </template>
            <template v-else>
            <count-to :startVal='0'
              :endVal='recodeNumber'
              :duration='recodeNumber'
              :separator='null'
              style="font-weight:bold;font-size: 18rem;text-align: center; color:#3ca2f8"/><span style="font-size:12rem;color:#ffffff">个</span>
            </template>
          </div>
        </div>
        <div class="box-center">
          <div>
            <span style="padding-left:16rem;font-size:14rem;"> 处理 </span>
          </div>
          <div style="padding-right:16rem;">
            <template v-if ="handleNumber == 0">
              <div style="font-weight:bold;font-size: 18rem;text-align: center;color:#3ca2f8">0<span style="font-size:12rem;margin-left:6rem;color:#ffffff">个</span></div>
            </template>
            <template v-else>
            <count-to :startVal='0'
              :endVal='handleNumber'
              :duration='handleNumber'
              :separator='null'
              style="font-weight:bold;font-size: 18rem;text-align: center; color:#3ca2f8"/><span style="font-size:12rem;color:#ffffff">个</span>
            </template>
          </div>
        </div>
      </div>
      <div style="float:left;width:100%;">
        <div class="box-center">
          <div>
            <span style="padding-left:16rem;font-size:14rem;"> 派单 </span>
          </div>
          <div style="padding-right:16rem;">
            <template v-if ="sendNumber == 0">
              <div style="font-weight:bold;font-size: 18rem;text-align: center;color:#3ca2f8">0<span style="font-size:12rem;margin-left:6rem;color:#ffffff">个</span></div>
            </template>
            <template v-else>
            <count-to :startVal='0'
              :endVal='sendNumber'
              :duration='sendNumber'
              :separator='null'
              style="font-weight:bold;font-size: 18rem;text-align: center; color:#3ca2f8"/><span style="font-size:12rem;color:#ffffff">个</span>
            </template>
          </div>
        </div>
        <div class="box-center">
          <div>
            <span style="padding-left:16rem;font-size:14rem;"> 维保 </span>
          </div>
          <div style="padding-right:16rem;">
            <template v-if ="maintenanceNumber == 0">
              <div style="font-weight:bold;font-size: 18rem;text-align: center;color:#3ca2f8">0<span style="font-size:12rem;margin-left:6rem;color:#ffffff">个</span></div>
            </template>
            <template v-else>
            <count-to :startVal='0'
              :endVal='maintenanceNumber'
              :duration='maintenanceNumber'
              :separator='null'
              style="font-weight:bold;font-size: 18rem;text-align: center; color:#3ca2f8"/><span style="font-size:12rem;color:#ffffff">个</span>
            </template>
          </div>
        </div>
      </div>
      <div style="float:left;width:100%;">
        <div class="box-center">
          <div>
            <span style="padding-left:16rem;font-size:14rem;"> 核实 </span>
          </div>
          <div style="padding-right:16rem;">
            <template v-if ="verifyNumber == 0">
              <div style="font-weight:bold;font-size: 18rem;text-align: center;color:#3ca2f8">0<span style="font-size:12rem;margin-left:6rem;color:#ffffff">个</span></div>
            </template>
            <template v-else>
            <count-to :startVal='0'
              :endVal='verifyNumber'
              :duration='verifyNumber'
              :separator='null'
              style="font-weight:bold;font-size: 18rem;text-align: center; color:#3ca2f8"/><span style="font-size:12rem;color:#ffffff">个</span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import countTo from "vue-count-to";
import { Chart } from '@antv/g2';

export default {
  name: 'keyMaintain',
  props: {
    sumData: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  watch: {
    sumData: {
      handler: function () {
        this.loading = true
        this.getData()
      },
      deep: true
    },
  },
  components: {
    countTo
  },
  data() {
    return {
      showDaysData: false,
      streetDate: [],
      loading: true,
      handleNumber: 0,
      maintenanceNumber: 0,
      recodeNumber: 0,
      sendNumber:0,
      verifyNumber: 0,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getData();
      moment.locale('zh-cn');
    })
  },
  methods: {
    getData() {
      if (this.sumData) {
        var handleNumber = 0
        var maintenanceNumber = 0
        var recodeNumber = 0
        var sendNumber = 0
        var verifyNumber = 0
        this.streetDate = this.sumData.streetDate
        for(var i = 0; i < this.sumData.streetData.length; i++) {
          handleNumber = handleNumber + this.sumData.streetData[i].HandleNumber
          maintenanceNumber = maintenanceNumber + this.sumData.streetData[i].MaintenanceNumber
          recodeNumber = recodeNumber + this.sumData.streetData[i].RecodeNumber
          sendNumber = sendNumber + this.sumData.streetData[i].SendNumber
          verifyNumber = verifyNumber + this.sumData.streetData[i].VerifyNumber
        }
        this.handleNumber = handleNumber
        this.maintenanceNumber = maintenanceNumber
        this.recodeNumber = recodeNumber
        this.sendNumber = sendNumber 
        this.verifyNumber = verifyNumber
        this.loading = false
      }
    },
    handleTypeChange(type) {
      this.$emit('type-change', type)
    },
  
  }
}
</script>

<style lang="scss">
.box2{
  background: linear-gradient(#19253e, #223e61);  
  border-radius:16px;
  height: 120rem;
  margin-top: 5px;
}
.box2:hover {
  box-shadow: inset 0 0 32px #25a6ff;
}
.box-center{
  width:50%;
  float:left;
  display:flex;
  align-items: center;
  height:40rem;
  justify-content: space-between;
}

.key-title {
  padding-left: 20px;
  padding-top: 6px;
  font-size: 16rem;
  font-weight:bold;
}

</style>
