<!-- sctterMap.vue 地图下钻并且带有散点图 -->
<template>
  <div class="wrapper" v-loading="loading">
    <div class="echarts" ref="chartMap"></div>
    <div v-if="!isChengdu" :class="[showMore ? 'large' : '', 'exceed_content']" :style="showMore? 'width:700px;margin-bottom:40px':''">
      <dec-block-title style="cursor: pointer" @click.native="showMore = !showMore">
        {{ getDevBlockTitle(contentData.level) }}
        <span style="float:right">{{ showMore ? '点击关闭' : '点击展开' }}</span>
      </dec-block-title>
      <el-table
        ref="table"
        v-show="showMore"
        :data="sortOption.sortData"
        height="330"
        :key="tableKey"
        style="width: 100%"
        :cell-style="cellStyle"
        :header-cell-style="headerStyle"
        @header-click="clickHeader"
        empty-text='0'>
        <el-table-column
          prop="Name"
          :label="getTableColName(contentData.level)"
          align="center"
          width="70"
        >
        </el-table-column>
        <el-table-column
          prop="Number"
          label="监测点数"
          align="center"
          width="70">
        </el-table-column>
        <el-table-column
          prop="OnlineDeviceCount"
          label="在线数"
          align="center"
          width="60"
        >
        </el-table-column>
        <el-table-column
          prop="OnlinePercent"
          label="在线率"
          align="center"
          width="60"
        >
        </el-table-column>
        <el-table-column
          prop="NomalDeviceCount"
          label="达标数"
          align="center"
          width="60"
        >
        </el-table-column>
        <el-table-column
          prop="NomalPercent"
          label="达标率"
          align="center"
          width="60"
        >
        </el-table-column>
        <el-table-column
          prop="ExcessDeviceCount"
          label="超标数"
          align="center"
          width="60"
        >
        </el-table-column>
        <el-table-column
          prop="ExcessPercent"
          label="超标率"
          align="center"
          width="60"
        >
        </el-table-column>
        <el-table-column
          prop="empty"
          label="离线数"
          align="center"
          width="70"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.Number - scope.row.OnlineDeviceCount }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="empty"
          label="离线率"
          align="center"
          width="70"
        >
          <template slot-scope="scope">
            <div>{{ (100 - scope.row.OnlinePercent).toFixed(2) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="empty"
          label="商户整改率"
          align="center"
          width="60"
        >
          <div>0</div>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import {stg, bdToGdLatLng, isEmptyObj, getUserInfo} from '@/util/index'
import decBlockTitle from '../decorations/blockTitle2'
import {mapState} from 'vuex'
import {getLabelPosition, cityData} from '../../assets/util/index'
import chengDuData from '../../assets/chengdu.json'

const defaultEchartsOpt = () => ({
  backgroundColor: 'transparent',
  geo: {
    id: 'mr',
    show: true,
    type: 'map',
    map: 'Map', // 使用
    roam: true,
    center: null,
    nameProperty: '明瑞大屏',
    itemStyle: {
      normal: {
        show: true,
        areaColor: '#091632',
        borderColor: '#1773c3',
        borderWidth: '1',
        shadowColor: '#1773c3'
      },
      // emphasis 是图形在高亮状态下的样式，比如在鼠标悬浮或者图例联动高亮时。
      emphasis: {
        show: false,
        areaColor: '#0e265d',
        borderColor: '#83BAFF',
        shadowColor: '#1773c3',
        shadowBlur: 20
      }
    },
    label: {
      normal: {
        show: true, // 显示省份标签
        color: '#ddb926'
      },
      emphasis: {
        show: false,
        color: '#ddb926'
      }
    }
  },
  tooltip: {
    trigger: 'item'
  },
  series: []
})

export default {
  name: 'dotMap',
  components: {decBlockTitle},
  props: {
    contentData: {
      type: Object,
      default: () => {
        return {
          areaId: null,
          code: ''
        }
      }
    },
    isShowExceed: {
      type: Boolean
    },
    isShowEmphasisDot: {
      type: Object
    },
    onlineDevice: {
      type: Object,
      default: () => {
        return {}
      }
    },
    sumData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    topList: {
      type: Object,
      default: () => {
        return {}
      }
    },
    boardList: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      isChengdu: false,
      contentData_map: {
        code: null,
        level: null
      },
      myChart: null,
      sortOption: {
        defaultData: [],
        sortData: [],
        order: 'default',
        name: null
      },
      tableKey: 1,
      Polyline: '',
      map: {},
      parentJson: [],
      geoJsonData: {},
      dotColor: ['#FF001A', '#78ff79', '#FEFF65', '#AEAEAE', '#000000'], //红 绿色 黄色 灰色 黑色
      dotData: [],
      parentCode: [],
      list: [],
      seriesData: [{
        name: '监测点',
        type: 'scatter',
        coordinateSystem: 'geo',
        data: null,
        symbolSize: function (val) {
          return 7
        },
        tooltip: {
          show: true,
          borderColor: '#03608C',
          formatter: function (params) {
            const healthStatusColor = params.value[18]
            const healthColor = params.value[2]  === 'Green' ? '#78ff79' :  (params.value[2]  === 'Red' ? '#FF001A': '#FEFF65')
            return `
            <p>
            <span style="color:#c7c7cc">店铺名称：</span>
            <span style="color:#c7c7cc">${params.value[4]}</span>
            </p>
            <p>
              <div style="display:inline-block;color:#c7c7cc">实时状态：</div>
              <div style="width:10px;height:10px;background-color:${params.value[11]};display:inline-block;border-radius:50%;margin-right:5px"></div>
              <span style="color:#c7c7cc">(${params.value[14]})</span>
              <div style="display:inline-block;color:#c7c7cc">排放状态码：</div>
              <div style="width:10px;height:10px;background-color:${healthStatusColor};display:inline-block;border-radius:50%;margin-right:5px"></div>
            </p>
             <p>
              <div style="display:inline-block;color:#c7c7cc">红绿码状态：</div>
              <div style="width:10px;height:10px;background-color:${healthColor};display:inline-block;border-radius:50%;margin-right:5px"></div>
            </p>
            <p>
              <span style="color:#c7c7cc">店铺规模：</span>
              <span style="color:#c7c7cc">${params.value[10]}</span>
              <span style="margin-left:4px;color:#c7c7cc">灶头数：</span>
              <span style="color:#c7c7cc">${params.value[13]}</span>
            </p>
            <p>
              <span style="color:#c7c7cc;">联系人：</span>
              <span style="color:#c7c7cc;">${params.value[15]}</span>
              <span style="margin-left:4px;color:#c7c7cc">联系电话：</span>
              <span style="color:#c7c7cc">${params.value[5]}</span>
            </p>
            <h5 style="color:#c7c7cc;font-size:14px;margin:5px 0 0;">监测点信息</h5>
            <p>
              <span style="color:#c7c7cc">监测点名称：</span>
              <span style="color:#c7c7cc">${params.value[6]}</span>
            </p>
            <p>
              <span style="color:#c7c7cc">所属单位：</span>
              <span style="color:#c7c7cc">${params.value[12]}</span>
            </p>
            <p>
              <span style="color:#c7c7cc">菜系：</span>
              <span style="color:#c7c7cc">${params.value[7]}</span>
            </p>
            <p>
              <span style="color:#c7c7cc">地址：</span>
              <span style="color:#c7c7cc">${params.value[8]}</span>
            </p>
            <p>
              <span style="color:#c7c7cc">设备：</span>
              <span style="color:#c7c7cc">${params.value[9]}</span>
            </p>
            `
          },
          extraCssText: `
            padding: 8px 16px;
            border-radius: 0;
          `
        },
        itemStyle: {
          color: function (params) {
            return params.value[2] === 'Green' ? '#78ff79' :  (params.value[2]  === 'Red' ? '#FF001A': '#FEFF65')
          }
        }
      },
        {
          name: '区详细数据',
          type: 'map',
          geoIndex: 0, // 此项用于防止地图出现重影
          data: null,
          tooltip: {
            show: true,
            formatter: function (params) {
              if (params.data && 'DaySum' in params.data) {
                return '<p>' + params.name + '</p><p>当日超标店数：' + params.data.DaySum + '</p><p>当月超标店数：' + params.data.MonthSum + '</p>'
              } else {
                return ''
              }
            }
          }
        }],
      echartsOptions: {
        backgroundColor: 'transparent',
        geo: {
          id: 'mr',
          show: true,
          type: 'map',
          map: 'Map', // 使用
          roam: true,
          center: null,
          nameProperty: '明瑞大屏',
          itemStyle: {
            normal: {
              show: true,
              areaColor: '#002C5E', 
              borderColor: '#0090DE',
              borderWidth: '1',
              shadowColor: '#004088'
            },
            // emphasis 是图形在高亮状态下的样式，比如在鼠标悬浮或者图例联动高亮时。
            emphasis: {
              show: false,
              areaColor: '#004088',
              borderColor: '#83BAFF',
              shadowColor: '#1773c3',
              shadowBlur: 20
            }
          },
          label: {
            normal: {
              show: true, // 显示省份标签
              color: '#A5C3D5',
              formatter: function (params) {
                switch (params.name) {
                  case '内蒙古自治区':
                  case '广西壮族自治区':
                  case '西藏自治区':
                  case '宁夏回族自治区':
                  case '新疆维吾尔自治区':
                  case '香港特别行政区':
                  case '澳门特别行政区':
                    return params.name.substring(0, 2)
                  default:
                    return params.name
                }
              }
            },
            emphasis: {
              // 对应的鼠标悬浮效果
              show: false,
              color: '#ffffff'
            }
          }
        },
        tooltip: {
          trigger: 'item'
        },
        series: []
      },
      postdata: {
        WithSumData: true, // 是否返回汇总数据,
        WithOnlineDevice: true, // 返回在线设备,
        WithTopList: true // 返回top5
      },
      showMore: false,
      mapScale: null,
      redEmissionsBar: [],
      onlineRateBar: [],
      complianceRateBar: [],
      loading: true,
    }
  },
  created() {
    this.user = getUserInfo()
    if (this.user.Info && this.user.Info.pid && this.user.Info.pid.slice(0,6) == "/1199/") {
      this.isChengdu = true
    }
  },
  mounted() {
    this.initData()
  },
  computed: {
    ...mapState(['showMoreDistrictInfo', 'districtInfoType', 'topBoardData', 'isXihu'])
  },
  methods: {
    //初始化数据
    initData() {
      this.loading = true
      if (isEmptyObj(this.sumData) || isEmptyObj(this.onlineDevice)
        || isEmptyObj(this.topList) || isEmptyObj(this.boardList))
        return
      this.sortOption.sortData = this.sumData.streetData
      this.sortOption.defaultData = this.sumData.streetData
      this.topList.redEmissions && this.topList.redEmissions.map((item) => {
        this.onlineDevice.device && this.onlineDevice.device.map((vo) => {
          if (item.K.split('/').join('') === vo.K.split('/').join('')) {
            item.N = vo.N
          }
        })
        return item
      })

      // 昨日在线率
      this.onlineRateBar = []
     
      this.onlineRateBar.sort((o1, o2) => {
        if (o1.V < o2.V) {
          return 1
        } else if (o1.V > o2.V) {
          return -1
        } else {
          return 0
        }
      })

      // 昨日达标率
      this.complianceRateBar = []
      this.topList.compliance.map((item) => {
        this.onlineDevice.device.map((vo) => {
          let i = Object.assign({}, item)
          if (item.K.split('/').join('') === vo.K.split('/').join('')) {
            i.V = vo.V == 0 ? 0 : ((item.V * 100) / vo.V).toFixed(2) * 1
            i.N = vo.N
          }
          this.complianceRateBar.push(i)
        })
      })
      this.complianceRateBar.sort((o1, o2) => {
        if (o1.V < o2.V) {
          return 1
        } else if (o1.V > o2.V) {
          return -1
        } else {
          return 0
        }
      })

      // 减排累计
      this.redEmissionsBar = []
      this.topList.redEmissions.map((item) => {
        let i = Object.assign({}, item)
        this.onlineDevice.device.map((vo) => {
          if (item.K.split('/').join('') === vo.K.split('/').join('')) {
            i.N = vo.N
          }
          this.redEmissionsBar.push(i)
        })
        return item
      })
      this.getGeoJson(this.contentData.code)
    },
    // 初始化地图
    initMap() {
      this.myChart = echarts.init(this.$refs.chartMap)
      window.addEventListener('resize', () => {
        this.myChart.resize()
      })
      this.myChart.on('click', (params) => {
        if (params.componentType === 'series') {
          this.$emit('echartsMapClick', {
            code: params.data.code,
            level: params.data.level
          })
        }
      })
    },
    sortExceedContent(name) {
      if (name !== this.sortOption.name) {
        this.sortOption.order = 'default'
      }
      this.sortOption.name = name
      switch (this.sortOption.order) {
        case 'desc':
          this.sortOption.order = 'asce'
          this.sortOption.sortData = [...this.sortOption.defaultData].sort(function (a, b) {
            return (a[name] - b[name])
          })
          break
        case 'asce':
          this.sortOption.order = 'default'
          this.sortOption.sortData = [...this.sortOption.defaultData]
          break
        case 'default':
          this.sortOption.order = 'desc'
          this.sortOption.sortData = [...this.sortOption.defaultData].sort(function (a, b) {
            return (b[name] - a[name])
          })
          break
      }
    },
    dotColorAndStatusFormatter(Status) {
      let r = []
      switch (Status) {
        case 'NORMAL':
          r = [1, '达标']
          break
        case 'EXCESS':
          r = [1, '超标']
          break
        case 'DOWN':
          r = [3, '离线']
          break
        case 'OFF':
          r = [4, '异常离线']
          break
        case 'ALARM':
          r = [1, '预警']
          break
        default:
          r = [4, '未知状态']
      }
      return r
    },
    dotColorStatusExeFormatter(Status) {
      var r = 3
      switch (Status) {
        case 'NORMAL':
          r = 1
          break
        case 'EXCESS':
          r = 0
          break
        case 'DOWN':
          r = 3
          break
        case 'OFF':
          r = 4
          break
        case 'ALARM':
          r = 2
          break
      }
      return r
    },
    stoveNumFormatter(StoveNum) {
      let r
      if (StoveNum > 6) {
        r = '大'
      } else if (StoveNum > 3) {
        r = '中'
      } else {
        r = '小'
      }
      return r
    },
    getTableColName(level) {
      switch (level) {
        case 'country':
          return '省/直辖市名'
        case 'province':
          return '市名'
        case 'city':
          return '区名'
      }
    },
    getDevBlockTitle(level) {
      switch (level) {
        case 'country':
          return '省级信息展示'
        case 'province':
          return '市级信息展示'
        case 'city':
          return '区级信息展示'
      }
    },
    // 获取地址详情
    getAreaDetail() {
      const areaData = {
        Param: {Id: this.contentData.areaId}
      }
      this.$post('admin/listArea', areaData)
        .then((res) => {
          this.Polyline = res.content[0].Polyline
          stg().setItem('quPolyline', this.Polyline)
        })
        .catch(() => {
        })
    },
    // 获取geoJson数据
    getGeoJson(adcode) {
      const that = this
      if (this.contentData.areaId) {
        this.getAreaDetail()
      } else {
        stg().setItem('quPolyline', '')
      }
      if (this.contentData.name == '盐南高新区') {
        stg().setItem('quName', '盐南高新区')
      } else {
        stg().setItem('quName', '')
        window.AMapUI.loadUI(['geo/DistrictExplorer'], (DistrictExplorer) => {
          const districtExplorer = (window.districtExplorer = new DistrictExplorer())
          districtExplorer.loadAreaNode(adcode, function (error, areaNode) {
            if (error) {
              console.error(error)
              return
            }
            that.mapScale = areaNode._data.scale
            const level = areaNode.getParentFeature()
            that.echartsOptions.geo.center = level.properties.centroid
            let Json = []
            if (level.properties.level !== 'district' && level.properties.level !== 'street') {
              Json = areaNode.getSubFeatures()
            } else {
              Json = [level]
            }
            if (adcode == "510100") {
              Json = chengDuData.features
            }
            that.geoJsonData = {
              features: Json
            }
            that.getMapData(adcode)
          })
        })
      }
    },
    // 获取数据
    getMapData(adcode) {
      const deviceDetail = this.onlineDevice.listDevice || []
      const exceedList = this.boardList.exceedStandards || []
      const areaList = this.onlineDevice.device
      const areaDetailList = this.geoJsonData.features
      const list = []
      for (let i = 0; i < areaList.length; i++) {
        let obj = {
          N: areaList[i].N,
          name: areaList[i].N,
          center: [],
          level: 'country',
          code: 100000
        }
        if (obj.name === '富阳市') {
          obj.name = '富阳区'
        }
        const m = exceedList.findIndex(e => e.K.split('/').join('') === areaList[i].K.split('/').join(''))
        if (m !== -1) {
          obj = {
            ...obj,
            ...exceedList[m]
          }
        }
        let n = areaDetailList.find(e => e.properties.name.substring(0, 2) === areaList[i].N.substring(0, 2))
        if (n) {
          if (n.properties.centroid) {
            obj.center = [
              n.properties.centroid[0],
              n.properties.centroid[1]
            ]
          }
          if (adcode == "510100") {
            const t = cityData.find(e => e.label === areaList[i].N)
            if (!t) continue
            n = {
              properties: {
                name: t.label,
                centroid: t.coordinate,
                level: t.level,
                adcode: t.code
              }
            }
            obj.center = n.properties.centroid.slice()
            obj.level = n.properties.level
            obj.code = n.properties.adcode
          } else {
            obj.level = n.properties.level
            obj.code = n.properties.adcode
          }
        }
        list.push(obj)
      }
      this.list = list
      this.dotData = []
      for (let i = 0; i < deviceDetail.length && i < 2400; i++) {
        const r = this.dotColorAndStatusFormatter(deviceDetail[i].Status)
        const {lng, lat} = bdToGdLatLng(deviceDetail[i].Lng, deviceDetail[i].Lat)
        this.dotData.push({
          name: deviceDetail[i].MN,
          value: [
            lng,
            lat,
            deviceDetail[i].HealthCodeColor,
            deviceDetail[i].Status,
            deviceDetail[i].CustomerName,
            deviceDetail[i].CustomerMobile,
            deviceDetail[i].LocaleName,
            deviceDetail[i].CuisineName,
            deviceDetail[i].Addr,
            deviceDetail[i].MN,
            this.stoveNumFormatter(deviceDetail[i].StoveNum),
            this.dotColor[r[0]],
            deviceDetail[i].OwnerName,
            deviceDetail[i].StoveNum,
            r[1],
            deviceDetail[i].CustomerContact,
            deviceDetail[i].Id,
            deviceDetail[i].Owner,
            this.dotColor[this.dotColorStatusExeFormatter(deviceDetail[i].Status)]
          ]
        })
      }
      this.initEcharts()
    },
    
    initEcharts() {
      if (!this.myChart) {
        this.initMap()
      }
      echarts.registerMap('Map', this.geoJsonData)
      this.seriesData[0].data = this.dotData
      this.seriesData[1].data = this.list
      this.echartsOptions.series = this.seriesData
      this.myChart.setOption(this.echartsOptions, true)
      this.loading = false
    },
    showLabelLine(type) {
      let t
      let label
      const opt = this.myChart.getOption()
      const mapCenter = opt.geo[0].center
      switch (type) {
        case 1:
          t = this.onlineRateBar
          label = '昨日在线率'
          break
        case 2:
          t = this.complianceRateBar
          label = '昨日达标率'
          break
        case 3:
          t = this.onlineDevice.device
          label = '安装数'
          break
        case 4:
          t = this.redEmissionsBar
          label = '减排累计'
          break
        default:
          return
      }
      const data = []
      let j
      let m = 0
      for (let i = 0; i < t.length; i++) {
        j = this.list.find(e => e.name === t[i].N)
        if ((!j.center) || j.center.length === 0) {
          m++
          continue
        }
        data.push({
          name: t[i].N,
          value: t[i].V,
          coords: [j.center, getLabelPosition(mapCenter, this.mapScale, i - m)]
        })
      }
      if (this.seriesData.length > 2) {
        this.seriesData.splice(2)
      }
      this.seriesData.push({
        name: label,
        type: 'lines',
        coordinateSystem: 'geo',
        geoIndex: 0,
        data: data,
        lineStyle: {
          type: 'solid',
          color: '#218890',
          width: 1
        },
        label: {
          show: true,
          color: '#c7c7cc',
          align: 'left',
          fontSize: 13,
          lineHeight: 16,
          formatter: function (params) {
            return params.name + '\n' + label + '：' + params.value
          }
        }
      })
      this.echartsOptions.series = this.seriesData
      this.myChart.setOption(this.echartsOptions, true)
    },
    clickHeader(column, event) {
      this.sortExceedContent(column.property)
    },
    //绘制eltable表头风格
    headerStyle({row, column, rowIndex, columnIndex}) {
      let style = "color:#ffffff;" +
        "background: rgba(21, 55, 135);\n" +
        "border: solid 1px rgb(255,255,255);" +
        "height:30px;" +
        "font-size:12px;" +
        "font-weight: normal;"
      return style
    },
    //绘制eltable单元格风格
    cellStyle({row, column, rowIndex, columnIndex}) {
      let style = "color:#ffffff;" +
        "background: rgba(21, 55, 135);\n" +
        "border: solid 1px rgb(255,255,255);" +
        "height:4vh;" +
        "font-size:12px;" +
        ""
      return style
    }
  },
  watch: {
    showMoreDistrictInfo(val) {
      if (!val && this.seriesData.length > 2) {
        this.seriesData.splice(2)
        this.echartsOptions.series.splice(2)
        this.echartsOptions.series = this.seriesData
        this.myChart.setOption(this.echartsOptions, true)
      }
    },
    districtInfoType(val) {
      this.showLabelLine(val)
    },
    sumData: {
      handler: function () {
        this.initData()
      }
    },
    onlineDevice: {
      handler: function () {
        this.initData()
      }
    },
    topList: {
      handler: function () {
        this.initData()
      }
    },
    boardList: {
      handler: function () {
        this.initData()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.echarts {
  width: 100%;
  height: 100%;
  position: relative;
}

.back {
  position: absolute;
  left: 2%;
  top: 2%;
  color: #eee;
  z-index: 99999;
  cursor: pointer;
}

.large {
  width: 23vw;
  height: 38vh;
}

.exceed_content {
  position: absolute;
  bottom: 180rem;
  right: 445rem;
  font-size: 12px;
  color: #ffffff;
  min-width: 12vw;
  background: rgba(21, 55, 135, 0.8);

  .ul_head {
    margin: 0;
    padding: 0;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #c7c7cc;
    }

    .li_item {
      display: flex;
      justify-content: space-around;
      height: 4vh;
      line-height: 4vh;

      span {
        text-align: center;
        display: inline-block;
      }

      .item1 {
        width: 40%;
      }

      .item2,
      .item3 {
        width: 30%;
      }

      .item-head {
        cursor: pointer;
      }
    }
  }

  .ul_cont {
    margin: 0;
    padding: 0;
    height: 28vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #c7c7cc;
    }

    .li_item {
      display: flex;
      justify-content: space-around;
      height: 4vh;
      line-height: 4vh;

      span {
        text-align: center;
        display: inline-block;
      }

      .item1 {
        width: 40%;
      }

      .item2,
      .item3 {
        width: 40%;
      }
    }
  }

  .exceed_content:hover {
    cursor: pointer;
  }
}

.el-table {
  opacity: 0.8;
  /deep/ .el-table__body-wrapper::-webkit-scrollbar {
    width: 7px; /*滚动条宽度*/
    height: 10px; /*滚动条高度*/
  }

  /*定义滚动条轨道 内阴影+圆角*/
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: 0px 1px 3px #071e4a inset; /*滚动条的背景区域的内阴影*/
    border-radius: 10px; /*滚动条的背景区域的圆角*/
    background-color: #071e4a; /*滚动条的背景颜色*/
  }

  /*定义滑块 内阴影+圆角*/
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
    box-shadow: 0px 1px 3px rgb(21, 55, 135) inset; /*滚动条的内阴影*/
    border-radius: 10px; /*滚动条的圆角*/
    background-color: rgb(21, 55, 135); /*滚动条的背景颜色*/
  }
}
</style>
