<template>
  <div class="event-mr-message">
    <el-row class="event-title">
      <div style="font-size: 16rem;font-weight:bold;float:left;  display: flex;align-items: center;height:40rem;"><img style="width:22rem;margin-right: 3rem;" src="./img/log.png" /><span>事件处理记录</span></div>
    </el-row>
    <vue-seamless-scroll :data="alarmData" class="seamless-warp-2" :class-option="optionHover" ref="seamlessScroll">
      <div class="event-list"
           v-loading="dataLoading"
           element-loading-background="rgb(44, 75, 147)">
        <div class="event-item" v-for="(item, index) in alarmData" :style="{'background-color': index % 2 === 0 ? 'rgba(1, 29, 54, 0.9)':'rgba(1, 29, 54, 0.4)' }">
          <div @click="showLocale(item)" style="width: 100%; padding: 5rem 15rem;min-height: 132rem;max-height: 170rem;">
            <div style="color: #c7c7cc;font-size: 12rem;opacity: 1">
              事件类型：<span style="color: #3f8ffd;">{{ item.EventType }}</span>
            </div>
            <div style="color: #c7c7cc;font-size: 12rem;opacity: 1">
              点位名称：{{ item.LocaleName }}
            </div>
            <div style="color: #c7c7cc;font-size: 12rem;opacity: 1">
              报警地址：{{ item.LocaleAddr }}
            </div>
            <div style="color: #c7c7cc;font-size: 12rem;opacity: 1">
              发起时间：{{ formaterTime(item.CalculateDate) }}
            </div>
            <div style="color: #c7c7cc;font-size: 12rem;opacity: 1">
              事件状态：{{ item.EventStatus }}
            </div>
            <div style="color: #c7c7cc;font-size: 12rem;opacity: 1">
              处理措施：{{ item.HandleMeasure }}
            </div>
          </div>
        </div>
      </div>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import moment from 'moment'
import vueSeamlessScroll from 'vue-seamless-scroll'
import {
  dateFormater
} from '@/util/index'
export default {
  name: 'alarmMessage',
  props: {
    countyData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    vueSeamlessScroll
  },
  data() {
    return {
      count: 5,
      length: 0,
      showData: null,
      loading: true,
      dataLoading: true,
      alarmData: undefined
    }
  },
  mounted() {
    this.getData()
    moment.locale('zh-cn');

  },
  watch: {
    countyData: {
      handler: function () {
        this.getData()
      },
      deep: true
    },
  },
  computed: {
    noMore() {
      return this.count >= this.alarmData.length
    },
    optionHover() {
      return {
        limitMoveNum: 7,
        step: 0.25, // 数值越大速度滚动越快
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },

  methods: {
    showLocale(item) {
      this.$emit('showLocale', item.LocaleMn)
    },
    formaterTime(time) {
      return dateFormater(time*1000, false, false)
    },
    getData() {
      this.dataLoading = true
      if (this.countyData) {
        const exceedStandardYesterdays = (this.countyData.onlineEventList || []).map((v) => {
          if (v.EventType == 1) {
            v.EventType = '例行巡检'
          } else if (v.EventType == 2) {
            v.EventType = '现场监督'
          } else if (v.EventType == 2) {
            v.EventType = '执法管理'
          } else {
            v.EventType = '红码处理'
          }
          return v
        })
        this.alarmData = [
          ...exceedStandardYesterdays
        ]
        this.init();
        this.dataLoading = false
      }
    },
    init() {
      if (!this.alarmData || this.alarmData.length === 0) {
        this.length = 0
        return false
      }
      this.optionHover.limitMoveNum = this.alarmData.length
      this.length = this.countyData.exceedNumber
      this.$refs.seamlessScroll.reset()
    },
  }
}
</script>

<style lang="scss">
.event-mr-message {
  box-sizing: border-box;
  z-index: 5;
  overflow: hidden;
  font-size: 16rem;
  margin-left:10rem;
  margin-right: 10rem;

  .event-title {
    display: flex;
    align-items: center;
    font-size: 16rem;
    font-weight:bold;
    margin-bottom: 10rem;
    z-index:10;
    background-color:rgb(44, 75, 147);
    height: 32px;

    .seamless-warp-2 {
      width: 100%;
      overflow: hidden;
      height: 36vh;
      .event-list {
        .event-item {
          width: auto;
          display: flex;
          align-items: stretch;
          justify-content: flex-start;
        }
      }
    }
  }
}


</style>
