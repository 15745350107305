<template>
  <div class="region_compare">
    <div class="region-compare-title">
      <div v-if="isChengdu"><div style="font-size: 16rem;font-weight:bold;float:left;  display: flex;align-items: center;"><img style="width:20rem;margin-right: 3rem;padding-left:10rem;" src="./img/left.png" /></div>区域数据对比</div>
      <div v-else><div style="font-size: 16rem;font-weight:bold;float:left;  display: flex;align-items: center;height:26rem;"><img style="width:20rem;margin-right: 3rem;padding-left:10rem;" src="./img/per.png" /></div>区域数据对比</div>
    </div>
      <template v-if="isChengdu">
        <div class="box_region">
          <el-row>
            <el-col :span="12" style="display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;">
              <dv-active-ring-chart
                :key="key1"
                :config="installNumConfig"
                class="region-compare-chart"
              />
              <span style="font-size: 14rem;color: #ffffff;">安装数量占比</span>
            </el-col>
            <el-col :span="12" style="display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;">
              <dv-active-ring-chart
                :config="enterInstallConfig"
                :key="key2"
                class="region-compare-chart"
              />
              <span style="font-size: 14rem;color: #ffffff;">大中小型企业安装占比</span>
            </el-col>
          </el-row>
          <dv-charts :option="option"/>
          <div style="font-size: 14rem;color: #ffffff;width:100%;text-align:center;margin-top:10rem;">各街道油烟达标率</div>
        </div>
      </template>
      <template v-else>
        <ul>
          <li>
            <dv-active-ring-chart
              :key="key1"
              :config="installNumConfig"
              class="region-compare-chart"
            />
            <span style="font-size: 14rem;color: #ffffff;">安装数量占比</span>
          </li>
          <li>
            <dv-active-ring-chart
              :config="enterInstallConfig"
              :key="key2"
              class="region-compare-chart"
            />
            <span style="font-size: 14rem;color: #ffffff;">大中小型企业安装占比</span>
          </li>
          <li>
            <dv-active-ring-chart
              :key="key3"
              :config="emissionRateConfig"
              class="region-compare-chart"
            />
            <span style="font-size: 14rem;color: #ffffff;">油烟排放达标率</span>
          </li>
          <li>
            <dv-active-ring-chart
              :key="key4"
              :config="reduceRateCofnig"
              class="region-compare-chart"
            />
            <span style="font-size: 14rem;color: #ffffff;">油烟减排贡献率</span>
          </li>
      </ul>
    </template>
  </div>
</template>

<script>
import decBlockTitle from "../decorations/blockTitle2";
import {
  getUserInfo
}  from '@/util/index'

export default {
  name: "regionCompare",
  components: {
    decBlockTitle,
  },
  props: ['onlineDeviceData', 'topListData', 'streetData'],
  data() {
    return {
      isChengdu: false,
      loading: true,
      config: {
        radius: "65%",
        activeRadius: "80%",
        lineWidth: 20,
        digitalFlopStyle: {
          fontSize: 12,
          fill: "#fff",
        },
        data: [],
      },
      installNumConfig: null,
      enterInstallConfig: null,
      emissionRateConfig: null,
      reduceRateCofnig: null,
      key1: 1,
      key2: 1,
      key3: 1,
      key4: 1,
      option:{}
    };
  },
  computed: {},
  watch: {
    onlineDeviceData: {
      handler: function () {
        this.getData()
      },
      deep: true
    },
    topListData: {
      handler: function () {
        this.getData()
      },
      deep: true
    },
    streetData: {
      handler: function () {
        this.getData()
      },
      deep: true
    },
  },
  created() {
    this.user = getUserInfo()
    if (this.user.Info && this.user.Info.pid && this.user.Info.pid.slice(0,6) == "/1199/") {
      this.isChengdu = true
    }
  },
  mounted() {
    this.getData()
    var _this=this
    window.addEventListener('resize', function(e){
      _this.$nextTick(()=>{
        _this.key1++
        _this.key2++
        _this.key3++
        _this.key4++
      })
    })
  },
  methods: {
    getData() {
      if (this.onlineDeviceData && this.topListData && this.streetData) {
        this.getinstallNumConfig();
        this.getenterInstallConfig();
        if (this.isChengdu) {
          this.getOther()
        } else {
          this.getemissionRateConfig();
          this.getreduceRateCofnig();
        }
        this.loading = false
      }
    },
    // 安装数量占比
    getinstallNumConfig() {
      const config = JSON.parse(JSON.stringify(this.config));
      const device = this.onlineDeviceData.device
        ? JSON.parse(JSON.stringify(this.onlineDeviceData.device))
        : [];
      config.data = device.map((item) => {
        item.value = item.V;
        item.name = item.N;
        return item;
      });
      config.color = [
        "#3f8ffd",
        "#11c2c1",
        "#1a419d",
        "#2ee3ff",
        "#6235ff",
      ]
      config.lineWidth = 16
      this.installNumConfig = config;
      this.key1++;
    },
    // 大中小型企业安装占比
    getenterInstallConfig() {
      const config = JSON.parse(JSON.stringify(this.config));
      const enterprise = this.onlineDeviceData.enterprise
        ? JSON.parse(JSON.stringify(this.onlineDeviceData.enterprise))
        : [];
      config.data = enterprise.map((item) => {
        item.value = item.V;
        item.name = item.K + "型";
        return item;
      });
      config.color = [
        "#FF5D1D",
        "#FFA91F",
        "#FFF803",
        "#9DF90D",
        "#11EA00",
      ]
      config.lineWidth = 16
      this.enterInstallConfig = config;
      this.key2++;
    },
    // 油烟排放达标率
     getemissionRateConfig() {
      const config = JSON.parse(JSON.stringify(this.config));
      const compliance = this.topListData.compliance
        ? JSON.parse(JSON.stringify(this.topListData.compliance))
        : [];
      config.data = compliance.map((item) => {
        item.value = item.V;
        item.name = item.Name;
        return item;
      });
      config.color = [
        "#b620e0",
        "#eb2f95",
        "#ef4764",
        "#6235ff",
        "#ff8c00"
      ]
      this.emissionRateConfig = config;
      this.key3++;
    },

    getOther() {
      const compliance = this.streetData.streetData
        ? JSON.parse(JSON.stringify(this.streetData.streetData))
        : [];
      if (compliance.length > 0 ){
        this.option = {
          xAxis: {
            axisLine: {
              show: true,
              style: {
                stroke: '#324D4D',
              }
            },
            axisTick: {
              show: true,
              style: {
                stroke: '#324D4D',
              }
            },
            axisLabel: {
              style: {
                fontSize: 9,
                fill: '#D0D0D0'
              },
              rotate: 12,  
            },
            data: compliance.filter((item, index) => index < 7).map(item => {
              return item.Name
            })
          },
          yAxis: {
            min: 0,
            max: 100,
            data: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              formatter: function(params) {
                return params.value + ' %'; // 在标签中显示数值和百分比  
              },
              style: {
                fontSize: 9,
                fill: '#D0D0D0'
              },
            },
            splitLine: {
              style: {
                stroke: '#324D4D',
                lineWidth: 1,
                lineDash: [5, 2]
              }
            }
          },
          series: [
            {
              xAxisIndex: 0,
              yAxisIndex: 0,
              data: compliance.filter((item, index) => index < 7).map(item => {
                return parseInt(item.NomalPercent)
              }),
              label: {
                show: true,
                position: 'top',
                formatter: function(params) {
                  return params.value + ' %'; // 在标签中显示数值和百分比  
                }
              },
              type: 'bar',
              barWidth: 20,
              gradient: {
                color: ['rgba(76,198,255,1)', 'rgba(75,189,255,0.05)']
              }
            }
          ],
          grid: {
            left: 40,
            top: 20,
            right: 0,
            bottom: 20
          }
        }
      } else {
        this.option = {}
      }
    },
    // 油烟减排贡献率
    getreduceRateCofnig() {
      const config = JSON.parse(JSON.stringify(this.config));
      const redEmissions = this.topListData.redEmissions
        ? JSON.parse(JSON.stringify(this.topListData.redEmissions))
        : [];
      config.data = redEmissions.map((item) => {
        item.value = item.V;
        item.name = item.Name;
        return item;
      });
      config.color = [
        "#ff8c00",
        "#facc14",
        "#a0d911",
        "#11c2c1",
        "#6235ff",
      ]

      
      this.reduceRateCofnig = config;
      this.key4++;
    },
  }
};
</script>

<style lang="scss">
.region_compare {
  width: 100%;
  box-sizing: border-box;
  ul {
    height: 42vh;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-inline-start: 0px !important;
    li {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 18vh;
      > span {
        font-size: 12rem;
        color: #b2f2f6;
        text-align: center;
      }
    }
  }
  .box_region{
    background: linear-gradient(#19253e, #223e61);  
    border-radius:16px;
    margin-top: 5px;
    height:100%;
  }
  .box_region:hover {
    box-shadow: inset 0 0 32px #25a6ff;
  }
}

.region-compare-title {
  font-size: 16rem;
  font-weight:bold;
}

.region-compare-chart {
  width: 120rem;
  height: 100rem;
}

.dv-charts-container {
  height: 29vh;
}



</style>
