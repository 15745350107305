<!--大屏-减排情况-->
<template>
  <div class="reach_data">
    <el-row>
      <div style="font-size: 16rem;font-weight:bold;float:left;  display: flex;align-items: center;height:40rem;"><img style="width:20rem;margin-right: 3rem;padding-left:10rem;" src="./img/recyle.png" /><span>减排情况</span></div>
    </el-row>
    <div v-loading="loading" element-loading-background="rgb(44, 75, 147)">
      <el-row>
        <el-col :span="8" class="reach-subtitle">
          <div @click="handleTypeChange('减排累计')">
            <div>昨日减排累计(Kg)</div>
            <count-to :startVal='0'
                      :endVal='total'
                      :duration='500'
                      :separator='null'
                      :decimals=2
                      style="font-size: 20rem;text-align: center"></count-to>
          </div>
        </el-col>
        <el-col :span="8" class="reach-subtitle">
          <div @click="handleTypeChange('PM2.5昨日减排')">
            <div>PM2.5昨日减排(Kg)</div>
            <count-to :startVal='0'
                      :endVal='totalRedPm25Amount'
                      :duration='totalRedPm25Amount/5+1'
                      :separator='null'
                      :decimals=2
                      style="font-size: 20rem;text-align: center"></count-to>
          </div>
        </el-col>
        <el-col :span="8" class="reach-subtitle">
          <div @click="handleTypeChange('PM10昨日减排')">
            <div>PM10昨日减排(Kg)</div>
            <count-to :startVal='0'
                      :endVal='totalRedPm10Amount'
                      :duration='totalRedPm10Amount/5+1'
                      :separator='null'
                      :decimals=2
                      style="font-size: 20rem;text-align: center"></count-to>
          </div>
        </el-col>
      </el-row>
      <el-row style="padding-top: 10px">
        <el-col :span="8" class="reach-subtitle">
          <div @click="handleTypeChange('油烟减排')">

            <div>油烟昨日减排(Kg)</div>
            <count-to :startVal='0'
                      :endVal='totalEmissionsAmount'
                      :duration='totalEmissionsAmount/5+1'
                      :separator='null'
                      :decimals=2
                      style="font-size: 20rem;text-align: center"></count-to>
          </div>
        </el-col>
        <el-col :span="8" class="reach-subtitle">
          <div @click="handleTypeChange('非甲烷减排')">
            <div>非甲烷昨日减排(Kg)</div>
            <count-to :startVal='0'
                      :endVal='totalRedVocsAmount'
                      :duration='totalRedVocsAmount/5+1'
                      :separator='null'
                      :decimals=2
                      style="font-size: 20rem;text-align: center"></count-to>
          </div>
        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
import countTo from "vue-count-to";

export default {
  name: 'reduceData',
  components: {
    countTo
  },
  props: {
    sumData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mounted() {
    this.getData()
  },
  data() {
    return {
      contentData: undefined,
      total: 0,
      totalRedPm10Amount: 0,
      totalRedPm25Amount: 0,
      totalRedVocsAmount: 0,
      totalEmissionsAmount: 0,
      loading: true
    }
  },
  methods: {
    getData() {
      this.total = 0
      this.totalRedVocsAmount = 0
      this.totalRedPm10Amount = 0
      this.totalRedPm25Amount = 0
      this.totalEmissionsAmount = 0
      this.loading = true
      if (this.sumData) {
        this.contentData = this.sumData.sumDataDaily
        this.loading = false
      }
    },
    handleTypeChange(type) {
      this.$emit('type-change', type)
    }
  },
  watch: {
    contentData: {
      handler: function () {
        if (!this.contentData) {
          return false
        }
        if (Array.isArray(this.contentData)) {
          for (let e of this.contentData) {
            // console.log(e.EmissionsAmount + "+" +
            //   this.totalEmissionsAmount + "=" + (this.totalEmissionsAmount + e.EmissionsAmount)
            // )
            this.totalRedPm10Amount += e.RedPm10Amount
            this.totalRedVocsAmount += e.RedVocsAmount
            this.totalRedPm25Amount += e.RedPm25Amount
            this.totalEmissionsAmount += e.EmissionsAmount
          }

          this.total = (this.totalRedPm10Amount
            + this.totalRedVocsAmount
            + this.totalRedPm25Amount
            + this.totalEmissionsAmount)
        }
      },
      deep: true,
      immediate: true
    },
    sumData: {
      handler: function () {
        this.getData()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.reach_data {
  width: 100%;
  box-sizing: border-box;
}

.reach-title {
  padding-left: 20px;
  padding-top: 10px;
  font-size: 16rem;
  font-weight:bold;
  padding-bottom: 10px;
}

.reach-subtitle {
  font-size: 14rem;
  text-align: center;

  div {
    padding-bottom: 2px;
  }
}
</style>
