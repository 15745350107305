 var gxnq = [
          [
          [
            104.08446997404099,
            30.622750993833787
          ],
          [
            104.08447265624999,
            30.62274868568607
          ],
          [
            104.0845799446106,
            30.62173309535159
          ],
          [
            104.08525049686432,
            30.620181991367453
          ],
          [
            104.08570647239685,
            30.6193325667489
          ],
          [
            104.08580839633942,
            30.618686260415828
          ],
          [
            104.08564746379852,
            30.618090731475018
          ],
          [
            104.08489108085632,
            30.617162806987256
          ],
          [
            104.08451557159424,
            30.61653956912966
          ],
          [
            104.08451020717621,
            30.615722428971367
          ],
          [
            104.08480525016785,
            30.615113031821593
          ],
          [
            104.08617854118347,
            30.61368647349551
          ],
          [
            104.08658623695374,
            30.612864692491822
          ],
          [
            104.08680617809296,
            30.6108702286938
          ],
          [
            104.08697247505187,
            30.610366989440056
          ],
          [
            104.0880560874939,
            30.608848022072188
          ],
          [
            104.08818483352661,
            30.60799388102065
          ],
          [
            104.08813655376434,
            30.605653034231945
          ],
          [
            104.08776640892029,
            30.604170930389397
          ],
          [
            104.08720850944519,
            30.603039714377935
          ],
          [
            104.0863448381424,
            30.60236559569758
          ],
          [
            104.08320128917694,
            30.60140981665141
          ],
          [
            104.08239662647247,
            30.60094808426553
          ],
          [
            104.08186554908752,
            30.600297037862322
          ],
          [
            104.0816992521286,
            30.599590578305158
          ],
          [
            104.08201038837433,
            30.596058203249115
          ],
          [
            104.08227324485779,
            30.594986921573657
          ],
          [
            104.08271849155426,
            30.594160364255764
          ],
          [
            104.08342123031616,
            30.59317679803765
          ],
          [
            104.08368408679962,
            30.592534934887944
          ],
          [
            104.08363580703735,
            30.591865360884793
          ],
          [
            104.08322811126709,
            30.59120963565332
          ],
          [
            104.08262729644775,
            30.590637026892917
          ],
          [
            104.08204793930054,
            30.59039689963174
          ],
          [
            104.08020257949828,
            30.590156771775625
          ],
          [
            104.07960176467896,
            30.589879700433112
          ],
          [
            104.0792155265808,
            30.58936249847452
          ],
          [
            104.07893657684326,
            30.588679048796095
          ],
          [
            104.0786361694336,
            30.587940178857682
          ],
          [
            104.07848596572876,
            30.587367550781412
          ],
          [
            104.0783143043518,
            30.586351589417728
          ],
          [
            104.0784215927124,
            30.585206311113062
          ],
          [
            104.07892048358917,
            30.583880911668857
          ],
          [
            104.07909750938416,
            30.583520695447827
          ],
          [
            104.0796822309494,
            30.58227840103355
          ],
          [
            104.07977342605591,
            30.582098290113183
          ],
          [
            104.07994508743286,
            30.580976052995087
          ],
          [
            104.07985389232635,
            30.579484334988948
          ],
          [
            104.07915115356445,
            30.578057252011334
          ],
          [
            104.07815873622894,
            30.577272116497923
          ],
          [
            104.07734870910645,
            30.57688878332005
          ],
          [
            104.0738832950592,
            30.573180072039243
          ],
          [
            104.07362043857574,
            30.572427227899553
          ],
          [
            104.07367944717407,
            30.571752896285464
          ],
          [
            104.07401740550993,
            30.570879953192005
          ],
          [
            104.07485961914062,
            30.570080903156
          ],
          [
            104.07585203647612,
            30.569734492135392
          ],
          [
            104.07756328582762,
            30.569614402692835
          ],
          [
            104.08073902130127,
            30.569965432953257
          ],
          [
            104.0815544128418,
            30.56983610616299
          ],
          [
            104.08226251602173,
            30.56931879727769
          ],
          [
            104.08269166946411,
            30.568561304289485
          ],
          [
            104.08286333084105,
            30.567785329729148
          ],
          [
            104.08588886260986,
            30.569207945014753
          ],
          [
            104.08671498298645,
            30.569406555229328
          ],
          [
            104.08722996711731,
            30.569471218932364
          ],
          [
            104.08792734146118,
            30.569448124757653
          ],
          [
            104.08847451210022,
            30.569369604522514
          ],
          [
            104.08972442150116,
            30.56875991582806
          ],
          [
            104.09031450748442,
            30.568607493055687
          ],
          [
            104.09087240695953,
            30.568570542044505
          ],
          [
            104.0916395187378,
            30.568598255304202
          ],
          [
            104.0923261642456,
            30.56887538746587
          ],
          [
            104.09303426742552,
            30.569448124757653
          ],
          [
            104.09350633621216,
            30.569780680342962
          ],
          [
            104.09404277801512,
            30.57007628435052
          ],
          [
            104.09708976745605,
            30.570796815344785
          ],
          [
            104.09910678863525,
            30.571355685058347
          ],
          [
            104.10050690174101,
            30.571545053404396
          ],
          [
            104.10232007503508,
            30.57161433441423
          ],
          [
            104.10394012928009,
            30.571526578460084
          ],
          [
            104.1054904460907,
            30.571300260106636
          ],
          [
            104.10678327083588,
            30.570889190726195
          ],
          [
            104.10819411277771,
            30.570200992021018
          ],
          [
            104.1094923019409,
            30.569429649413937
          ],
          [
            104.1103559732437,
            30.568639825178927
          ],
          [
            104.11131620407104,
            30.56730496141444
          ],
          [
            104.11170244216919,
            30.566140982171607
          ],
          [
            104.1118311882019,
            30.56427489106884
          ],
          [
            104.1120457649231,
            30.56349888222205
          ],
          [
            104.11298990249634,
            30.56163274030046
          ],
          [
            104.11303281784058,
            30.56105995686832
          ],
          [
            104.11286115646362,
            30.560634986330534
          ],
          [
            104.11268949508667,
            30.560450215950993
          ],
          [
            104.112389087677,
            30.56030239939401
          ],
          [
            104.11195993423462,
            30.560265445219578
          ],
          [
            104.11150932312012,
            30.560265445219578
          ],
          [
            104.11101579666138,
            30.560210013931567
          ],
          [
            104.11060810089111,
            30.560099151260506
          ],
          [
            104.10979270935057,
            30.559711130914387
          ],
          [
            104.10927772521973,
            30.559249199907903
          ],
          [
            104.10859644412994,
            30.558463912150348
          ],
          [
            104.1080117225647,
            30.55760470767675
          ],
          [
            104.10755574703217,
            30.55675011503614
          ],
          [
            104.1064453125,
            30.554167813712343
          ],
          [
            104.10631656646729,
            30.552855848973806
          ],
          [
            104.10640239715576,
            30.551340600025394
          ],
          [
            104.10668134689331,
            30.550324261258105
          ],
          [
            104.1071105003357,
            30.54943726600192
          ],
          [
            104.10768985748291,
            30.548753534960483
          ],
          [
            104.10854816436768,
            30.548180675515578
          ],
          [
            104.10921335220337,
            30.54799588142518
          ],
          [
            104.10951375961304,
            30.547478456100688
          ],
          [
            104.11043643951415,
            30.546480556615855
          ],
          [
            104.11120891571045,
            30.54649903632914
          ],
          [
            104.11164343357086,
            30.546697693024978
          ],
          [
            104.11164343357086,
            30.546859390035497
          ],
          [
            104.11200821399687,
            30.546965647924324
          ],
          [
            104.11294162273407,
            30.547108864894824
          ],
          [
            104.11419153213501,
            30.54704418628913
          ],
          [
            104.11434710025786,
            30.547122724590434
          ],
          [
            104.1150176525116,
            30.547723309499762
          ],
          [
            104.11506056785583,
            30.547848045899574
          ],
          [
            104.11492645740509,
            30.548222254137464
          ],
          [
            104.1149452328682,
            30.548349299816177
          ],
          [
            104.11512762308121,
            30.54910926049516
          ],
          [
            104.11517322063445,
            30.549197036725293
          ],
          [
            104.11532878875732,
            30.54922937531638
          ],
          [
            104.11550849676132,
            30.549213206022184
          ],
          [
            104.11593765020369,
            30.54896373657034
          ],
          [
            104.11603420972824,
            30.548940637514594
          ],
          [
            104.11612272262573,
            30.548952187043167
          ],
          [
            104.11688178777695,
            30.549402617585233
          ],
          [
            104.1169837117195,
            30.54942340663673
          ],
          [
            104.11710172891617,
            30.549397997795413
          ],
          [
            104.1173779964447,
            30.5492871227737
          ],
          [
            104.11853939294815,
            30.548448626321903
          ],
          [
            104.11861717700957,
            30.54841397755238
          ],
          [
            104.11868155002594,
            30.548400118041087
          ],
          [
            104.11952912807465,
            30.548430146979676
          ],
          [
            104.11962032318115,
            30.548446316404323
          ],
          [
            104.12003338336945,
            30.548614940243485
          ],
          [
            104.12092924118042,
            30.548845931328593
          ],
          [
            104.12111163139343,
            30.54885517096055
          ],
          [
            104.12127256393433,
            30.54880897279189
          ],
          [
            104.12164807319641,
            30.54836546925426
          ],
          [
            104.12190556526184,
            30.54840242795979
          ],
          [
            104.12216305732726,
            30.548587221276346
          ],
          [
            104.12256002426147,
            30.54852254365556
          ],
          [
            104.12295699119568,
            30.548448626321903
          ],
          [
            104.12365436553955,
            30.5485687419605
          ],
          [
            104.12418007850647,
            30.548014360850054
          ],
          [
            104.1241157054901,
            30.54773716910764
          ],
          [
            104.12412643432617,
            30.547626292189097
          ],
          [
            104.12472724914551,
            30.54729366067364
          ],
          [
            104.12482649087906,
            30.547289040783465
          ],
          [
            104.12487745285034,
            30.547300590508492
          ],
          [
            104.12522345781325,
            30.547487695862817
          ],
          [
            104.12529319524765,
            30.547496935624057
          ],
          [
            104.12589132785797,
            30.547425327451442
          ],
          [
            104.12595301866531,
            30.547418397625492
          ],
          [
            104.1260227560997,
            30.547397608144646
          ],
          [
            104.12611395120621,
            30.547418397625492
          ],
          [
            104.12629634141922,
            30.547614742502795
          ],
          [
            104.12649750709534,
            30.547781057852927
          ],
          [
            104.12657797336578,
            30.54780415718459
          ],
          [
            104.12677377462386,
            30.54780646711744
          ],
          [
            104.12681668996811,
            30.54779491745259
          ],
          [
            104.12678718566895,
            30.54781108698301
          ],
          [
            104.12704467773438,
            30.53387657299763
          ],
          [
            104.12601470947266,
            30.52973649132263
          ],
          [
            104.12155151367188,
            30.532102273884586
          ],
          [
            104.117431640625,
            30.531806554215013
          ],
          [
            104.1177749633789,
            30.524709012097027
          ],
          [
            104.11897659301758,
            30.50511414795302
          ],
          [
            104.117431640625,
            30.496017831341284
          ],
          [
            104.11721706390381,
            30.49544466095337
          ],
          [
            104.1090202331543,
            30.49557408682
          ],
          [
            104.10541534423828,
            30.4954261715298
          ],
          [
            104.10490036010742,
            30.493355333853483
          ],
          [
            104.10627365112305,
            30.490544840792403
          ],
          [
            104.10558700561523,
            30.488325973138874
          ],
          [
            104.10507202148438,
            30.483666186386614
          ],
          [
            104.09528732299805,
            30.48566326517115
          ],
          [
            104.09528732299805,
            30.49217197824867
          ],
          [
            104.08086776733397,
            30.493207415189985
          ],
          [
            104.07975196838379,
            30.488991638747354
          ],
          [
            104.06842231750488,
            30.490175033030358
          ],
          [
            104.06704902648924,
            30.535207276064373
          ],
          [
            104.0588092803955,
            30.53454192680974
          ],
          [
            104.05503273010254,
            30.536464034443824
          ],
          [
            104.0540885925293,
            30.540382059131083
          ],
          [
            104.04387474060059,
            30.540234212012663
          ],
          [
            104.04335975646971,
            30.53291549833425
          ],
          [
            104.04142856597899,
            30.528368746998297
          ],
          [
            104.03323173522949,
            30.528442679616198
          ],
          [
            104.03258800506592,
            30.52899717245727
          ],
          [
            104.03087139129637,
            30.529625593851495
          ],
          [
            104.02855396270752,
            30.52866447713238
          ],
          [
            104.02675151824951,
            30.52870144333585
          ],
          [
            104.02241706848145,
            30.527037950255746
          ],
          [
            104.01722431182861,
            30.528109982392568
          ],
          [
            104.01748180389404,
            30.530475804562
          ],
          [
            104.01791095733643,
            30.531917449196587
          ],
          [
            104.01816844940186,
            30.53380264451493
          ],
          [
            104.01731014251709,
            30.53587262076145
          ],
          [
            104.01761054992676,
            30.53672277679748
          ],
          [
            104.01670932769775,
            30.53772077656194
          ],
          [
            104.01679515838623,
            30.539605859248322
          ],
          [
            104.0161943435669,
            30.540345097372604
          ],
          [
            104.01503562927246,
            30.54097344535385
          ],
          [
            104.01336193084717,
            30.541084329869697
          ],
          [
            104.0107011795044,
            30.540012440913024
          ],
          [
            104.00932788848877,
            30.539975479013837
          ],
          [
            104.00864124298096,
            30.540936483820452
          ],
          [
            104.00838375091553,
            30.542119245911852
          ],
          [
            104.00881290435791,
            30.543154150924355
          ],
          [
            104.01061534881592,
            30.5442629654837
          ],
          [
            104.01185989379883,
            30.54485432807133
          ],
          [
            104.01263236999512,
            30.54500216815546
          ],
          [
            104.01366233825684,
            30.54511304807085
          ],
          [
            104.0141773223877,
            30.545039128141326
          ],
          [
            104.01503562927246,
            30.545408727226135
          ],
          [
            104.01559352874756,
            30.546074002033322
          ],
          [
            104.0156364440918,
            30.54736757888668
          ],
          [
            104.01533603668213,
            30.54847634532861
          ],
          [
            104.01486396789551,
            30.549400307690348
          ],
          [
            104.01374816894531,
            30.549880764643095
          ],
          [
            104.01301860809326,
            30.55039817716362
          ],
          [
            104.0136730670929,
            30.551839526071582
          ],
          [
            104.01385545730591,
            30.551941158840666
          ],
          [
            104.01649475097656,
            30.55353955112072
          ],
          [
            104.01906967163085,
            30.55605257708668
          ],
          [
            104.02233123779295,
            30.559008994915498
          ],
          [
            104.02336120605469,
            30.56196532267716
          ],
          [
            104.02301788330077,
            30.566104030220888
          ],
          [
            104.0240478515625,
            30.5677299027373
          ],
          [
            104.02748107910156,
            30.56743429157932
          ],
          [
            104.03091430664062,
            30.569060141800456
          ],
          [
            104.03228759765625,
            30.57541547664092
          ],
          [
            104.02765274047852,
            30.57718898413019
          ],
          [
            104.02164459228516,
            30.579701397549737
          ],
          [
            104.01623725891113,
            30.585058532281685
          ],
          [
            104.01621580123901,
            30.585007733256383
          ],
          [
            104.02044296264648,
            30.589602628298536
          ],
          [
            104.0243911743164,
            30.592779674575983
          ],
          [
            104.02971267700195,
            30.59499615681113
          ],
          [
            104.03048515319824,
            30.59595661670199
          ],
          [
            104.03048515319824,
            30.601275914486067
          ],
          [
            104.02833938598633,
            30.603639953090326
          ],
          [
            104.02301788330077,
            30.603713828367127
          ],
          [
            104.02250289916992,
            30.608884957731544
          ],
          [
            104.02301788330077,
            30.61191363386011
          ],
          [
            104.02379035949707,
            30.61427741282775
          ],
          [
            104.02421951293945,
            30.61582861141568
          ],
          [
            104.02456283569336,
            30.617453649947834
          ],
          [
            104.02585029602051,
            30.620112745074337
          ],
          [
            104.02642965316772,
            30.62105449041677
          ],
          [
            104.02793169021605,
            30.620906766263023
          ],
          [
            104.02870416641235,
            30.620795972999783
          ],
          [
            104.0321159362793,
            30.625966190160003
          ],
          [
            104.03597831726074,
            30.631948525318574
          ],
          [
            104.0419864654541,
            30.640662870231083
          ],
          [
            104.05237197875977,
            30.63560421473627
          ],
          [
            104.05597686767578,
            30.635714991047177
          ],
          [
            104.0561056137085,
            30.631911598458185
          ],
          [
            104.05554771423338,
            30.627277165616874
          ],
          [
            104.05486106872559,
            30.622402462905523
          ],
          [
            104.08446997404099,
            30.622750993833787
          ]
            
          ]
        ]
          
              
var gxxq = [     
   [
            [
              103.8763439655304,
              30.75589703985065
            ],
            [
              103.87682676315308,
              30.75588781998434
            ],
            [
              103.87762069702147,
              30.7558693802491
            ],
            [
              103.87824296951294,
              30.755721862239998
            ],
            [
              103.87989521026611,
              30.75496582889594
            ],
            [
              103.8811182975769,
              30.75435730994126
            ],
            [
              103.88255596160887,
              30.753933188395603
            ],
            [
              103.88390779495238,
              30.75345374439886
            ],
            [
              103.88551712036131,
              30.752808335248915
            ],
            [
              103.88689041137695,
              30.752255123963092
            ],
            [
              103.88757705688477,
              30.752033838558884
            ],
            [
              103.88978719711302,
              30.751683468962263
            ],
            [
              103.89055967330933,
              30.751296216872362
            ],
            [
              103.89158964157104,
              30.750650793261986
            ],
            [
              103.89691114425659,
              30.745856082454324
            ],
            [
              103.9008378982544,
              30.74290537255077
            ],
            [
              103.90442132949829,
              30.74069228080818
            ],
            [
              103.90652418136597,
              30.73980702987609
            ],
            [
              103.90897035598755,
              30.739161529276828
            ],
            [
              103.91088008880614,
              30.738940213790094
            ],
            [
              103.91259670257568,
              30.739106200452802
            ],
            [
              103.91349792480467,
              30.739567273015496
            ],
            [
              103.91401290893555,
              30.74008367166615
            ],
            [
              103.91414165496825,
              30.740729166087146
            ],
            [
              103.91399145126343,
              30.741319328630222
            ],
            [
              103.91369104385376,
              30.741798833034476
            ],
            [
              103.9130687713623,
              30.742370546704887
            ],
            [
              103.91278982162476,
              30.742868488105007
            ],
            [
              103.91270399093628,
              30.743366426931274
            ],
            [
              103.9128541946411,
              30.74384592114615
            ],
            [
              103.91321897506714,
              30.7441225513383
            ],
            [
              103.9140772819519,
              30.744362296862228
            ],
            [
              103.91502141952515,
              30.74438073880088
            ],
            [
              103.91590118408203,
              30.74430697102505
            ],
            [
              103.91729593276978,
              30.743809037060505
            ],
            [
              103.91995668411255,
              30.74273939243359
            ],
            [
              103.92197370529175,
              30.7413746561833
            ],
            [
              103.92298221588135,
              30.740526296878002
            ],
            [
              103.92377614974976,
              30.73971481576954
            ],
            [
              103.92585754394531,
              30.737944287808656
            ],
            [
              103.92937660217285,
              30.73587863073339
            ],
            [
              103.93064260482788,
              30.734403134282182
            ],
            [
              103.93081426620483,
              30.734089588375728
            ],
            [
              103.93115758895874,
              30.73294605936601
            ],
            [
              103.93154382705688,
              30.732263624298295
            ],
            [
              103.9317798614502,
              30.73202384866782
            ],
            [
              103.93240213394165,
              30.731784072440774
            ],
            [
              103.93293857574463,
              30.73156274000942
            ],
            [
              103.93390417098999,
              30.731286073755427
            ],
            [
              103.93433332443237,
              30.731304518197074
            ],
            [
              103.934805393219,
              30.731415184772793
            ],
            [
              103.93579244613647,
              30.73169185065616
            ],
            [
              103.93785238265991,
              30.73182096112993
            ],
            [
              103.93871068954468,
              30.731839405469213
            ],
            [
              103.93903255462646,
              30.73169185065616
            ],
            [
              103.93922567367554,
              30.731525851221438
            ],
            [
              103.93941879272461,
              30.731267629310267
            ],
            [
              103.93967628479004,
              30.730861850623413
            ],
            [
              103.93959045410156,
              30.730492959425575
            ],
            [
              103.93948316574095,
              30.73032695792591
            ],
            [
              103.93911838531493,
              30.730124066815797
            ],
            [
              103.93894672393799,
              30.729884285862322
            ],
            [
              103.93898963928223,
              30.72942316696747
            ],
            [
              103.93933296203613,
              30.728998935635758
            ],
            [
              103.93959045410156,
              30.728722262022192
            ],
            [
              103.93993377685547,
              30.728611592354365
            ],
            [
              103.94034147262573,
              30.728464032599646
            ],
            [
              103.94085645675659,
              30.72853781250525
            ],
            [
              103.9409852027893,
              30.728722262022192
            ],
            [
              103.94137144088745,
              30.72918338427029
            ],
            [
              103.94167184829712,
              30.729349387739557
            ],
            [
              103.94205808639526,
              30.729349387739557
            ],
            [
              103.94261598587036,
              30.72925716362525
            ],
            [
              103.94328117370605,
              30.728980490752903
            ],
            [
              103.94375324249266,
              30.728593147397394
            ],
            [
              103.94409656524658,
              30.728224247516387
            ],
            [
              103.94424676895142,
              30.727836901121815
            ],
            [
              103.94416093826294,
              30.72759711447848
            ],
            [
              103.94394636154175,
              30.72746799834653
            ],
            [
              103.94353866577148,
              30.72746799834653
            ],
            [
              103.9432168006897,
              30.727375772432104
            ],
            [
              103.94304513931274,
              30.727320436841104
            ],
            [
              103.9430022239685,
              30.727043758409557
            ],
            [
              103.9430022239685,
              30.726730188560424
            ],
            [
              103.94315242767334,
              30.726342836162036
            ],
            [
              103.94343137741087,
              30.726010818581695
            ],
            [
              103.94381761550903,
              30.725771027395815
            ],
            [
              103.94424676895142,
              30.72575258189527
            ],
            [
              103.94514799118042,
              30.725844809362698
            ],
            [
              103.94562005996704,
              30.72578947289282
            ],
            [
              103.94592046737671,
              30.725549681156348
            ],
            [
              103.94589900970459,
              30.725236106447074
            ],
            [
              103.94566297531128,
              30.724996313334106
            ],
            [
              103.94465446472168,
              30.724332267754463
            ],
            [
              103.94416093826294,
              30.723834230567967
            ],
            [
              103.94413948059082,
              30.72329929887221
            ],
            [
              103.94443988800049,
              30.722801256349353
            ],
            [
              103.94459009170532,
              30.722690579883764
            ],
            [
              103.9447832107544,
              30.722506118825454
            ],
            [
              103.94491195678711,
              30.72235854972469
            ],
            [
              103.94525527954102,
              30.7222847650896
            ],
            [
              103.94628524780273,
              30.722506118825454
            ],
            [
              103.94662857055664,
              30.722192534216333
            ],
            [
              103.94692897796631,
              30.721897394828986
            ],
            [
              103.94697189331055,
              30.72165759341149
            ],
            [
              103.94701480865477,
              30.720735274708836
            ],
            [
              103.9471435546875,
              30.720513916907343
            ],
            [
              103.94750833511353,
              30.72044013086057
            ],
            [
              103.94802331924437,
              30.72038479128846
            ],
            [
              103.94866704940794,
              30.720698381777204
            ],
            [
              103.95057678222656,
              30.721510023011962
            ],
            [
              103.95134925842285,
              30.72171293225307
            ],
            [
              103.95338773727417,
              30.721694485976087
            ],
            [
              103.95383834838867,
              30.72158380823996
            ],
            [
              103.95482540130615,
              30.720864399858346
            ],
            [
              103.95534038543701,
              30.720938185580422
            ],
            [
              103.95643472671509,
              30.72158380823996
            ],
            [
              103.9566707611084,
              30.72154691563302
            ],
            [
              103.95847856998442,
              30.72218331112415
            ],
            [
              103.96033465862274,
              30.721754436363437
            ],
            [
              103.96089792251587,
              30.721740601661974
            ],
            [
              103.96120369434357,
              30.721906617948495
            ],
            [
              103.9613538980484,
              30.722155641842317
            ],
            [
              103.96141827106474,
              30.722584514818358
            ],
            [
              103.96109640598296,
              30.723368471241344
            ],
            [
              103.96123588085173,
              30.724032523459748
            ],
            [
              103.9618420600891,
              30.7243553249693
            ],
            [
              103.96209955215454,
              30.724959422033006
            ],
            [
              103.96224975585938,
              30.725807918386316
            ],
            [
              103.96255016326904,
              30.726158382091914
            ],
            [
              103.9632797241211,
              30.726287499978053
            ],
            [
              103.9639663696289,
              30.726029264032825
            ],
            [
              103.9651894569397,
              30.725162324014303
            ],
            [
              103.9659833908081,
              30.724664291116035
            ],
            [
              103.9661979675293,
              30.722690579883764
            ],
            [
              103.96647691726685,
              30.72243233430329
            ],
            [
              103.96694898605347,
              30.72222942657623
            ],
            [
              103.96729230880737,
              30.72180516358515
            ],
            [
              103.96757125854492,
              30.721676039695545
            ],
            [
              103.96795749664307,
              30.72165759341149
            ],
            [
              103.96827936172485,
              30.721749824796497
            ],
            [
              103.96879434585571,
              30.721030417653623
            ],
            [
              103.96907329559325,
              30.720882846294153
            ],
            [
              103.96950244903564,
              30.720772167626322
            ],
            [
              103.97021055221558,
              30.720403237816015
            ],
            [
              103.9711332321167,
              30.71942556699162
            ],
            [
              103.97403001785278,
              30.71865080193936
            ],
            [
              103.97598266601561,
              30.715883733078442
            ],
            [
              103.97624015808105,
              30.715828390891332
            ],
            [
              103.9807677268982,
              30.717101253159388
            ],
            [
              103.98093938827515,
              30.717322618795304
            ],
            [
              103.98085355758667,
              30.717839136636275
            ],
            [
              103.97737741470337,
              30.72241388816394
            ],
            [
              103.97694826126097,
              30.722967270809523
            ],
            [
              103.97669076919556,
              30.723520650278726
            ],
            [
              103.97448062896729,
              30.725881700324955
            ],
            [
              103.97392272949219,
              30.726545735231497
            ],
            [
              103.97366523742674,
              30.727228210785487
            ],
            [
              103.97357940673828,
              30.727670895047673
            ],
            [
              103.97353649139404,
              30.72820580248529
            ],
            [
              103.97362232208252,
              30.728500922559498
            ],
            [
              103.97372961044312,
              30.729072715131935
            ],
            [
              103.97392272949219,
              30.72929405328157
            ],
            [
              103.9742660522461,
              30.730400736405507
            ],
            [
              103.9752960205078,
              30.731064740180397
            ],
            [
              103.97658348083496,
              30.731802516787134
            ],
            [
              103.97821426391602,
              30.732392734006083
            ],
            [
              103.97950172424316,
              30.732466510904306
            ],
            [
              103.97950172424316,
              30.73290917110753
            ],
            [
              103.97641181945801,
              30.736524153326393
            ],
            [
              103.96954536437988,
              30.742425873654216
            ],
            [
              103.97194862365723,
              30.746335564276233
            ],
            [
              103.96920204162598,
              30.748622290897163
            ],
            [
              103.96448135375975,
              30.75083520044918
            ],
            [
              103.96044731140137,
              30.75304805915245
            ],
            [
              103.94697189331055,
              30.75924379298793
            ],
            [
              103.94577026367188,
              30.759981353791616
            ],
            [
              103.94371032714844,
              30.76315280085516
            ],
            [
              103.9419937133789,
              30.76691414912237
            ],
            [
              103.93632888793945,
              30.771412823598634
            ],
            [
              103.94010543823241,
              30.775985031323472
            ],
            [
              103.9310073852539,
              30.782253027476553
            ],
            [
              103.92525672912598,
              30.79301827930348
            ],
            [
              103.92345428466797,
              30.798400453249858
            ],
            [
              103.92087936401367,
              30.80429837998386
            ],
            [
              103.91993522644043,
              30.806215128240485
            ],
            [
              103.9185619354248,
              30.807247207619508
            ],
            [
              103.91401290893555,
              30.808795305902553
            ],
            [
              103.88483047485352,
              30.769937871533127
            ],
            [
              103.87298583984375,
              30.784760111575803
            ],
            [
              103.86534690856934,
              30.78778327312566
            ],
            [
              103.85324478149414,
              30.77104408770176
            ],
            [
              103.87504577636719,
              30.759981353791616
            ],
            [
              103.8760757446289,
              30.758284955495245
            ],
            [
              103.8763439655304,
              30.75589703985065
            ]
          ],
        ]
          
          
          
          
var gxdq = [ 
        [
            
          [
            104.18554097414017,
            30.32420946420142
          ],
          [
            104.18553829193115,
            30.324211779436713
          ],
          [
            104.18676674365997,
            30.326351033468487
          ],
          [
            104.18779134750366,
            30.328490240777764
          ],
          [
            104.18785035610199,
            30.32882824977923
          ],
          [
            104.18806493282318,
            30.329110694762516
          ],
          [
            104.18875962495804,
            30.32959455353757
          ],
          [
            104.18980568647385,
            30.329872366108
          ],
          [
            104.19167518615721,
            30.329995066409015
          ],
          [
            104.19603109359741,
            30.33710216109928
          ],
          [
            104.19774234294891,
            30.338771211792242
          ],
          [
            104.19834047555923,
            30.339768924761763
          ],
          [
            104.19843435287476,
            30.34063005167808
          ],
          [
            104.19828414916991,
            30.34287079804396
          ],
          [
            104.1969296336174,
            30.346160065234045
          ],
          [
            104.19631272554398,
            30.34886593122777
          ],
          [
            104.19633954763412,
            30.34969688788984
          ],
          [
            104.19657021760939,
            30.35129397140938
          ],
          [
            104.19772624969482,
            30.35351827183304
          ],
          [
            104.19972449541092,
            30.35540460561498
          ],
          [
            104.20635223388672,
            30.36177621018143
          ],
          [
            104.207124710083,
            30.362461254468705
          ],
          [
            104.20858383178711,
            30.364821843297864
          ],
          [
            104.21000003814697,
            30.367173118272323
          ],
          [
            104.21107292175292,
            30.368357991324174
          ],
          [
            104.21369075775146,
            30.37106092923761
          ],
          [
            104.2157506942749,
            30.372986264098625
          ],
          [
            104.2174243927002,
            30.3744302403619
          ],
          [
            104.21823978424072,
            30.375429903742283
          ],
          [
            104.21855092048645,
            30.376346252860607
          ],
          [
            104.22047138214111,
            30.375133708250864
          ],
          [
            104.22188758850098,
            30.37528180610877
          ],
          [
            104.22334671020508,
            30.376170388544413
          ],
          [
            104.2241621017456,
            30.376540628841997
          ],
          [
            104.22467708587646,
            30.376392532891227
          ],
          [
            104.22467708587646,
            30.374615363981377
          ],
          [
            104.22484874725342,
            30.373689742378332
          ],
          [
            104.22849655151366,
            30.37169036980603
          ],
          [
            104.23141479492188,
            30.37020932672447
          ],
          [
            104.23450469970703,
            30.369024476106393
          ],
          [
            104.23759460449219,
            30.367987720037757
          ],
          [
            104.24051284790039,
            30.365766062875743
          ],
          [
            104.24291610717773,
            30.363692470653177
          ],
          [
            104.24617767333984,
            30.361470715911796
          ],
          [
            104.25081253051758,
            30.359397032618983
          ],
          [
            104.2557907104492,
            30.355545789870668
          ],
          [
            104.26111221313477,
            30.35006491379473
          ],
          [
            104.26403045654295,
            30.34828726643185
          ],
          [
            104.26763534545897,
            30.3463614453484
          ],
          [
            104.27003860473633,
            30.34325042354528
          ],
          [
            104.27244186401367,
            30.34102820457309
          ],
          [
            104.2743730545044,
            30.338694820356928
          ],
          [
            104.27626132965088,
            30.33750958802677
          ],
          [
            104.27836418151855,
            30.337398471760164
          ],
          [
            104.2793083190918,
            30.338398513619605
          ],
          [
            104.28012371063231,
            30.34006522735121
          ],
          [
            104.28128242492676,
            30.340398566692347
          ],
          [
            104.28235530853271,
            30.34065782983959
          ],
          [
            104.28312778472899,
            30.3407319048984
          ],
          [
            104.28415775299072,
            30.33936150723686
          ],
          [
            104.28540229797362,
            30.337361432976586
          ],
          [
            104.28604602813719,
            30.33654657619303
          ],
          [
            104.28647518157959,
            30.335398357396045
          ],
          [
            104.28707599639891,
            30.33425012513356
          ],
          [
            104.28836345672606,
            30.334213085158982
          ],
          [
            104.28977966308594,
            30.334620524108843
          ],
          [
            104.29123878479004,
            30.335176119944524
          ],
          [
            104.29235458374023,
            30.33595394881798
          ],
          [
            104.29299831390381,
            30.336583615284862
          ],
          [
            104.29295539855956,
            30.337954051832096
          ],
          [
            104.29261207580566,
            30.33936150723686
          ],
          [
            104.29235458374023,
            30.34017634059105
          ],
          [
            104.29080963134766,
            30.346213303695375
          ],
          [
            104.29080963134766,
            30.349027956757453
          ],
          [
            104.29012298583984,
            30.351842528864772
          ],
          [
            104.28806304931639,
            30.355397662121728
          ],
          [
            104.28823471069336,
            30.356175330301554
          ],
          [
            104.29149627685547,
            30.35628642525117
          ],
          [
            104.29218292236328,
            30.356249393615318
          ],
          [
            104.29252624511719,
            30.35710111769353
          ],
          [
            104.29261207580566,
            30.358100958236307
          ],
          [
            104.2928695678711,
            30.358693451513467
          ],
          [
            104.29437160491943,
            30.35847126695508
          ],
          [
            104.29600238800049,
            30.358175020092204
          ],
          [
            104.29643154144287,
            30.35858235929735
          ],
          [
            104.29677486419678,
            30.359878427301638
          ],
          [
            104.29720401763916,
            30.360470909812573
          ],
          [
            104.29840564727783,
            30.360211699155627
          ],
          [
            104.29969310760498,
            30.359248910701528
          ],
          [
            104.3005084991455,
            30.359174849658707
          ],
          [
            104.30076599121094,
            30.360322789521298
          ],
          [
            104.30093765258789,
            30.361618834464732
          ],
          [
            104.30153846740721,
            30.36221130643335
          ],
          [
            104.30286884307861,
            30.36195210038869
          ],
          [
            104.30419921875,
            30.360804179647847
          ],
          [
            104.30548667907713,
            30.358249081892023
          ],
          [
            104.30643081665038,
            30.356990023669102
          ],
          [
            104.30784702301025,
            30.355879076485785
          ],
          [
            104.30943489074706,
            30.355397662121728
          ],
          [
            104.31089401245117,
            30.354953277529233
          ],
          [
            104.32188034057617,
            30.356138298623645
          ],
          [
            104.32737350463867,
            30.35910078855978
          ],
          [
            104.33012008666991,
            30.361915070897652
          ],
          [
            104.33372497558592,
            30.365914174923507
          ],
          [
            104.33784484863281,
            30.367543392643316
          ],
          [
            104.33835983276366,
            30.368728261208474
          ],
          [
            104.33595657348633,
            30.370357432042177
          ],
          [
            104.3345832824707,
            30.372282780756848
          ],
          [
            104.33286666870117,
            30.375689074061068
          ],
          [
            104.3345832824707,
            30.378502879007097
          ],
          [
            104.33698654174805,
            30.380724246747615
          ],
          [
            104.33921813964844,
            30.38161277970734
          ],
          [
            104.34436798095703,
            30.38235321766959
          ],
          [
            104.34514045715332,
            30.383537906740475
          ],
          [
            104.34496879577637,
            30.384944706358652
          ],
          [
            104.34514045715332,
            30.386203404639215
          ],
          [
            104.34479713439941,
            30.387832284227873
          ],
          [
            104.34514045715332,
            30.38849863623295
          ],
          [
            104.34659957885742,
            30.389831326609237
          ],
          [
            104.34659957885742,
            30.391386109072137
          ],
          [
            104.34651374816895,
            30.39353314893672
          ],
          [
            104.34685707092285,
            30.39442156542216
          ],
          [
            104.34840202331543,
            30.395532074666125
          ],
          [
            104.35183525085448,
            30.394791736572984
          ],
          [
            104.35483932495117,
            30.394717702455036
          ],
          [
            104.35810089111327,
            30.39656853856939
          ],
          [
            104.36084747314453,
            30.39827127681912
          ],
          [
            104.36385154724121,
            30.400788313767322
          ],
          [
            104.36591148376465,
            30.40197277929529
          ],
          [
            104.36779975891113,
            30.403009174846854
          ],
          [
            104.36882972717285,
            30.404489720838985
          ],
          [
            104.36882972717285,
            30.407672818701744
          ],
          [
            104.36822891235352,
            30.410115590864454
          ],
          [
            104.36788558959961,
            30.411522007715064
          ],
          [
            104.36848640441895,
            30.411966135142258
          ],
          [
            104.37123298645018,
            30.41100385649601
          ],
          [
            104.37277793884277,
            30.410707768850255
          ],
          [
            104.37440872192383,
            30.410855812785393
          ],
          [
            104.3748378753662,
            30.411744071681248
          ],
          [
            104.37492370605469,
            30.412706343030358
          ],
          [
            104.37586784362793,
            30.413816644337622
          ],
          [
            104.37664031982422,
            30.41440879987054
          ],
          [
            104.37732696533203,
            30.413964683557655
          ],
          [
            104.37930107116699,
            30.413150465068853
          ],
          [
            104.38015937805176,
            30.41492693301478
          ],
          [
            104.38050270080566,
            30.41633328054082
          ],
          [
            104.38204765319824,
            30.41699943808526
          ],
          [
            104.38316345214844,
            30.41648131594365
          ],
          [
            104.38290596008301,
            30.414260761324122
          ],
          [
            104.38359260559082,
            30.413520565223973
          ],
          [
            104.3854808807373,
            30.413520565223973
          ],
          [
            104.38762664794922,
            30.413224485212158
          ],
          [
            104.39045906066895,
            30.413372525330324
          ],
          [
            104.39260482788085,
            30.414260761324122
          ],
          [
            104.3950080871582,
            30.415519081811507
          ],
          [
            104.39835548400879,
            30.41988606823241
          ],
          [
            104.40178871154785,
            30.423512738916244
          ],
          [
            104.40393447875977,
            30.426991255563397
          ],
          [
            104.40462112426758,
            30.427953376568325
          ],
          [
            104.40530776977539,
            30.42824941189154
          ],
          [
            104.40702438354492,
            30.427213284329845
          ],
          [
            104.40788269042969,
            30.425807093607872
          ],
          [
            104.40865516662598,
            30.424474894226915
          ],
          [
            104.40985679626465,
            30.423290701727836
          ],
          [
            104.4111442565918,
            30.42269860008843
          ],
          [
            104.41492080688477,
            30.421292344294063
          ],
          [
            104.41586494445801,
            30.420256142845158
          ],
          [
            104.41740989685059,
            30.41810969055452
          ],
          [
            104.41895484924316,
            30.41699943808526
          ],
          [
            104.4206714630127,
            30.415223007862238
          ],
          [
            104.42290306091309,
            30.413224485212158
          ],
          [
            104.42427635192871,
            30.411447986280734
          ],
          [
            104.42599296569824,
            30.409893523193663
          ],
          [
            104.42753791809082,
            30.40782086723945
          ],
          [
            104.4297695159912,
            30.406784522760823
          ],
          [
            104.4316577911377,
            30.407228671741567
          ],
          [
            104.43500518798827,
            30.408783177262148
          ],
          [
            104.43852424621582,
            30.41078179084589
          ],
          [
            104.44024085998535,
            30.412114177168935
          ],
          [
            104.44230079650879,
            30.413446545305213
          ],
          [
            104.44521903991698,
            30.41263232249414
          ],
          [
            104.44710731506348,
            30.410337658030098
          ],
          [
            104.44865226745605,
            30.408635130183754
          ],
          [
            104.45380210876465,
            30.406784522760823
          ],
          [
            104.45972442626953,
            30.405155959211367
          ],
          [
            104.46307182312012,
            30.40374945064906
          ],
          [
            104.46393013000488,
            30.403231258176813
          ],
          [
            104.46277141571045,
            30.40321275125192
          ],
          [
            104.46232080459593,
            30.403194244323544
          ],
          [
            104.46156978607178,
            30.4029906678799
          ],
          [
            104.46144104003906,
            30.402694555931475
          ],
          [
            104.46154832839966,
            30.402120836476087
          ],
          [
            104.46161270141602,
            30.401417562867874
          ],
          [
            104.4632863998413,
            30.399455772875754
          ],
          [
            104.46352243423462,
            30.39914114251191
          ],
          [
            104.4636082649231,
            30.398363816090875
          ],
          [
            104.46369409561157,
            30.397993658477763
          ],
          [
            104.46335077285767,
            30.397197814858952
          ],
          [
            104.46292161941528,
            30.39647599759662
          ],
          [
            104.46262121200562,
            30.39584671665653
          ],
          [
            104.46208477020264,
            30.39499533010796
          ],
          [
            104.46161270141602,
            30.393995866864493
          ],
          [
            104.46156978607178,
            30.391959891587316
          ],
          [
            104.46354389190674,
            30.389387098503647
          ],
          [
            104.46313619613647,
            30.388017382462937
          ],
          [
            104.46189165115356,
            30.388072911865056
          ],
          [
            104.45753574371338,
            30.391108471161388
          ],
          [
            104.45650577545166,
            30.390590211616868
          ],
          [
            104.45594787597656,
            30.38849863623295
          ],
          [
            104.45416152477263,
            30.386888277774663
          ],
          [
            104.45358753204346,
            30.383181575991074
          ],
          [
            104.45275068283081,
            30.38079829146946
          ],
          [
            104.45320129394531,
            30.37835478602933
          ],
          [
            104.45440292358398,
            30.375837171077578
          ],
          [
            104.45508956909178,
            30.37465238866319
          ],
          [
            104.45508956909178,
            30.37331949128317
          ],
          [
            104.45405960083008,
            30.37272708661425
          ],
          [
            104.45062637329102,
            30.373171390452463
          ],
          [
            104.44822311401367,
            30.37198657573015
          ],
          [
            104.4466781616211,
            30.370653642004587
          ],
          [
            104.44547653198242,
            30.36858015342299
          ],
          [
            104.44787979125975,
            30.365914174923507
          ],
          [
            104.45096969604492,
            30.36561795060364
          ],
          [
            104.45405960083008,
            30.364729272260305
          ],
          [
            104.45508956909178,
            30.362655658054585
          ],
          [
            104.45594787597656,
            30.360878239457048
          ],
          [
            104.46006774902344,
            30.360137638841763
          ],
          [
            104.46126937866211,
            30.36117447813302
          ],
          [
            104.46281433105469,
            30.361766952793353
          ],
          [
            104.46453094482422,
            30.359989518045797
          ],
          [
            104.46624755859374,
            30.359248910701528
          ],
          [
            104.46779251098633,
            30.36117447813302
          ],
          [
            104.46950912475586,
            30.362655658054585
          ],
          [
            104.4693374633789,
            30.364729272260305
          ],
          [
            104.47225570678711,
            30.36206318877766
          ],
          [
            104.47448730468749,
            30.36206318877766
          ],
          [
            104.47723388671875,
            30.362507541071825
          ],
          [
            104.47843551635741,
            30.360137638841763
          ],
          [
            104.47912216186523,
            30.358212050999125
          ],
          [
            104.47826385498047,
            30.355990171771825
          ],
          [
            104.47998046875,
            30.35406450228875
          ],
          [
            104.48324203491211,
            30.35406450228875
          ],
          [
            104.48856353759766,
            30.354953277529233
          ],
          [
            104.49165344238281,
            30.354953277529233
          ],
          [
            104.49560165405273,
            30.35569391739534
          ],
          [
            104.49800491333008,
            30.35376824208106
          ],
          [
            104.4990348815918,
            30.351694395509025
          ],
          [
            104.50006484985352,
            30.349472368261814
          ],
          [
            104.50246810913085,
            30.34784284954548
          ],
          [
            104.50504302978516,
            30.34784284954548
          ],
          [
            104.50624465942383,
            30.349472368261814
          ],
          [
            104.50658798217773,
            30.351101859843464
          ],
          [
            104.50847625732422,
            30.35213879490344
          ],
          [
            104.51087951660156,
            30.35213879490344
          ],
          [
            104.51465606689453,
            30.3499167777479
          ],
          [
            104.51620101928711,
            30.34769471013487
          ],
          [
            104.5180892944336,
            30.34813912769397
          ],
          [
            104.51997756958008,
            30.349176094149833
          ],
          [
            104.52375411987305,
            30.34887981914083
          ],
          [
            104.52512741088866,
            30.346213303695375
          ],
          [
            104.52701568603516,
            30.34399115199148
          ],
          [
            104.52890396118164,
            30.345028162398517
          ],
          [
            104.53130722045898,
            30.34562073484083
          ],
          [
            104.53336715698241,
            30.34325042354528
          ],
          [
            104.53113555908203,
            30.339546697231103
          ],
          [
            104.53371047973633,
            30.337324394179017
          ],
          [
            104.53611373901367,
            30.336731771512106
          ],
          [
            104.53817367553711,
            30.335250199151083
          ],
          [
            104.53920364379883,
            30.334064925120494
          ],
          [
            104.53903198242188,
            30.331546170166447
          ],
          [
            104.54263687133789,
            30.330509016942763
          ],
          [
            104.54401016235352,
            30.327545661492763
          ],
          [
            104.54401016235352,
            30.325026738862697
          ],
          [
            104.53765869140625,
            30.324434041770782
          ],
          [
            104.53353881835938,
            30.320581423259316
          ],
          [
            104.53130722045898,
            30.320285061713502
          ],
          [
            104.53130722045898,
            30.317321396948888
          ],
          [
            104.5323371887207,
            30.31524677827466
          ],
          [
            104.53096389770508,
            30.31302392380996
          ],
          [
            104.52117919921874,
            30.314802211415493
          ],
          [
            104.52083587646483,
            30.314061262168238
          ],
          [
            104.52169418334961,
            30.312134767906088
          ],
          [
            104.52272415161133,
            30.310356431896864
          ],
          [
            104.52306747436523,
            30.30828166577001
          ],
          [
            104.52255249023438,
            30.305762247858855
          ],
          [
            104.52220916748047,
            30.3036873844871
          ],
          [
            104.52255249023438,
            30.2996857382589
          ],
          [
            104.52049255371094,
            30.299389313522617
          ],
          [
            104.51791763305663,
            30.300130373683043
          ],
          [
            104.51293945312499,
            30.299982162099035
          ],
          [
            104.51019287109375,
            30.299092887890204
          ],
          [
            104.50950622558594,
            30.297166099436673
          ],
          [
            104.51139450073242,
            30.2934606306162
          ],
          [
            104.51379776000977,
            30.291385506922204
          ],
          [
            104.51448440551758,
            30.289013883226843
          ],
          [
            104.51139450073242,
            30.285604573707126
          ],
          [
            104.50899124145508,
            30.283677520264256
          ],
          [
            104.50830459594727,
            30.278933842846154
          ],
          [
            104.50967788696288,
            30.278044377800153
          ],
          [
            104.51139450073242,
            30.275968928008236
          ],
          [
            104.5103645324707,
            30.27359693164586
          ],
          [
            104.50950622558594,
            30.27003882962186
          ],
          [
            104.51208114624023,
            30.266184073558826
          ],
          [
            104.51345443725586,
            30.262180897456524
          ],
          [
            104.51379776000977,
            30.258474107402265
          ],
          [
            104.51602935791016,
            30.2558051319317
          ],
          [
            104.51602935791016,
            30.25298780136615
          ],
          [
            104.51517105102539,
            30.25061524979676
          ],
          [
            104.51190948486328,
            30.248984087355694
          ],
          [
            104.50761795043945,
            30.246166561171986
          ],
          [
            104.5071029663086,
            30.243052359289663
          ],
          [
            104.50899124145508,
            30.239196544037164
          ],
          [
            104.50727462768555,
            30.236230429370867
          ],
          [
            104.50521469116211,
            30.238306719035243
          ],
          [
            104.5016098022461,
            30.24053126643564
          ],
          [
            104.50075149536133,
            30.24156937132543
          ],
          [
            104.49560165405273,
            30.24231086810482
          ],
          [
            104.49182510375977,
            30.241272771047235
          ],
          [
            104.49148178100586,
            30.239641453517258
          ],
          [
            104.48684692382812,
            30.24067956780551
          ],
          [
            104.48392868041992,
            30.241272771047235
          ],
          [
            104.47998046875,
            30.240827868951598
          ],
          [
            104.47792053222656,
            30.238455023761645
          ],
          [
            104.47483062744139,
            30.235933812981585
          ],
          [
            104.468994140625,
            30.236082121288117
          ],
          [
            104.46556091308594,
            30.232670973629617
          ],
          [
            104.46560382843016,
            30.231484459743548
          ],
          [
            104.46577548980713,
            30.228258552732147
          ],
          [
            104.46564674377441,
            30.22714614646456
          ],
          [
            104.47105407714844,
            30.200333349994086
          ],
          [
            104.47174072265625,
            30.190244210264005
          ],
          [
            104.47174072265625,
            30.183715392538552
          ],
          [
            104.46968078613281,
            30.177186142075502
          ],
          [
            104.46762084960938,
            30.165907326727396
          ],
          [
            104.46487426757812,
            30.159970589718387
          ],
		  
		  
		  
		  
		  
		  
		  
		  
		  
		  
		  
		  
		  
		  
		  [
            104.46487426757812,
            30.15996595150285
          ],
          [
            104.46472406387329,
            30.159497490609695
          ],
          [
            104.46464896202087,
            30.159154260423787
          ],
          [
            104.46448802947998,
            30.158644051182723
          ],
          [
            104.4642949104309,
            30.15805034983354
          ],
          [
            104.4636297225952,
            30.157187621189507
          ],
          [
            104.46290016174316,
            30.15644548298369
          ],
          [
            104.46212768554688,
            30.155666231856706
          ],
          [
            104.4614839553833,
            30.154998297418526
          ],
          [
            104.4605827331543,
            30.154033495243763
          ],
          [
            104.45985317230225,
            30.15318000852932
          ],
          [
            104.45916652679443,
            30.152103862579715
          ],
          [
            104.45508956909178,
            30.146314738085053
          ],
          [
            104.45234298706055,
            30.14067372591087
          ],
          [
            104.45114135742186,
            30.134735469964642
          ],
          [
            104.45148468017577,
            30.130875412002318
          ],
          [
            104.44942474365234,
            30.12597589029753
          ],
          [
            104.4466781616211,
            30.124045708929074
          ],
          [
            104.44496154785156,
            30.120927643939744
          ],
          [
            104.44478988647461,
            30.117364020544315
          ],
          [
            104.4418716430664,
            30.114542727501153
          ],
          [
            104.4411849975586,
            30.109939392178905
          ],
          [
            104.43946838378906,
            30.108899899678804
          ],
          [
            104.43569183349608,
            30.10637537236278
          ],
          [
            104.4331169128418,
            30.107563393251965
          ],
          [
            104.43260192871094,
            30.109345397803718
          ],
          [
            104.43105697631835,
            30.111127370215993
          ],
          [
            104.42968368530273,
            30.113206297408112
          ],
          [
            104.42693710327148,
            30.115730650183107
          ],
          [
            104.42264556884766,
            30.116176117505724
          ],
          [
            104.42075729370117,
            30.11587913951386
          ],
          [
            104.41801071166992,
            30.116770070810812
          ],
          [
            104.41629409790038,
            30.118848879252113
          ],
          [
            104.41543579101562,
            30.121670049295695
          ],
          [
            104.40290451049805,
            30.124342662364686
          ],
          [
            104.39552307128906,
            30.125085042046717
          ],
          [
            104.3917465209961,
            30.130133075858343
          ],
          [
            104.39002990722656,
            30.133399313136767
          ],
          [
            104.38591003417969,
            30.142009784255944
          ],
          [
            104.38556671142578,
            30.147799161369214
          ],
          [
            104.38333511352539,
            30.155517802372515
          ],
          [
            104.38076019287108,
            30.16041585739418
          ],
          [
            104.37664031982422,
            30.1656104983674
          ],
          [
            104.359130859375,
            30.182528288276167
          ],
          [
            104.35157775878906,
            30.18905718468536
          ],
          [
            104.32960510253906,
            30.197959527771424
          ],
          [
            104.32273864746094,
            30.203893976001527
          ],
          [
            104.30763244628906,
            30.208047876887466
          ],
          [
            104.29389953613281,
            30.21576179908697
          ],
          [
            104.28085327148438,
            30.217541849095714
          ],
          [
            104.27810668945312,
            30.21902519949919
          ],
          [
            104.27656173706055,
            30.22036019573785
          ],
          [
            104.27398681640625,
            30.21976686631304
          ],
          [
            104.26986694335938,
            30.21976686631304
          ],
          [
            104.26832199096678,
            30.22095352158375
          ],
          [
            104.26797866821288,
            30.223326789176987
          ],
          [
            104.26763534545897,
            30.225848323247707
          ],
          [
            104.26849365234375,
            30.229259707613494
          ],
          [
            104.26832199096678,
            30.231632774762375
          ],
          [
            104.26815032958984,
            30.234154095850688
          ],
          [
            104.26712036132812,
            30.23712027316867
          ],
          [
            104.26660537719727,
            30.238751632543142
          ],
          [
            104.26351547241211,
            30.239789756229758
          ],
          [
            104.25956726074219,
            30.239789756229758
          ],
          [
            104.25596237182617,
            30.24067956780551
          ],
          [
            104.2547607421875,
            30.24067956780551
          ],
          [
            104.25184249877928,
            30.241124470572473
          ],
          [
            104.25046920776367,
            30.24156937132543
          ],
          [
            104.24737930297852,
            30.243348954197124
          ],
          [
            104.24480438232422,
            30.244535324875464
          ],
          [
            104.24154281616211,
            30.244535324875464
          ],
          [
            104.23604965209961,
            30.244535324875464
          ],
          [
            104.2334747314453,
            30.24586997476859
          ],
          [
            104.23107147216797,
            30.24779777038891
          ],
          [
            104.23038482666016,
            30.25002210295159
          ],
          [
            104.23089981079102,
            30.25298780136615
          ],
          [
            104.23158645629883,
            30.254322336443444
          ],
          [
            104.23381805419922,
            30.25743618111515
          ],
          [
            104.23604965209961,
            30.260105112263386
          ],
          [
            104.23999786376953,
            30.26292223867753
          ],
          [
            104.24703598022461,
            30.264849699658892
          ],
          [
            104.24772262573242,
            30.266184073558826
          ],
          [
            104.24720764160156,
            30.27018708644781
          ],
          [
            104.24531936645508,
            30.273300428069934
          ],
          [
            104.24308776855469,
            30.27522768530293
          ],
          [
            104.23845291137695,
            30.276265423522855
          ],
          [
            104.23553466796875,
            30.28056450782511
          ],
          [
            104.23313140869139,
            30.28041626667403
          ],
          [
            104.22935485839844,
            30.280119783700034
          ],
          [
            104.22609329223633,
            30.280860989455412
          ],
          [
            104.22489166259764,
            30.284863403785405
          ],
          [
            104.22523498535155,
            30.287679819699456
          ],
          [
            104.22918319702147,
            30.29790717639933
          ],
          [
            104.22883987426756,
            30.30131605828983
          ],
          [
            104.22832489013672,
            30.305317637976145
          ],
          [
            104.2276382446289,
            30.308726262208744
          ],
          [
            104.22592163085938,
            30.313468498736643
          ],
          [
            104.22540664672852,
            30.316580466750533
          ],
          [
            104.22231674194336,
            30.31880324053713
          ],
          [
            104.21957015991211,
            30.31895142366329
          ],
          [
            104.21459197998047,
            30.319247789243317
          ],
          [
            104.21236038208008,
            30.319544153926838
          ],
          [
            104.21133041381836,
            30.32102596389705
          ],
          [
            104.20755386352539,
            30.322804106276315
          ],
          [
            104.205322265625,
            30.322063217540613
          ],
          [
            104.20377731323242,
            30.321174143661388
          ],
          [
            104.20120239257811,
            30.32102596389705
          ],
          [
            104.19965744018555,
            30.320433242598465
          ],
          [
            104.19656753540039,
            30.320136880604423
          ],
          [
            104.19536590576172,
            30.3217668604774
          ],
          [
            104.19210433959961,
            30.3217668604774
          ],
          [
            104.1891860961914,
            30.32147050251765
          ],
          [
            104.18781280517578,
            30.32265592897744
          ],
          [
            104.18575286865233,
            30.324137691879947
          ],
          [
            104.18554097414017,
            30.32420946420142
          ]
          ]  
        
      ]

var tfxq =  [
  [           
    [
      104.12670135498047,
      30.547626292189097
    ],
    [
      104.126819,
      30.547794
    ],
    [
      104.128373,
      30.5498
    ],
    [
      104.128532,
      30.551369
    ],
    [
      104.126541,
      30.553191
    ],
    [
      104.125865,
      30.55369
    ],
    [
      104.125023,
      30.554105
    ],
    [
      104.123592,
      30.554521
    ],
    [
      104.123176,
      30.554732
    ],
    [
      104.122933,
      30.555043
    ],
    [
      104.122881,
      30.555346
    ],
    [
      104.122933,
      30.5559
    ],
    [
      104.123124,
      30.556432
    ],
    [
      104.125466,
      30.561607
    ],
    [
      104.125813,
      30.562119
    ],
    [
      104.126403,
      30.562642
    ],
    [
      104.127019,
      30.563049
    ],
    [
      104.127842,
      30.563421
    ],
    [
      104.128753,
      30.563627
    ],
    [
      104.130149,
      30.563816
    ],
    [
      104.13079,
      30.563974
    ],
    [
      104.13842,
      30.566969
    ],
    [
      104.139434,
      30.567258
    ],
    [
      104.140553,
      30.567367
    ],
    [
      104.141411,
      30.567243
    ],
    [
      104.142512,
      30.56688
    ],
    [
      104.143231,
      30.566452
    ],
    [
      104.143916,
      30.565815
    ],
    [
      104.144592,
      30.564861
    ],
    [
      104.146282,
      30.561895
    ],
    [
      104.146802,
      30.561299
    ],
    [
      104.147504,
      30.560911
    ],
    [
      104.148171,
      30.560828
    ],
    [
      104.148812,
      30.560992
    ],
    [
      104.149324,
      30.561392
    ],
    [
      104.151118,
      30.563157
    ],
    [
      104.151447,
      30.563365
    ],
    [
      104.151932,
      30.563377
    ],
    [
      104.152452,
      30.563183
    ],
    [
      104.153024,
      30.562823
    ],
    [
      104.153683,
      30.562186
    ],
    [
      104.154099,
      30.561695
    ],
    [
      104.154567,
      30.561149
    ],
    [
      104.155087,
      30.560291
    ],
    [
      104.155355,
      30.559626
    ],
    [
      104.155381,
      30.558729
    ],
    [
      104.155242,
      30.557913
    ],
    [
      104.154982,
      30.557017
    ],
    [
      104.153795,
      30.554
    ],
    [
      104.15357,
      30.553088
    ],
    [
      104.153518,
      30.551983
    ],
    [
      104.153665,
      30.551015
    ],
    [
      104.154107,
      30.549963
    ],
    [
      104.154852,
      30.548981
    ],
    [
      104.156039,
      30.547943
    ],
    [
      104.162399,
      30.543898
    ],
    [
      104.163742,
      30.543227
    ],
    [
      104.165094,
      30.54288
    ],
    [
      104.168195,
      30.542709
    ],
    [
      104.169486,
      30.542457
    ],
    [
      104.171046,
      30.541931
    ],
    [
      104.171921,
      30.541545
    ],
    [
      104.172666,
      30.541072
    ],
    [
      104.173342,
      30.540491
    ],
    [
      104.173905,
      30.539806
    ],
    [
      104.174502,
      30.538922
    ],
    [
      104.175005,
      30.537894
    ],
    [
      104.17536,
      30.537029
    ],
    [
      104.176088,
      30.534753
    ],
    [
      104.176521,
      30.533923
    ],
    [
      104.177067,
      30.533273
    ],
    [
      104.177587,
      30.532905
    ],
    [
      104.178029,
      30.532821
    ],
    [
      104.178479,
      30.532868
    ],
    [
      104.178886,
      30.533041
    ],
    [
      104.179649,
      30.53363
    ],
    [
      104.179961,
      30.53376
    ],
    [
      104.180307,
      30.533794
    ],
    [
      104.180628,
      30.533739
    ],
    [
      104.181018,
      30.533545
    ],
    [
      104.181685,
      30.533082
    ],
    [
      104.182265,
      30.532562
    ],
    [
      104.183149,
      30.531627
    ],
    [
      104.183712,
      30.530905
    ],
    [
      104.184518,
      30.529618
    ],
    [
      104.185194,
      30.528325
    ],
    [
      104.185887,
      30.526666
    ],
    [
      104.186303,
      30.525021
    ],
    [
      104.186909,
      30.522444
    ],
    [
      104.186973,
      30.522122
    ],
    [
      104.186975,
      30.522107
    ],
    [
      104.18698,
      30.522091
    ],
    [
      104.187472,
      30.519638
    ],
    [
      104.187732,
      30.518862
    ],
    [
      104.188105,
      30.518184
    ],
    [
      104.190461,
      30.514803
    ],
    [
      104.193893,
      30.509428
    ],
    [
      104.195574,
      30.506262
    ],
    [
      104.19608,
      30.50506
    ],
    [
      104.196073,
      30.505034
    ],
    [
      104.196228,
      30.503696
    ],
    [
      104.196436,
      30.503354
    ],
    [
      104.196535,
      30.503308
    ],
    [
      104.196652,
      30.503317
    ],
    [
      104.196902,
      30.50352
    ],
    [
      104.197045,
      30.503591
    ],
    [
      104.197224,
      30.503591
    ],
    [
      104.19735,
      30.503522
    ],
    [
      104.197423,
      30.503414
    ],
    [
      104.197399,
      30.503017
    ],
    [
      104.197409,
      30.502877
    ],
    [
      104.197553,
      30.502815
    ],
    [
      104.197956,
      30.502848
    ],
    [
      104.198465,
      30.503122
    ],
    [
      104.198618,
      30.503116
    ],
    [
      104.198717,
      30.503023
    ],
    [
      104.198872,
      30.502626
    ],
    [
      104.198918,
      30.502518
    ],
    [
      104.199409,
      30.50168
    ],
    [
      104.199563,
      30.501556
    ],
    [
      104.199905,
      30.501441
    ],
    [
      104.199951,
      30.501363
    ],
    [
      104.199951,
      30.501262
    ],
    [
      104.19988,
      30.501192
    ],
    [
      104.199325,
      30.501158
    ],
    [
      104.199191,
      30.50104
    ],
    [
      104.199174,
      30.500916
    ],
    [
      104.19922,
      30.500737
    ],
    [
      104.200066,
      30.500267
    ],
    [
      104.200194,
      30.500081
    ],
    [
      104.20015,
      30.498843
    ],
    [
      104.200215,
      30.498626
    ],
    [
      104.200315,
      30.498486
    ],
    [
      104.200809,
      30.498372
    ],
    [
      104.200854,
      30.498294
    ],
    [
      104.200783,
      30.498162
    ],
    [
      104.199942,
      30.497893
    ],
    [
      104.199737,
      30.497783
    ],
    [
      104.199703,
      30.497651
    ],
    [
      104.199801,
      30.497527
    ],
    [
      104.200248,
      30.497411
    ],
    [
      104.20062,
      30.497313
    ],
    [
      104.200843,
      30.497049
    ],
    [
      104.200944,
      30.496645
    ],
    [
      104.200859,
      30.496428
    ],
    [
      104.200691,
      30.495789
    ],
    [
      104.200682,
      30.495498
    ],
    [
      104.200708,
      30.494972
    ],
    [
      104.200929,
      30.494438
    ],
    [
      104.201377,
      30.493915
    ],
    [
      104.201619,
      30.493493
    ],
    [
      104.20174,
      30.493109
    ],
    [
      104.202053,
      30.492251
    ],
    [
      104.201885,
      30.492104
    ],
    [
      104.201849,
      30.491917
    ],
    [
      104.201964,
      30.491682
    ],
    [
      104.201967,
      30.491341
    ],
    [
      104.201772,
      30.491015
    ],
    [
      104.200489,
      30.489961
    ],
    [
      104.200387,
      30.489806
    ],
    [
      104.200361,
      30.489668
    ],
    [
      104.200408,
      30.489508
    ],
    [
      104.200633,
      30.489434
    ],
    [
      104.200763,
      30.4893
    ],
    [
      104.201183,
      30.488518
    ],
    [
      104.201408,
      30.488347
    ],
    [
      104.201901,
      30.488132
    ],
    [
      104.202539,
      30.488045
    ],
    [
      104.202754,
      30.488091
    ],
    [
      104.203011,
      30.488245
    ],
    [
      104.203155,
      30.488455
    ],
    [
      104.203282,
      30.488822
    ],
    [
      104.203392,
      30.488905
    ],
    [
      104.203531,
      30.488921
    ],
    [
      104.204023,
      30.488803
    ],
    [
      104.204118,
      30.488736
    ],
    [
      104.204162,
      30.488617
    ],
    [
      104.204147,
      30.4884
    ],
    [
      104.203782,
      30.487681
    ],
    [
      104.203594,
      30.487471
    ],
    [
      104.203295,
      30.487261
    ],
    [
      104.203098,
      30.48708
    ],
    [
      104.202995,
      30.48693
    ],
    [
      104.202778,
      30.486152
    ],
    [
      104.202787,
      30.486076
    ],
    [
      104.202822,
      30.486001
    ],
    [
      104.202926,
      30.48592
    ],
    [
      104.203251,
      30.485798
    ],
    [
      104.203306,
      30.485753
    ],
    [
      104.203328,
      30.485686
    ],
    [
      104.20333,
      30.485589
    ],
    [
      104.203271,
      30.485401
    ],
    [
      104.203271,
      30.48526
    ],
    [
      104.203325,
      30.48517
    ],
    [
      104.203724,
      30.484757
    ],
    [
      104.20414,
      30.484453
    ],
    [
      104.204364,
      30.484416
    ],
    [
      104.204597,
      30.484433
    ],
    [
      104.204828,
      30.484523
    ],
    [
      104.204904,
      30.48468
    ],
    [
      104.204894,
      30.484874
    ],
    [
      104.204944,
      30.485062
    ],
    [
      104.205055,
      30.485212
    ],
    [
      104.20539,
      30.485393
    ],
    [
      104.20575,
      30.485551
    ],
    [
      104.205922,
      30.485567
    ],
    [
      104.206121,
      30.485523
    ],
    [
      104.206276,
      30.485435
    ],
    [
      104.206695,
      30.484764
    ],
    [
      104.206851,
      30.484543
    ],
    [
      104.206998,
      30.484492
    ],
    [
      104.207145,
      30.484515
    ],
    [
      104.207299,
      30.484643
    ],
    [
      104.207522,
      30.484794
    ],
    [
      104.207702,
      30.484832
    ],
    [
      104.20784,
      30.484825
    ],
    [
      104.207953,
      30.484766
    ],
    [
      104.20798,
      30.484594
    ],
    [
      104.207808,
      30.48342
    ],
    [
      104.207749,
      30.48333
    ],
    [
      104.20762,
      30.483285
    ],
    [
      104.2075,
      30.483321
    ],
    [
      104.207247,
      30.483575
    ],
    [
      104.207066,
      30.483633
    ],
    [
      104.206954,
      30.483618
    ],
    [
      104.206868,
      30.48361
    ],
    [
      104.206731,
      30.483505
    ],
    [
      104.206698,
      30.48337
    ],
    [
      104.206771,
      30.482922
    ],
    [
      104.206755,
      30.482765
    ],
    [
      104.206678,
      30.482705
    ],
    [
      104.206548,
      30.48269
    ],
    [
      104.206323,
      30.482778
    ],
    [
      104.20622,
      30.482778
    ],
    [
      104.206177,
      30.482748
    ],
    [
      104.206109,
      30.48265
    ],
    [
      104.20612,
      30.482411
    ],
    [
      104.206086,
      30.482351
    ],
    [
      104.206,
      30.482321
    ],
    [
      104.205845,
      30.48235
    ],
    [
      104.205326,
      30.482631
    ],
    [
      104.205179,
      30.482646
    ],
    [
      104.205067,
      30.482623
    ],
    [
      104.20499,
      30.482548
    ],
    [
      104.204957,
      30.482406
    ],
    [
      104.204992,
      30.482302
    ],
    [
      104.205105,
      30.482204
    ],
    [
      104.205442,
      30.482102
    ],
    [
      104.20558,
      30.482005
    ],
    [
      104.205668,
      30.481864
    ],
    [
      104.205743,
      30.481109
    ],
    [
      104.205839,
      30.480975
    ],
    [
      104.205977,
      30.480939
    ],
    [
      104.206131,
      30.480955
    ],
    [
      104.206259,
      30.481096
    ],
    [
      104.206318,
      30.481344
    ],
    [
      104.20636,
      30.481485
    ],
    [
      104.206462,
      30.481583
    ],
    [
      104.206609,
      30.481599
    ],
    [
      104.206781,
      30.48154
    ],
    [
      104.206937,
      30.481398
    ],
    [
      104.207052,
      30.48107
    ],
    [
      104.207098,
      30.480734
    ],
    [
      104.207168,
      30.480608
    ],
    [
      104.207298,
      30.480526
    ],
    [
      104.208031,
      30.480433
    ],
    [
      104.208159,
      30.480455
    ],
    [
      104.208271,
      30.480523
    ],
    [
      104.208415,
      30.480785
    ],
    [
      104.208359,
      30.481354
    ],
    [
      104.208408,
      30.481653
    ],
    [
      104.208502,
      30.481802
    ],
    [
      104.208579,
      30.481854
    ],
    [
      104.208777,
      30.481871
    ],
    [
      104.209131,
      30.481782
    ],
    [
      104.209252,
      30.481738
    ],
    [
      104.209321,
      30.481656
    ],
    [
      104.209331,
      30.481537
    ],
    [
      104.209117,
      30.481312
    ],
    [
      104.209084,
      30.481178
    ],
    [
      104.209128,
      30.481065
    ],
    [
      104.209318,
      30.480984
    ],
    [
      104.209972,
      30.481047
    ],
    [
      104.210119,
      30.481033
    ],
    [
      104.210257,
      30.480981
    ],
    [
      104.210362,
      30.480831
    ],
    [
      104.2105,
      30.480653
    ],
    [
      104.210726,
      30.48052
    ],
    [
      104.2112,
      30.48044
    ],
    [
      104.211589,
      30.480345
    ],
    [
      104.211693,
      30.480278
    ],
    [
      104.211745,
      30.48018
    ],
    [
      104.211729,
      30.480091
    ],
    [
      104.211626,
      30.48003
    ],
    [
      104.21154,
      30.479993
    ],
    [
      104.21148,
      30.479903
    ],
    [
      104.211464,
      30.479769
    ],
    [
      104.211551,
      30.479649
    ],
    [
      104.211759,
      30.479516
    ],
    [
      104.212486,
      30.479093
    ],
    [
      104.212693,
      30.479042
    ],
    [
      104.212865,
      30.47908
    ],
    [
      104.213019,
      30.479223
    ],
    [
      104.213068,
      30.4795
    ],
    [
      104.212912,
      30.479655
    ],
    [
      104.21261,
      30.479796
    ],
    [
      104.212428,
      30.47984
    ],
    [
      104.212359,
      30.479922
    ],
    [
      104.212375,
      30.480034
    ],
    [
      104.212521,
      30.480132
    ],
    [
      104.213647,
      30.480392
    ],
    [
      104.214069,
      30.480453
    ],
    [
      104.214302,
      30.480439
    ],
    [
      104.21443,
      30.480403
    ],
    [
      104.214517,
      30.480291
    ],
    [
      104.214527,
      30.480171
    ],
    [
      104.214426,
      30.479827
    ],
    [
      104.214182,
      30.479251
    ],
    [
      104.214175,
      30.479049
    ],
    [
      104.214236,
      30.478899
    ],
    [
      104.214437,
      30.478445
    ],
    [
      104.214602,
      30.478386
    ],
    [
      104.214808,
      30.478425
    ],
    [
      104.214945,
      30.478597
    ],
    [
      104.214918,
      30.478791
    ],
    [
      104.214813,
      30.478955
    ],
    [
      104.214846,
      30.47909
    ],
    [
      104.214931,
      30.479225
    ],
    [
      104.215111,
      30.4793
    ],
    [
      104.215241,
      30.479294
    ],
    [
      104.215336,
      30.479234
    ],
    [
      104.215423,
      30.47907
    ],
    [
      104.2156,
      30.47854
    ],
    [
      104.215739,
      30.478377
    ],
    [
      104.216206,
      30.47811
    ],
    [
      104.216475,
      30.477932
    ],
    [
      104.216579,
      30.477805
    ],
    [
      104.216702,
      30.477596
    ],
    [
      104.216927,
      30.477419
    ],
    [
      104.217186,
      30.477359
    ],
    [
      104.217634,
      30.477377
    ],
    [
      104.218029,
      30.477453
    ],
    [
      104.21858,
      30.477643
    ],
    [
      104.218812,
      30.477652
    ],
    [
      104.218958,
      30.477622
    ],
    [
      104.219149,
      30.477466
    ],
    [
      104.219212,
      30.477228
    ],
    [
      104.219145,
      30.476884
    ],
    [
      104.219112,
      30.476719
    ],
    [
      104.219113,
      30.476562
    ],
    [
      104.219166,
      30.476436
    ],
    [
      104.219279,
      30.476369
    ],
    [
      104.219495,
      30.476325
    ],
    [
      104.219752,
      30.476326
    ],
    [
      104.219969,
      30.476223
    ],
    [
      104.220082,
      30.476089
    ],
    [
      104.220188,
      30.475798
    ],
    [
      104.220242,
      30.47553
    ],
    [
      104.220346,
      30.47541
    ],
    [
      104.220485,
      30.475343
    ],
    [
      104.220675,
      30.475345
    ],
    [
      104.220854,
      30.475435
    ],
    [
      104.221317,
      30.475751
    ],
    [
      104.221489,
      30.475782
    ],
    [
      104.221584,
      30.475752
    ],
    [
      104.221663,
      30.475678
    ],
    [
      104.221689,
      30.475566
    ],
    [
      104.221647,
      30.475439
    ],
    [
      104.221392,
      30.474997
    ],
    [
      104.221393,
      30.474847
    ],
    [
      104.221463,
      30.474758
    ],
    [
      104.221575,
      30.474721
    ],
    [
      104.221799,
      30.474775
    ],
    [
      104.222691,
      30.475347
    ],
    [
      104.222854,
      30.475347
    ],
    [
      104.223018,
      30.475296
    ],
    [
      104.223097,
      30.475177
    ],
    [
      104.223098,
      30.475012
    ],
    [
      104.222987,
      30.474788
    ],
    [
      104.222775,
      30.474481
    ],
    [
      104.222776,
      30.474368
    ],
    [
      104.222829,
      30.474256
    ],
    [
      104.223088,
      30.474079
    ],
    [
      104.223167,
      30.473907
    ],
    [
      104.223168,
      30.47375
    ],
    [
      104.22305,
      30.473466
    ],
    [
      104.222675,
      30.472956
    ],
    [
      104.222404,
      30.472791
    ],
    [
      104.222845,
      30.470923
    ],
    [
      104.223166,
      30.47033
    ],
    [
      104.223687,
      30.469817
    ],
    [
      104.224502,
      30.469265
    ],
    [
      104.225811,
      30.468723
    ],
    [
      104.227633,
      30.46817
    ],
    [
      104.231693,
      30.467524
    ],
    [
      104.234166,
      30.467004
    ],
    [
      104.236614,
      30.466354
    ],
    [
      104.23663,
      30.466347
    ],
    [
      104.236647,
      30.466345
    ],
    [
      104.238236,
      30.465924
    ],
    [
      104.240501,
      30.465433
    ],
    [
      104.247845,
      30.463976
    ],
    [
      104.246707,
      30.462752
    ],
    [
      104.246134,
      30.461654
    ],
    [
      104.245813,
      30.46035
    ],
    [
      104.246343,
      30.459086
    ],
    [
      104.247402,
      30.457985
    ],
    [
      104.248582,
      30.457414
    ],
    [
      104.250371,
      30.456965
    ],
    [
      104.253758,
      30.457088
    ],
    [
      104.258491,
      30.458678
    ],
    [
      104.260359,
      30.459004
    ],
    [
      104.265094,
      30.458029
    ],
    [
      104.268222,
      30.45766
    ],
    [
      104.269099,
      30.457439
    ],
    [
      104.269491,
      30.456729
    ],
    [
      104.269273,
      30.455822
    ],
    [
      104.268439,
      30.454154
    ],
    [
      104.265449,
      30.449078
    ],
    [
      104.263607,
      30.446357
    ],
    [
      104.260462,
      30.442727
    ],
    [
      104.257865,
      30.437996
    ],
    [
      104.248841,
      30.428582
    ],
    [
      104.248234,
      30.4276
    ],
    [
      104.247912,
      30.426548
    ],
    [
      104.247938,
      30.426007
    ],
    [
      104.249188,
      30.423728
    ],
    [
      104.249822,
      30.422061
    ],
    [
      104.249796,
      30.420834
    ],
    [
      104.249605,
      30.420615
    ],
    [
      104.248937,
      30.420296
    ],
    [
      104.247886,
      30.419954
    ],
    [
      104.245464,
      30.419611
    ],
    [
      104.241879,
      30.419807
    ],
    [
      104.239752,
      30.419858
    ],
    [
      104.238451,
      30.419785
    ],
    [
      104.236784,
      30.419467
    ],
    [
      104.23465,
      30.418757
    ],
    [
      104.233504,
      30.418024
    ],
    [
      104.231717,
      30.415303
    ],
    [
      104.22914,
      30.412633
    ],
    [
      104.228455,
      30.411212
    ],
    [
      104.228333,
      30.410036
    ],
    [
      104.228725,
      30.408174
    ],
    [
      104.22875,
      30.40278
    ],
    [
      104.228282,
      30.398539
    ],
    [
      104.227865,
      30.397217
    ],
    [
      104.22711,
      30.396163
    ],
    [
      104.226252,
      30.39565
    ],
    [
      104.224786,
      30.395087
    ],
    [
      104.221013,
      30.394307
    ],
    [
      104.219521,
      30.393818
    ],
    [
      104.218637,
      30.39306
    ],
    [
      104.218099,
      30.391908
    ],
    [
      104.218003,
      30.390584
    ],
    [
      104.218316,
      30.389602
    ],
    [
      104.218983,
      30.388377
    ],
    [
      104.219226,
      30.386096
    ],
    [
      104.219252,
      30.383793
    ],
    [
      104.218758,
      30.380141
    ],
    [
      104.218757,
      30.377714
    ],
    [
      104.218567,
      30.376366
    ],
    [
      104.218194,
      30.375312
    ],
    [
      104.216676,
      30.373599
    ],
    [
      104.215133,
      30.372327
    ],
    [
      104.210433,
      30.367625
    ],
    [
      104.207061,
      30.36241
    ],
    [
      104.201305,
      30.356978
    ],
    [
      104.197786,
      30.353502
    ],
    [
      104.196582,
      30.351299
    ],
    [
      104.196339,
      30.34968
    ],
    [
      104.196313,
      30.348873
    ],
    [
      104.196928,
      30.34615
    ],
    [
      104.198297,
      30.342864
    ],
    [
      104.198445,
      30.340582
    ],
    [
      104.19834,
      30.339749
    ],
    [
      104.197734,
      30.338745
    ],
    [
      104.196044,
      30.337081
    ],
    [
      104.191676,
      30.329992
    ],
    [
      104.189787,
      30.329873
    ],
    [
      104.188712,
      30.32955
    ],
    [
      104.188056,
      30.329094
    ],
    [
      104.187854,
      30.328828
    ],
    [
      104.187793,
      30.32849
    ],
    [
      104.18678,
      30.32634
    ],
    [
      104.186072,
      30.325264
    ],
    [
      104.185181,
      30.323566
    ],
    [
      104.182553,
      30.320731
    ],
    [
      104.181396,
      30.319821
    ],
    [
      104.181465,
      30.319822
    ],
    [
      104.180231,
      30.31925
    ],
    [
      104.178555,
      30.318226
    ],
    [
      104.178182,
      30.317037
    ],
    [
      104.178258,
      30.315685
    ],
    [
      104.179324,
      30.313209
    ],
    [
      104.178578,
      30.31044
    ],
    [
      104.177062,
      30.306088
    ],
    [
      104.175745,
      30.301905
    ],
    [
      104.175805,
      30.301962
    ],
    [
      104.174793,
      30.299925
    ],
    [
      104.174031,
      30.298625
    ],
    [
      104.173064,
      30.297773
    ],
    [
      104.173064,
      30.297829
    ],
    [
      104.171259,
      30.296407
    ],
    [
      104.171259,
      30.296294
    ],
    [
      104.16985,
      30.295102
    ],
    [
      104.168891,
      30.293742
    ],
    [
      104.168708,
      30.29267
    ],
    [
      104.168769,
      30.29267
    ],
    [
      104.168853,
      30.291317
    ],
    [
      104.168799,
      30.289849
    ],
    [
      104.168494,
      30.288213
    ],
    [
      104.167299,
      30.284932
    ],
    [
      104.166529,
      30.283461
    ],
    [
      104.166598,
      30.283518
    ],
    [
      104.165578,
      30.282047
    ],
    [
      104.164359,
      30.280627
    ],
    [
      104.163133,
      30.280002
    ],
    [
      104.161717,
      30.278919
    ],
    [
      104.159851,
      30.277781
    ],
    [
      104.158563,
      30.276363
    ],
    [
      104.157802,
      30.274779
    ],
    [
      104.15618,
      30.268903
    ],
    [
      104.155281,
      30.267994
    ],
    [
      104.15522,
      30.26805
    ],
    [
      104.153415,
      30.266684
    ],
    [
      104.152265,
      30.265323
    ],
    [
      104.152326,
      30.265267
    ],
    [
      104.151823,
      30.264024
    ],
    [
      104.15164,
      30.262612
    ],
    [
      104.151274,
      30.261088
    ],
    [
      104.151152,
      30.259732
    ],
    [
      104.151297,
      30.258379
    ],
    [
      104.151129,
      30.255785
    ],
    [
      104.151068,
      30.255951
    ],
    [
      104.151015,
      30.254598
    ],
    [
      104.15052,
      30.252733
    ],
    [
      104.150467,
      30.251436
    ],
    [
      104.150398,
      30.251436
    ],
    [
      104.150581,
      30.247093
    ],
    [
      104.150527,
      30.245627
    ],
    [
      104.150215,
      30.24478
    ],
    [
      104.150146,
      30.244779
    ],
    [
      104.149514,
      30.243759
    ],
    [
      104.148235,
      30.242341
    ],
    [
      104.146947,
      30.241318
    ],
    [
      104.147008,
      30.241318
    ],
    [
      104.145073,
      30.24012
    ],
    [
      104.142566,
      30.238186
    ],
    [
      104.140967,
      30.236597
    ],
    [
      104.140898,
      30.236709
    ],
    [
      104.139352,
      30.235515
    ],
    [
      104.137874,
      30.234601
    ],
    [
      104.13561,
      30.233682
    ],
    [
      104.130954,
      30.232633
    ],
    [
      104.129986,
      30.232008
    ],
    [
      104.129216,
      30.231268
    ],
    [
      104.12805,
      30.230527
    ],
    [
      104.12805,
      30.230583
    ],
    [
      104.126777,
      30.228656
    ],
    [
      104.126777,
      30.228713
    ],
    [
      104.125817,
      30.227747
    ],
    [
      104.120694,
      30.227147
    ],
    [
      104.120694,
      30.227203
    ],
    [
      104.11862,
      30.227073
    ],
    [
      104.116599,
      30.227171
    ],
    [
      104.116607,
      30.227115
    ],
    [
      104.114906,
      30.227722
    ],
    [
      104.114845,
      30.227666
    ],
    [
      104.113076,
      30.228893
    ],
    [
      104.112084,
      30.230578
    ],
    [
      104.111612,
      30.232492
    ],
    [
      104.111673,
      30.232549
    ],
    [
      104.11133,
      30.233956
    ],
    [
      104.111398,
      30.234013
    ],
    [
      104.110597,
      30.235586
    ],
    [
      104.109423,
      30.236535
    ],
    [
      104.109293,
      30.236478
    ],
    [
      104.108118,
      30.236975
    ],
    [
      104.106417,
      30.238035
    ],
    [
      104.098405,
      30.239435
    ],
    [
      104.096573,
      30.240378
    ],
    [
      104.096512,
      30.240491
    ],
    [
      104.095199,
      30.241325
    ],
    [
      104.094009,
      30.243064
    ],
    [
      104.092879,
      30.245365
    ],
    [
      104.092406,
      30.246997
    ],
    [
      104.091619,
      30.248757
    ],
    [
      104.090619,
      30.249464
    ],
    [
      104.088466,
      30.250348
    ],
    [
      104.086908,
      30.250052
    ],
    [
      104.086977,
      30.250052
    ],
    [
      104.084839,
      30.249242
    ],
    [
      104.082371,
      30.248598
    ],
    [
      104.081592,
      30.248705
    ],
    [
      104.080867,
      30.249204
    ],
    [
      104.080874,
      30.249092
    ],
    [
      104.07995,
      30.249761
    ],
    [
      104.080018,
      30.249818
    ],
    [
      104.079423,
      30.250546
    ],
    [
      104.079415,
      30.251448
    ],
    [
      104.079347,
      30.25156
    ],
    [
      104.079919,
      30.25241
    ],
    [
      104.080362,
      30.253713
    ],
    [
      104.080423,
      30.253713
    ],
    [
      104.080546,
      30.254616
    ],
    [
      104.07982,
      30.255513
    ],
    [
      104.07982,
      30.255457
    ],
    [
      104.077727,
      30.256346
    ],
    [
      104.076558,
      30.25644
    ],
    [
      104.074686,
      30.255295
    ],
    [
      104.072936,
      30.254374
    ],
    [
      104.070919,
      30.254355
    ],
    [
      104.070919,
      30.254299
    ],
    [
      104.069222,
      30.255071
    ],
    [
      104.069282,
      30.255184
    ],
    [
      104.068167,
      30.256077
    ],
    [
      104.067433,
      30.257365
    ],
    [
      104.066378,
      30.258822
    ],
    [
      104.066439,
      30.258822
    ],
    [
      104.065446,
      30.260674
    ],
    [
      104.065446,
      30.260618
    ],
    [
      104.0641,
      30.264102
    ],
    [
      104.0641,
      30.264045
    ],
    [
      104.062839,
      30.265836
    ],
    [
      104.062059,
      30.266055
    ],
    [
      104.062059,
      30.265942
    ],
    [
      104.061149,
      30.265932
    ],
    [
      104.060048,
      30.265074
    ],
    [
      104.060117,
      30.264962
    ],
    [
      104.058511,
      30.263366
    ],
    [
      104.058442,
      30.263422
    ],
    [
      104.057348,
      30.262621
    ],
    [
      104.055589,
      30.262546
    ],
    [
      104.055589,
      30.262321
    ],
    [
      104.05422,
      30.262475
    ],
    [
      104.05422,
      30.262419
    ],
    [
      104.052974,
      30.262968
    ],
    [
      104.052247,
      30.263864
    ],
    [
      104.052247,
      30.263808
    ],
    [
      104.051711,
      30.26493
    ],
    [
      104.051566,
      30.266507
    ],
    [
      104.051627,
      30.266564
    ],
    [
      104.051222,
      30.268139
    ],
    [
      104.050488,
      30.269711
    ],
    [
      104.049501,
      30.270491
    ],
    [
      104.047351,
      30.27041
    ],
    [
      104.047412,
      30.270354
    ],
    [
      104.041314,
      30.267974
    ],
    [
      104.041383,
      30.268144
    ],
    [
      104.035995,
      30.26577
    ],
    [
      104.032298,
      30.264599
    ],
    [
      104.032298,
      30.264486
    ],
    [
      104.030928,
      30.264414
    ],
    [
      104.030331,
      30.265254
    ],
    [
      104.029994,
      30.266265
    ],
    [
      104.029995,
      30.266322
    ],
    [
      104.030063,
      30.266266
    ],
    [
      104.029635,
      30.26908
    ],
    [
      104.02929,
      30.270658
    ],
    [
      104.028563,
      30.271494
    ],
    [
      104.027315,
      30.271989
    ],
    [
      104.02609,
      30.271241
    ],
    [
      104.022989,
      30.268834
    ],
    [
      104.022981,
      30.268947
    ],
    [
      104.021167,
      30.268024
    ],
    [
      104.019728,
      30.268401
    ],
    [
      104.017638,
      30.269167
    ],
    [
      104.016007,
      30.268809
    ],
    [
      104.016076,
      30.268641
    ],
    [
      104.014452,
      30.268112
    ],
    [
      104.014521,
      30.267944
    ],
    [
      104.012967,
      30.266966
    ],
    [
      104.012967,
      30.266854
    ],
    [
      104.009406,
      30.264667
    ],
    [
      104.009475,
      30.26467
    ],
    [
      104.007461,
      30.263855
    ],
    [
      104.007392,
      30.26391
    ],
    [
      104.003617,
      30.263582
    ],
    [
      104.003617,
      30.263525
    ],
    [
      104.000026,
      30.26399
    ],
    [
      103.999957,
      30.263933
    ],
    [
      103.998387,
      30.264534
    ],
    [
      103.998387,
      30.264478
    ],
    [
      103.99707,
      30.265309
    ],
    [
      103.995814,
      30.266647
    ],
    [
      103.995814,
      30.266759
    ],
    [
      103.99462,
      30.268494
    ],
    [
      103.993617,
      30.270286
    ],
    [
      103.992101,
      30.272074
    ],
    [
      103.990186,
      30.27363
    ],
    [
      103.987422,
      30.275402
    ],
    [
      103.986412,
      30.276293
    ],
    [
      103.986235,
      30.276514
    ],
    
    
    
    [
    103.98542404174805,
    30.27793319410253
  ],
  [
    103.99435043334961,
    30.282676919894065
  ],
  [
    104.00070190429688,
    30.29057026774473
  ],
  [
    104.00997161865234,
    30.30620685572504
  ],
  [
    104.01357650756836,
    30.321618681609593
  ],
  [
    104.00602340698242,
    30.331694334016003
  ],
  [
    103.99795532226562,
    30.350213049617338
  ],
  [
    103.99829864501953,
    30.355101405951
  ],
  [
    104.00259017944335,
    30.35569391739534
  ],
  [
    104.00894165039062,
    30.35465702001285
  ],
  [
    104.01237487792969,
    30.35806392728733
  ],
  [
    104.01185989379883,
    30.363544355240617
  ],
  [
    104.00533676147461,
    30.366654731797436
  ],
  [
    104.01323318481445,
    30.393014902255455
  ],
  [
    104.01203155517578,
    30.398197245338586
  ],
  [
    104.00413513183594,
    30.402342921826413
  ],
  [
    104.00001525878906,
    30.40811696364135
  ],
  [
    103.9995002746582,
    30.410041568363646
  ],
  [
    104.0049934387207,
    30.40752476993954
  ],
  [
    104.00859832763672,
    30.40796891555266
  ],
  [
    104.01151657104492,
    30.41063374679849
  ],
  [
    104.01134490966797,
    30.415223007862238
  ],
  [
    104.0049934387207,
    30.41773960780154
  ],
  [
    104.00190353393555,
    30.421440372174192
  ],
  [
    104.00121688842773,
    30.426473186476493
  ],
  [
    104.0024185180664,
    30.4297295750316
  ],
  [
    104.00688171386717,
    30.429433544200425
  ],
  [
    104.00876998901367,
    30.42839742921625
  ],
  [
    104.00997161865234,
    30.428989496269086
  ],
  [
    104.01031494140625,
    30.43209778933758
  ],
  [
    104.00739669799805,
    30.44334602199014
  ],
  [
    104.02524948120117,
    30.474271969858705
  ],
  [
    104.0305709838867,
    30.489065601311676
  ],
  [
    104.03228759765625,
    30.505779698694468
  ],
  [
    104.04335975646971,
    30.53298942749209
  ],
  [
    104.04383182525635,
    30.540234212012663
  ],
  [
    104.05426025390625,
    30.540345097372604
  ],
  [
    104.05481815338135,
    30.536537960900915
  ],
  [
    104.05893802642822,
    30.534652818668675
  ],
  [
    104.06661987304688,
    30.534911565847203
  ],
  [
    104.06816482543945,
    30.490544840792403
  ],
  [
    104.07983779907227,
    30.489065601311676
  ],
  [
    104.08035278320312,
    30.493651170505892
  ],
  [
    104.0951156616211,
    30.492467818499154
  ],
  [
    104.09460067749023,
    30.48551533481461
  ],
  [
    104.10524368286133,
    30.484183951487754
  ],
  [
    104.10661697387695,
    30.489509375517002
  ],
  [
    104.10490036010742,
    30.492319898486365
  ],
  [
    104.10472869873047,
    30.495278256014693
  ],
  [
    104.1177749633789,
    30.49557408682
  ],
  [
    104.11914825439453,
    30.504596494227247
  ],
  [
    104.117431640625,
    30.531067251102684
  ],
  [
    104.12137985229492,
    30.532102273884586
  ],
  [
    104.12635803222656,
    30.52973649132263
  ],
  [
    104.12713050842285,
    30.533950501424016
  ],
  [
    104.12670135498047,
    30.547626292189097
  ]
  ]
]

var gxq =  [
  [
  [
    104.08446997404099,
    30.622750993833787
  ],
    [
    104.08447265624999,
    30.62274868568607
  ],
  [
    104.0845799446106,
    30.62173309535159
  ],
  [
    104.08525049686432,
    30.620181991367453
  ],
  [
    104.08570647239685,
    30.6193325667489
  ],
  [
    104.08580839633942,
    30.618686260415828
  ],
  [
    104.08564746379852,
    30.618090731475018
  ],
  [
    104.08489108085632,
    30.617162806987256
  ],
  [
    104.08451557159424,
    30.61653956912966
  ],
  [
    104.08451020717621,
    30.615722428971367
  ],
  [
    104.08480525016785,
    30.615113031821593
  ],
  [
    104.08617854118347,
    30.61368647349551
  ],
  [
    104.08658623695374,
    30.612864692491822
  ],
  [
    104.08680617809296,
    30.6108702286938
  ],
  [
    104.08697247505187,
    30.610366989440056
  ],
  [
    104.0880560874939,
    30.608848022072188
  ],
  [
    104.08818483352661,
    30.60799388102065
  ],
  [
    104.08813655376434,
    30.605653034231945
  ],
  [
    104.08776640892029,
    30.604170930389397
  ],
  [
    104.08720850944519,
    30.603039714377935
  ],
  [
    104.0863448381424,
    30.60236559569758
  ],
  [
    104.08320128917694,
    30.60140981665141
  ],
  [
    104.08239662647247,
    30.60094808426553
  ],
  [
    104.08186554908752,
    30.600297037862322
  ],
  [
    104.0816992521286,
    30.599590578305158
  ],
  [
    104.08201038837433,
    30.596058203249115
  ],
  [
    104.08227324485779,
    30.594986921573657
  ],
  [
    104.08271849155426,
    30.594160364255764
  ],
  [
    104.08342123031616,
    30.59317679803765
  ],
  [
    104.08368408679962,
    30.592534934887944
  ],
  [
    104.08363580703735,
    30.591865360884793
  ],
  [
    104.08322811126709,
    30.59120963565332
  ],
  [
    104.08262729644775,
    30.590637026892917
  ],
  [
    104.08204793930054,
    30.59039689963174
  ],
  [
    104.08020257949828,
    30.590156771775625
  ],
  [
    104.07960176467896,
    30.589879700433112
  ],
  [
    104.0792155265808,
    30.58936249847452
  ],
  [
    104.07893657684326,
    30.588679048796095
  ],
  [
    104.0786361694336,
    30.587940178857682
  ],
  [
    104.07848596572876,
    30.587367550781412
  ],
  [
    104.0783143043518,
    30.586351589417728
  ],
  [
    104.0784215927124,
    30.585206311113062
  ],
  [
    104.07892048358917,
    30.583880911668857
  ],
  [
    104.07909750938416,
    30.583520695447827
  ],
  [
    104.0796822309494,
    30.58227840103355
  ],
  [
    104.07977342605591,
    30.582098290113183
  ],
  [
    104.07994508743286,
    30.580976052995087
  ],
  [
    104.07985389232635,
    30.579484334988948
  ],
  [
    104.07915115356445,
    30.578057252011334
  ],
  [
    104.07815873622894,
    30.577272116497923
  ],
  [
    104.07734870910645,
    30.57688878332005
  ],
  [
    104.0738832950592,
    30.573180072039243
  ],
  [
    104.07362043857574,
    30.572427227899553
  ],
  [
    104.07367944717407,
    30.571752896285464
  ],
  [
    104.07401740550993,
    30.570879953192005
  ],
  [
    104.07485961914062,
    30.570080903156
  ],
  [
    104.07585203647612,
    30.569734492135392
  ],
  [
    104.07756328582762,
    30.569614402692835
  ],
  [
    104.08073902130127,
    30.569965432953257
  ],
  [
    104.0815544128418,
    30.56983610616299
  ],
  [
    104.08226251602173,
    30.56931879727769
  ],
  [
    104.08269166946411,
    30.568561304289485
  ],
  [
    104.08286333084105,
    30.567785329729148
  ],
  [
    104.08588886260986,
    30.569207945014753
  ],
  [
    104.08671498298645,
    30.569406555229328
  ],
  [
    104.08722996711731,
    30.569471218932364
  ],
  [
    104.08792734146118,
    30.569448124757653
  ],
  [
    104.08847451210022,
    30.569369604522514
  ],
  [
    104.08972442150116,
    30.56875991582806
  ],
  [
    104.09031450748442,
    30.568607493055687
  ],
  [
    104.09087240695953,
    30.568570542044505
  ],
  [
    104.0916395187378,
    30.568598255304202
  ],
  [
    104.0923261642456,
    30.56887538746587
  ],
  [
    104.09303426742552,
    30.569448124757653
  ],
  [
    104.09350633621216,
    30.569780680342962
  ],
  [
    104.09404277801512,
    30.57007628435052
  ],
  [
    104.09708976745605,
    30.570796815344785
  ],
  [
    104.09910678863525,
    30.571355685058347
  ],
  [
    104.10050690174101,
    30.571545053404396
  ],
  [
    104.10232007503508,
    30.57161433441423
  ],
  [
    104.10394012928009,
    30.571526578460084
  ],
  [
    104.1054904460907,
    30.571300260106636
  ],
  [
    104.10678327083588,
    30.570889190726195
  ],
  [
    104.10819411277771,
    30.570200992021018
  ],
  [
    104.1094923019409,
    30.569429649413937
  ],
  [
    104.1103559732437,
    30.568639825178927
  ],
  [
    104.11131620407104,
    30.56730496141444
  ],
  [
    104.11170244216919,
    30.566140982171607
  ],
  [
    104.1118311882019,
    30.56427489106884
  ],
  [
    104.1120457649231,
    30.56349888222205
  ],
  [
    104.11298990249634,
    30.56163274030046
  ],
  [
    104.11303281784058,
    30.56105995686832
  ],
  [
    104.11286115646362,
    30.560634986330534
  ],
  [
    104.11268949508667,
    30.560450215950993
  ],
  [
    104.112389087677,
    30.56030239939401
  ],
  [
    104.11195993423462,
    30.560265445219578
  ],
  [
    104.11150932312012,
    30.560265445219578
  ],
  [
    104.11101579666138,
    30.560210013931567
  ],
  [
    104.11060810089111,
    30.560099151260506
  ],
  [
    104.10979270935057,
    30.559711130914387
  ],
  [
    104.10927772521973,
    30.559249199907903
  ],
  [
    104.10859644412994,
    30.558463912150348
  ],
  [
    104.1080117225647,
    30.55760470767675
  ],
  [
    104.10755574703217,
    30.55675011503614
  ],
  [
    104.1064453125,
    30.554167813712343
  ],
  [
    104.10631656646729,
    30.552855848973806
  ],
  [
    104.10640239715576,
    30.551340600025394
  ],
  [
    104.10668134689331,
    30.550324261258105
  ],
  [
    104.1071105003357,
    30.54943726600192
  ],
  [
    104.10768985748291,
    30.548753534960483
  ],
  [
    104.10854816436768,
    30.548180675515578
  ],
  [
    104.10921335220337,
    30.54799588142518
  ],
  [
    104.10951375961304,
    30.547478456100688
  ],
  [
    104.11043643951415,
    30.546480556615855
  ],
  [
    104.11120891571045,
    30.54649903632914
  ],
  [
    104.11164343357086,
    30.546697693024978
  ],
  [
    104.11164343357086,
    30.546859390035497
  ],
  [
    104.11200821399687,
    30.546965647924324
  ],
  [
    104.11294162273407,
    30.547108864894824
  ],
  [
    104.11419153213501,
    30.54704418628913
  ],
  [
    104.11434710025786,
    30.547122724590434
  ],
  [
    104.1150176525116,
    30.547723309499762
  ],
  [
    104.11506056785583,
    30.547848045899574
  ],
  [
    104.11492645740509,
    30.548222254137464
  ],
  [
    104.1149452328682,
    30.548349299816177
  ],
  [
    104.11512762308121,
    30.54910926049516
  ],
  [
    104.11517322063445,
    30.549197036725293
  ],
  [
    104.11532878875732,
    30.54922937531638
  ],
  [
    104.11550849676132,
    30.549213206022184
  ],
  [
    104.11593765020369,
    30.54896373657034
  ],
  [
    104.11603420972824,
    30.548940637514594
  ],
  [
    104.11612272262573,
    30.548952187043167
  ],
  [
    104.11688178777695,
    30.549402617585233
  ],
  [
    104.1169837117195,
    30.54942340663673
  ],
  [
    104.11710172891617,
    30.549397997795413
  ],
  [
    104.1173779964447,
    30.5492871227737
  ],
  [
    104.11853939294815,
    30.548448626321903
  ],
  [
    104.11861717700957,
    30.54841397755238
  ],
  [
    104.11868155002594,
    30.548400118041087
  ],
  [
    104.11952912807465,
    30.548430146979676
  ],
  [
    104.11962032318115,
    30.548446316404323
  ],
  [
    104.12003338336945,
    30.548614940243485
  ],
  [
    104.12092924118042,
    30.548845931328593
  ],
  [
    104.12111163139343,
    30.54885517096055
  ],
  [
    104.12127256393433,
    30.54880897279189
  ],
  [
    104.12164807319641,
    30.54836546925426
  ],
  [
    104.12190556526184,
    30.54840242795979
  ],
  [
    104.12216305732726,
    30.548587221276346
  ],
  [
    104.12256002426147,
    30.54852254365556
  ],
  [
    104.12295699119568,
    30.548448626321903
  ],
  [
    104.12365436553955,
    30.5485687419605
  ],
  [
    104.12418007850647,
    30.548014360850054
  ],
  [
    104.1241157054901,
    30.54773716910764
  ],
  [
    104.12412643432617,
    30.547626292189097
  ],
  [
    104.12472724914551,
    30.54729366067364
  ],
  [
    104.12482649087906,
    30.547289040783465
  ],
  [
    104.12487745285034,
    30.547300590508492
  ],
  [
    104.12522345781325,
    30.547487695862817
  ],
  [
    104.12529319524765,
    30.547496935624057
  ],
  [
    104.12589132785797,
    30.547425327451442
  ],
  [
    104.12595301866531,
    30.547418397625492
  ],
  [
    104.1260227560997,
    30.547397608144646
  ],
  [
    104.12611395120621,
    30.547418397625492
  ],
  [
    104.12629634141922,
    30.547614742502795
  ],
  [
    104.12649750709534,
    30.547781057852927
  ],
  [
    104.12657797336578,
    30.54780415718459
  ],
  [
    104.12677377462386,
    30.54780646711744
  ],
  [
    104.12681668996811,
    30.54779491745259
  ],
  [
    104.12678718566895,
    30.54781108698301
  ],
  [
    104.12704467773438,
    30.53387657299763
  ],
  [
    104.12601470947266,
    30.52973649132263
  ],
  [
    104.12155151367188,
    30.532102273884586
  ],
  [
    104.117431640625,
    30.531806554215013
  ],
  [
    104.1177749633789,
    30.524709012097027
  ],
  [
    104.11897659301758,
    30.50511414795302
  ],
  [
    104.117431640625,
    30.496017831341284
  ],
  [
    104.11721706390381,
    30.49544466095337
  ],
  [
    104.1090202331543,
    30.49557408682
  ],
  [
    104.10541534423828,
    30.4954261715298
  ],
  [
    104.10490036010742,
    30.493355333853483
  ],
  [
    104.10627365112305,
    30.490544840792403
  ],
  [
    104.10558700561523,
    30.488325973138874
  ],
  [
    104.10507202148438,
    30.483666186386614
  ],
  [
    104.09528732299805,
    30.48566326517115
  ],
  [
    104.09528732299805,
    30.49217197824867
  ],
  [
    104.08086776733397,
    30.493207415189985
  ],
  [
    104.07975196838379,
    30.488991638747354
  ],
  [
    104.06842231750488,
    30.490175033030358
  ],
  [
    104.06704902648924,
    30.535207276064373
  ],
  [
    104.0588092803955,
    30.53454192680974
  ],
  [
    104.05503273010254,
    30.536464034443824
  ],
  [
    104.0540885925293,
    30.540382059131083
  ],
  [
    104.04387474060059,
    30.540234212012663
  ],
  [
    104.04335975646971,
    30.53291549833425
  ],
  [
    104.04142856597899,
    30.528368746998297
  ],
  [
    104.03323173522949,
    30.528442679616198
  ],
  [
    104.03258800506592,
    30.52899717245727
  ],
  [
    104.03087139129637,
    30.529625593851495
  ],
  [
    104.02855396270752,
    30.52866447713238
  ],
  [
    104.02675151824951,
    30.52870144333585
  ],
  [
    104.02241706848145,
    30.527037950255746
  ],
  [
    104.01722431182861,
    30.528109982392568
  ],
  [
    104.01748180389404,
    30.530475804562
  ],
  [
    104.01791095733643,
    30.531917449196587
  ],
  [
    104.01816844940186,
    30.53380264451493
  ],
  [
    104.01731014251709,
    30.53587262076145
  ],
  [
    104.01761054992676,
    30.53672277679748
  ],
  [
    104.01670932769775,
    30.53772077656194
  ],
  [
    104.01679515838623,
    30.539605859248322
  ],
  [
    104.0161943435669,
    30.540345097372604
  ],
  [
    104.01503562927246,
    30.54097344535385
  ],
  [
    104.01336193084717,
    30.541084329869697
  ],
  [
    104.0107011795044,
    30.540012440913024
  ],
  [
    104.00932788848877,
    30.539975479013837
  ],
  [
    104.00864124298096,
    30.540936483820452
  ],
  [
    104.00838375091553,
    30.542119245911852
  ],
  [
    104.00881290435791,
    30.543154150924355
  ],
  [
    104.01061534881592,
    30.5442629654837
  ],
  [
    104.01185989379883,
    30.54485432807133
  ],
  [
    104.01263236999512,
    30.54500216815546
  ],
  [
    104.01366233825684,
    30.54511304807085
  ],
  [
    104.0141773223877,
    30.545039128141326
  ],
  [
    104.01503562927246,
    30.545408727226135
  ],
  [
    104.01559352874756,
    30.546074002033322
  ],
  [
    104.0156364440918,
    30.54736757888668
  ],
  [
    104.01533603668213,
    30.54847634532861
  ],
  [
    104.01486396789551,
    30.549400307690348
  ],
  [
    104.01374816894531,
    30.549880764643095
  ],
  [
    104.01301860809326,
    30.55039817716362
  ],
  [
    104.0136730670929,
    30.551839526071582
  ],
  [
    104.01385545730591,
    30.551941158840666
  ],
  [
    104.01649475097656,
    30.55353955112072
  ],
  [
    104.01906967163085,
    30.55605257708668
  ],
  [
    104.02233123779295,
    30.559008994915498
  ],
  [
    104.02336120605469,
    30.56196532267716
  ],
  [
    104.02301788330077,
    30.566104030220888
  ],
  [
    104.0240478515625,
    30.5677299027373
  ],
  [
    104.02748107910156,
    30.56743429157932
  ],
  [
    104.03091430664062,
    30.569060141800456
  ],
  [
    104.03228759765625,
    30.57541547664092
  ],
  [
    104.02765274047852,
    30.57718898413019
  ],
  [
    104.02164459228516,
    30.579701397549737
  ],
  [
    104.01623725891113,
    30.585058532281685
  ],
  [
    104.01621580123901,
    30.585007733256383
  ],
  [
    104.02044296264648,
    30.589602628298536
  ],
  [
    104.0243911743164,
    30.592779674575983
  ],
  [
    104.02971267700195,
    30.59499615681113
  ],
  [
    104.03048515319824,
    30.59595661670199
  ],
  [
    104.03048515319824,
    30.601275914486067
  ],
  [
    104.02833938598633,
    30.603639953090326
  ],
  [
    104.02301788330077,
    30.603713828367127
  ],
  [
    104.02250289916992,
    30.608884957731544
  ],
  [
    104.02301788330077,
    30.61191363386011
  ],
  [
    104.02379035949707,
    30.61427741282775
  ],
  [
    104.02421951293945,
    30.61582861141568
  ],
  [
    104.02456283569336,
    30.617453649947834
  ],
  [
    104.02585029602051,
    30.620112745074337
  ],
  [
    104.02642965316772,
    30.62105449041677
  ],
  [
    104.02793169021605,
    30.620906766263023
  ],
  [
    104.02870416641235,
    30.620795972999783
  ],
  [
    104.0321159362793,
    30.625966190160003
  ],
  [
    104.03597831726074,
    30.631948525318574
  ],
  [
    104.0419864654541,
    30.640662870231083
  ],
  [
    104.05237197875977,
    30.63560421473627
  ],
  [
    104.05597686767578,
    30.635714991047177
  ],
  [
    104.0561056137085,
    30.631911598458185
  ],
  [
    104.05554771423338,
    30.627277165616874
  ],
  [
    104.05486106872559,
    30.622402462905523
  ],
  [
    104.08446997404099,
    30.622750993833787
  ]
    
  ],
  
  
  
  
  
  
  [
    [
      103.8763439655304,
      30.75589703985065
    ],
    [
      103.87682676315308,
      30.75588781998434
    ],
    [
      103.87762069702147,
      30.7558693802491
    ],
    [
      103.87824296951294,
      30.755721862239998
    ],
    [
      103.87989521026611,
      30.75496582889594
    ],
    [
      103.8811182975769,
      30.75435730994126
    ],
    [
      103.88255596160887,
      30.753933188395603
    ],
    [
      103.88390779495238,
      30.75345374439886
    ],
    [
      103.88551712036131,
      30.752808335248915
    ],
    [
      103.88689041137695,
      30.752255123963092
    ],
    [
      103.88757705688477,
      30.752033838558884
    ],
    [
      103.88978719711302,
      30.751683468962263
    ],
    [
      103.89055967330933,
      30.751296216872362
    ],
    [
      103.89158964157104,
      30.750650793261986
    ],
    [
      103.89691114425659,
      30.745856082454324
    ],
    [
      103.9008378982544,
      30.74290537255077
    ],
    [
      103.90442132949829,
      30.74069228080818
    ],
    [
      103.90652418136597,
      30.73980702987609
    ],
    [
      103.90897035598755,
      30.739161529276828
    ],
    [
      103.91088008880614,
      30.738940213790094
    ],
    [
      103.91259670257568,
      30.739106200452802
    ],
    [
      103.91349792480467,
      30.739567273015496
    ],
    [
      103.91401290893555,
      30.74008367166615
    ],
    [
      103.91414165496825,
      30.740729166087146
    ],
    [
      103.91399145126343,
      30.741319328630222
    ],
    [
      103.91369104385376,
      30.741798833034476
    ],
    [
      103.9130687713623,
      30.742370546704887
    ],
    [
      103.91278982162476,
      30.742868488105007
    ],
    [
      103.91270399093628,
      30.743366426931274
    ],
    [
      103.9128541946411,
      30.74384592114615
    ],
    [
      103.91321897506714,
      30.7441225513383
    ],
    [
      103.9140772819519,
      30.744362296862228
    ],
    [
      103.91502141952515,
      30.74438073880088
    ],
    [
      103.91590118408203,
      30.74430697102505
    ],
    [
      103.91729593276978,
      30.743809037060505
    ],
    [
      103.91995668411255,
      30.74273939243359
    ],
    [
      103.92197370529175,
      30.7413746561833
    ],
    [
      103.92298221588135,
      30.740526296878002
    ],
    [
      103.92377614974976,
      30.73971481576954
    ],
    [
      103.92585754394531,
      30.737944287808656
    ],
    [
      103.92937660217285,
      30.73587863073339
    ],
    [
      103.93064260482788,
      30.734403134282182
    ],
    [
      103.93081426620483,
      30.734089588375728
    ],
    [
      103.93115758895874,
      30.73294605936601
    ],
    [
      103.93154382705688,
      30.732263624298295
    ],
    [
      103.9317798614502,
      30.73202384866782
    ],
    [
      103.93240213394165,
      30.731784072440774
    ],
    [
      103.93293857574463,
      30.73156274000942
    ],
    [
      103.93390417098999,
      30.731286073755427
    ],
    [
      103.93433332443237,
      30.731304518197074
    ],
    [
      103.934805393219,
      30.731415184772793
    ],
    [
      103.93579244613647,
      30.73169185065616
    ],
    [
      103.93785238265991,
      30.73182096112993
    ],
    [
      103.93871068954468,
      30.731839405469213
    ],
    [
      103.93903255462646,
      30.73169185065616
    ],
    [
      103.93922567367554,
      30.731525851221438
    ],
    [
      103.93941879272461,
      30.731267629310267
    ],
    [
      103.93967628479004,
      30.730861850623413
    ],
    [
      103.93959045410156,
      30.730492959425575
    ],
    [
      103.93948316574095,
      30.73032695792591
    ],
    [
      103.93911838531493,
      30.730124066815797
    ],
    [
      103.93894672393799,
      30.729884285862322
    ],
    [
      103.93898963928223,
      30.72942316696747
    ],
    [
      103.93933296203613,
      30.728998935635758
    ],
    [
      103.93959045410156,
      30.728722262022192
    ],
    [
      103.93993377685547,
      30.728611592354365
    ],
    [
      103.94034147262573,
      30.728464032599646
    ],
    [
      103.94085645675659,
      30.72853781250525
    ],
    [
      103.9409852027893,
      30.728722262022192
    ],
    [
      103.94137144088745,
      30.72918338427029
    ],
    [
      103.94167184829712,
      30.729349387739557
    ],
    [
      103.94205808639526,
      30.729349387739557
    ],
    [
      103.94261598587036,
      30.72925716362525
    ],
    [
      103.94328117370605,
      30.728980490752903
    ],
    [
      103.94375324249266,
      30.728593147397394
    ],
    [
      103.94409656524658,
      30.728224247516387
    ],
    [
      103.94424676895142,
      30.727836901121815
    ],
    [
      103.94416093826294,
      30.72759711447848
    ],
    [
      103.94394636154175,
      30.72746799834653
    ],
    [
      103.94353866577148,
      30.72746799834653
    ],
    [
      103.9432168006897,
      30.727375772432104
    ],
    [
      103.94304513931274,
      30.727320436841104
    ],
    [
      103.9430022239685,
      30.727043758409557
    ],
    [
      103.9430022239685,
      30.726730188560424
    ],
    [
      103.94315242767334,
      30.726342836162036
    ],
    [
      103.94343137741087,
      30.726010818581695
    ],
    [
      103.94381761550903,
      30.725771027395815
    ],
    [
      103.94424676895142,
      30.72575258189527
    ],
    [
      103.94514799118042,
      30.725844809362698
    ],
    [
      103.94562005996704,
      30.72578947289282
    ],
    [
      103.94592046737671,
      30.725549681156348
    ],
    [
      103.94589900970459,
      30.725236106447074
    ],
    [
      103.94566297531128,
      30.724996313334106
    ],
    [
      103.94465446472168,
      30.724332267754463
    ],
    [
      103.94416093826294,
      30.723834230567967
    ],
    [
      103.94413948059082,
      30.72329929887221
    ],
    [
      103.94443988800049,
      30.722801256349353
    ],
    [
      103.94459009170532,
      30.722690579883764
    ],
    [
      103.9447832107544,
      30.722506118825454
    ],
    [
      103.94491195678711,
      30.72235854972469
    ],
    [
      103.94525527954102,
      30.7222847650896
    ],
    [
      103.94628524780273,
      30.722506118825454
    ],
    [
      103.94662857055664,
      30.722192534216333
    ],
    [
      103.94692897796631,
      30.721897394828986
    ],
    [
      103.94697189331055,
      30.72165759341149
    ],
    [
      103.94701480865477,
      30.720735274708836
    ],
    [
      103.9471435546875,
      30.720513916907343
    ],
    [
      103.94750833511353,
      30.72044013086057
    ],
    [
      103.94802331924437,
      30.72038479128846
    ],
    [
      103.94866704940794,
      30.720698381777204
    ],
    [
      103.95057678222656,
      30.721510023011962
    ],
    [
      103.95134925842285,
      30.72171293225307
    ],
    [
      103.95338773727417,
      30.721694485976087
    ],
    [
      103.95383834838867,
      30.72158380823996
    ],
    [
      103.95482540130615,
      30.720864399858346
    ],
    [
      103.95534038543701,
      30.720938185580422
    ],
    [
      103.95643472671509,
      30.72158380823996
    ],
    [
      103.9566707611084,
      30.72154691563302
    ],
    [
      103.95847856998442,
      30.72218331112415
    ],
    [
      103.96033465862274,
      30.721754436363437
    ],
    [
      103.96089792251587,
      30.721740601661974
    ],
    [
      103.96120369434357,
      30.721906617948495
    ],
    [
      103.9613538980484,
      30.722155641842317
    ],
    [
      103.96141827106474,
      30.722584514818358
    ],
    [
      103.96109640598296,
      30.723368471241344
    ],
    [
      103.96123588085173,
      30.724032523459748
    ],
    [
      103.9618420600891,
      30.7243553249693
    ],
    [
      103.96209955215454,
      30.724959422033006
    ],
    [
      103.96224975585938,
      30.725807918386316
    ],
    [
      103.96255016326904,
      30.726158382091914
    ],
    [
      103.9632797241211,
      30.726287499978053
    ],
    [
      103.9639663696289,
      30.726029264032825
    ],
    [
      103.9651894569397,
      30.725162324014303
    ],
    [
      103.9659833908081,
      30.724664291116035
    ],
    [
      103.9661979675293,
      30.722690579883764
    ],
    [
      103.96647691726685,
      30.72243233430329
    ],
    [
      103.96694898605347,
      30.72222942657623
    ],
    [
      103.96729230880737,
      30.72180516358515
    ],
    [
      103.96757125854492,
      30.721676039695545
    ],
    [
      103.96795749664307,
      30.72165759341149
    ],
    [
      103.96827936172485,
      30.721749824796497
    ],
    [
      103.96879434585571,
      30.721030417653623
    ],
    [
      103.96907329559325,
      30.720882846294153
    ],
    [
      103.96950244903564,
      30.720772167626322
    ],
    [
      103.97021055221558,
      30.720403237816015
    ],
    [
      103.9711332321167,
      30.71942556699162
    ],
    [
      103.97403001785278,
      30.71865080193936
    ],
    [
      103.97598266601561,
      30.715883733078442
    ],
    [
      103.97624015808105,
      30.715828390891332
    ],
    [
      103.9807677268982,
      30.717101253159388
    ],
    [
      103.98093938827515,
      30.717322618795304
    ],
    [
      103.98085355758667,
      30.717839136636275
    ],
    [
      103.97737741470337,
      30.72241388816394
    ],
    [
      103.97694826126097,
      30.722967270809523
    ],
    [
      103.97669076919556,
      30.723520650278726
    ],
    [
      103.97448062896729,
      30.725881700324955
    ],
    [
      103.97392272949219,
      30.726545735231497
    ],
    [
      103.97366523742674,
      30.727228210785487
    ],
    [
      103.97357940673828,
      30.727670895047673
    ],
    [
      103.97353649139404,
      30.72820580248529
    ],
    [
      103.97362232208252,
      30.728500922559498
    ],
    [
      103.97372961044312,
      30.729072715131935
    ],
    [
      103.97392272949219,
      30.72929405328157
    ],
    [
      103.9742660522461,
      30.730400736405507
    ],
    [
      103.9752960205078,
      30.731064740180397
    ],
    [
      103.97658348083496,
      30.731802516787134
    ],
    [
      103.97821426391602,
      30.732392734006083
    ],
    [
      103.97950172424316,
      30.732466510904306
    ],
    [
      103.97950172424316,
      30.73290917110753
    ],
    [
      103.97641181945801,
      30.736524153326393
    ],
    [
      103.96954536437988,
      30.742425873654216
    ],
    [
      103.97194862365723,
      30.746335564276233
    ],
    [
      103.96920204162598,
      30.748622290897163
    ],
    [
      103.96448135375975,
      30.75083520044918
    ],
    [
      103.96044731140137,
      30.75304805915245
    ],
    [
      103.94697189331055,
      30.75924379298793
    ],
    [
      103.94577026367188,
      30.759981353791616
    ],
    [
      103.94371032714844,
      30.76315280085516
    ],
    [
      103.9419937133789,
      30.76691414912237
    ],
    [
      103.93632888793945,
      30.771412823598634
    ],
    [
      103.94010543823241,
      30.775985031323472
    ],
    [
      103.9310073852539,
      30.782253027476553
    ],
    [
      103.92525672912598,
      30.79301827930348
    ],
    [
      103.92345428466797,
      30.798400453249858
    ],
    [
      103.92087936401367,
      30.80429837998386
    ],
    [
      103.91993522644043,
      30.806215128240485
    ],
    [
      103.9185619354248,
      30.807247207619508
    ],
    [
      103.91401290893555,
      30.808795305902553
    ],
    [
      103.88483047485352,
      30.769937871533127
    ],
    [
      103.87298583984375,
      30.784760111575803
    ],
    [
      103.86534690856934,
      30.78778327312566
    ],
    [
      103.85324478149414,
      30.77104408770176
    ],
    [
      103.87504577636719,
      30.759981353791616
    ],
    [
      103.8760757446289,
      30.758284955495245
    ],
    [
      103.8763439655304,
      30.75589703985065
    ]
  ],
  
  
  
  
  [
    
  [
    104.18554097414017,
    30.32420946420142
  ],
  [
    104.18553829193115,
    30.324211779436713
  ],
  [
    104.18676674365997,
    30.326351033468487
  ],
  [
    104.18779134750366,
    30.328490240777764
  ],
  [
    104.18785035610199,
    30.32882824977923
  ],
  [
    104.18806493282318,
    30.329110694762516
  ],
  [
    104.18875962495804,
    30.32959455353757
  ],
  [
    104.18980568647385,
    30.329872366108
  ],
  [
    104.19167518615721,
    30.329995066409015
  ],
  [
    104.19603109359741,
    30.33710216109928
  ],
  [
    104.19774234294891,
    30.338771211792242
  ],
  [
    104.19834047555923,
    30.339768924761763
  ],
  [
    104.19843435287476,
    30.34063005167808
  ],
  [
    104.19828414916991,
    30.34287079804396
  ],
  [
    104.1969296336174,
    30.346160065234045
  ],
  [
    104.19631272554398,
    30.34886593122777
  ],
  [
    104.19633954763412,
    30.34969688788984
  ],
  [
    104.19657021760939,
    30.35129397140938
  ],
  [
    104.19772624969482,
    30.35351827183304
  ],
  [
    104.19972449541092,
    30.35540460561498
  ],
  [
    104.20635223388672,
    30.36177621018143
  ],
  [
    104.207124710083,
    30.362461254468705
  ],
  [
    104.20858383178711,
    30.364821843297864
  ],
  [
    104.21000003814697,
    30.367173118272323
  ],
  [
    104.21107292175292,
    30.368357991324174
  ],
  [
    104.21369075775146,
    30.37106092923761
  ],
  [
    104.2157506942749,
    30.372986264098625
  ],
  [
    104.2174243927002,
    30.3744302403619
  ],
  [
    104.21823978424072,
    30.375429903742283
  ],
  [
    104.21855092048645,
    30.376346252860607
  ],
  [
    104.22047138214111,
    30.375133708250864
  ],
  [
    104.22188758850098,
    30.37528180610877
  ],
  [
    104.22334671020508,
    30.376170388544413
  ],
  [
    104.2241621017456,
    30.376540628841997
  ],
  [
    104.22467708587646,
    30.376392532891227
  ],
  [
    104.22467708587646,
    30.374615363981377
  ],
  [
    104.22484874725342,
    30.373689742378332
  ],
  [
    104.22849655151366,
    30.37169036980603
  ],
  [
    104.23141479492188,
    30.37020932672447
  ],
  [
    104.23450469970703,
    30.369024476106393
  ],
  [
    104.23759460449219,
    30.367987720037757
  ],
  [
    104.24051284790039,
    30.365766062875743
  ],
  [
    104.24291610717773,
    30.363692470653177
  ],
  [
    104.24617767333984,
    30.361470715911796
  ],
  [
    104.25081253051758,
    30.359397032618983
  ],
  [
    104.2557907104492,
    30.355545789870668
  ],
  [
    104.26111221313477,
    30.35006491379473
  ],
  [
    104.26403045654295,
    30.34828726643185
  ],
  [
    104.26763534545897,
    30.3463614453484
  ],
  [
    104.27003860473633,
    30.34325042354528
  ],
  [
    104.27244186401367,
    30.34102820457309
  ],
  [
    104.2743730545044,
    30.338694820356928
  ],
  [
    104.27626132965088,
    30.33750958802677
  ],
  [
    104.27836418151855,
    30.337398471760164
  ],
  [
    104.2793083190918,
    30.338398513619605
  ],
  [
    104.28012371063231,
    30.34006522735121
  ],
  [
    104.28128242492676,
    30.340398566692347
  ],
  [
    104.28235530853271,
    30.34065782983959
  ],
  [
    104.28312778472899,
    30.3407319048984
  ],
  [
    104.28415775299072,
    30.33936150723686
  ],
  [
    104.28540229797362,
    30.337361432976586
  ],
  [
    104.28604602813719,
    30.33654657619303
  ],
  [
    104.28647518157959,
    30.335398357396045
  ],
  [
    104.28707599639891,
    30.33425012513356
  ],
  [
    104.28836345672606,
    30.334213085158982
  ],
  [
    104.28977966308594,
    30.334620524108843
  ],
  [
    104.29123878479004,
    30.335176119944524
  ],
  [
    104.29235458374023,
    30.33595394881798
  ],
  [
    104.29299831390381,
    30.336583615284862
  ],
  [
    104.29295539855956,
    30.337954051832096
  ],
  [
    104.29261207580566,
    30.33936150723686
  ],
  [
    104.29235458374023,
    30.34017634059105
  ],
  [
    104.29080963134766,
    30.346213303695375
  ],
  [
    104.29080963134766,
    30.349027956757453
  ],
  [
    104.29012298583984,
    30.351842528864772
  ],
  [
    104.28806304931639,
    30.355397662121728
  ],
  [
    104.28823471069336,
    30.356175330301554
  ],
  [
    104.29149627685547,
    30.35628642525117
  ],
  [
    104.29218292236328,
    30.356249393615318
  ],
  [
    104.29252624511719,
    30.35710111769353
  ],
  [
    104.29261207580566,
    30.358100958236307
  ],
  [
    104.2928695678711,
    30.358693451513467
  ],
  [
    104.29437160491943,
    30.35847126695508
  ],
  [
    104.29600238800049,
    30.358175020092204
  ],
  [
    104.29643154144287,
    30.35858235929735
  ],
  [
    104.29677486419678,
    30.359878427301638
  ],
  [
    104.29720401763916,
    30.360470909812573
  ],
  [
    104.29840564727783,
    30.360211699155627
  ],
  [
    104.29969310760498,
    30.359248910701528
  ],
  [
    104.3005084991455,
    30.359174849658707
  ],
  [
    104.30076599121094,
    30.360322789521298
  ],
  [
    104.30093765258789,
    30.361618834464732
  ],
  [
    104.30153846740721,
    30.36221130643335
  ],
  [
    104.30286884307861,
    30.36195210038869
  ],
  [
    104.30419921875,
    30.360804179647847
  ],
  [
    104.30548667907713,
    30.358249081892023
  ],
  [
    104.30643081665038,
    30.356990023669102
  ],
  [
    104.30784702301025,
    30.355879076485785
  ],
  [
    104.30943489074706,
    30.355397662121728
  ],
  [
    104.31089401245117,
    30.354953277529233
  ],
  [
    104.32188034057617,
    30.356138298623645
  ],
  [
    104.32737350463867,
    30.35910078855978
  ],
  [
    104.33012008666991,
    30.361915070897652
  ],
  [
    104.33372497558592,
    30.365914174923507
  ],
  [
    104.33784484863281,
    30.367543392643316
  ],
  [
    104.33835983276366,
    30.368728261208474
  ],
  [
    104.33595657348633,
    30.370357432042177
  ],
  [
    104.3345832824707,
    30.372282780756848
  ],
  [
    104.33286666870117,
    30.375689074061068
  ],
  [
    104.3345832824707,
    30.378502879007097
  ],
  [
    104.33698654174805,
    30.380724246747615
  ],
  [
    104.33921813964844,
    30.38161277970734
  ],
  [
    104.34436798095703,
    30.38235321766959
  ],
  [
    104.34514045715332,
    30.383537906740475
  ],
  [
    104.34496879577637,
    30.384944706358652
  ],
  [
    104.34514045715332,
    30.386203404639215
  ],
  [
    104.34479713439941,
    30.387832284227873
  ],
  [
    104.34514045715332,
    30.38849863623295
  ],
  [
    104.34659957885742,
    30.389831326609237
  ],
  [
    104.34659957885742,
    30.391386109072137
  ],
  [
    104.34651374816895,
    30.39353314893672
  ],
  [
    104.34685707092285,
    30.39442156542216
  ],
  [
    104.34840202331543,
    30.395532074666125
  ],
  [
    104.35183525085448,
    30.394791736572984
  ],
  [
    104.35483932495117,
    30.394717702455036
  ],
  [
    104.35810089111327,
    30.39656853856939
  ],
  [
    104.36084747314453,
    30.39827127681912
  ],
  [
    104.36385154724121,
    30.400788313767322
  ],
  [
    104.36591148376465,
    30.40197277929529
  ],
  [
    104.36779975891113,
    30.403009174846854
  ],
  [
    104.36882972717285,
    30.404489720838985
  ],
  [
    104.36882972717285,
    30.407672818701744
  ],
  [
    104.36822891235352,
    30.410115590864454
  ],
  [
    104.36788558959961,
    30.411522007715064
  ],
  [
    104.36848640441895,
    30.411966135142258
  ],
  [
    104.37123298645018,
    30.41100385649601
  ],
  [
    104.37277793884277,
    30.410707768850255
  ],
  [
    104.37440872192383,
    30.410855812785393
  ],
  [
    104.3748378753662,
    30.411744071681248
  ],
  [
    104.37492370605469,
    30.412706343030358
  ],
  [
    104.37586784362793,
    30.413816644337622
  ],
  [
    104.37664031982422,
    30.41440879987054
  ],
  [
    104.37732696533203,
    30.413964683557655
  ],
  [
    104.37930107116699,
    30.413150465068853
  ],
  [
    104.38015937805176,
    30.41492693301478
  ],
  [
    104.38050270080566,
    30.41633328054082
  ],
  [
    104.38204765319824,
    30.41699943808526
  ],
  [
    104.38316345214844,
    30.41648131594365
  ],
  [
    104.38290596008301,
    30.414260761324122
  ],
  [
    104.38359260559082,
    30.413520565223973
  ],
  [
    104.3854808807373,
    30.413520565223973
  ],
  [
    104.38762664794922,
    30.413224485212158
  ],
  [
    104.39045906066895,
    30.413372525330324
  ],
  [
    104.39260482788085,
    30.414260761324122
  ],
  [
    104.3950080871582,
    30.415519081811507
  ],
  [
    104.39835548400879,
    30.41988606823241
  ],
  [
    104.40178871154785,
    30.423512738916244
  ],
  [
    104.40393447875977,
    30.426991255563397
  ],
  [
    104.40462112426758,
    30.427953376568325
  ],
  [
    104.40530776977539,
    30.42824941189154
  ],
  [
    104.40702438354492,
    30.427213284329845
  ],
  [
    104.40788269042969,
    30.425807093607872
  ],
  [
    104.40865516662598,
    30.424474894226915
  ],
  [
    104.40985679626465,
    30.423290701727836
  ],
  [
    104.4111442565918,
    30.42269860008843
  ],
  [
    104.41492080688477,
    30.421292344294063
  ],
  [
    104.41586494445801,
    30.420256142845158
  ],
  [
    104.41740989685059,
    30.41810969055452
  ],
  [
    104.41895484924316,
    30.41699943808526
  ],
  [
    104.4206714630127,
    30.415223007862238
  ],
  [
    104.42290306091309,
    30.413224485212158
  ],
  [
    104.42427635192871,
    30.411447986280734
  ],
  [
    104.42599296569824,
    30.409893523193663
  ],
  [
    104.42753791809082,
    30.40782086723945
  ],
  [
    104.4297695159912,
    30.406784522760823
  ],
  [
    104.4316577911377,
    30.407228671741567
  ],
  [
    104.43500518798827,
    30.408783177262148
  ],
  [
    104.43852424621582,
    30.41078179084589
  ],
  [
    104.44024085998535,
    30.412114177168935
  ],
  [
    104.44230079650879,
    30.413446545305213
  ],
  [
    104.44521903991698,
    30.41263232249414
  ],
  [
    104.44710731506348,
    30.410337658030098
  ],
  [
    104.44865226745605,
    30.408635130183754
  ],
  [
    104.45380210876465,
    30.406784522760823
  ],
  [
    104.45972442626953,
    30.405155959211367
  ],
  [
    104.46307182312012,
    30.40374945064906
  ],
  [
    104.46393013000488,
    30.403231258176813
  ],
  [
    104.46277141571045,
    30.40321275125192
  ],
  [
    104.46232080459593,
    30.403194244323544
  ],
  [
    104.46156978607178,
    30.4029906678799
  ],
  [
    104.46144104003906,
    30.402694555931475
  ],
  [
    104.46154832839966,
    30.402120836476087
  ],
  [
    104.46161270141602,
    30.401417562867874
  ],
  [
    104.4632863998413,
    30.399455772875754
  ],
  [
    104.46352243423462,
    30.39914114251191
  ],
  [
    104.4636082649231,
    30.398363816090875
  ],
  [
    104.46369409561157,
    30.397993658477763
  ],
  [
    104.46335077285767,
    30.397197814858952
  ],
  [
    104.46292161941528,
    30.39647599759662
  ],
  [
    104.46262121200562,
    30.39584671665653
  ],
  [
    104.46208477020264,
    30.39499533010796
  ],
  [
    104.46161270141602,
    30.393995866864493
  ],
  [
    104.46156978607178,
    30.391959891587316
  ],
  [
    104.46354389190674,
    30.389387098503647
  ],
  [
    104.46313619613647,
    30.388017382462937
  ],
  [
    104.46189165115356,
    30.388072911865056
  ],
  [
    104.45753574371338,
    30.391108471161388
  ],
  [
    104.45650577545166,
    30.390590211616868
  ],
  [
    104.45594787597656,
    30.38849863623295
  ],
  [
    104.45416152477263,
    30.386888277774663
  ],
  [
    104.45358753204346,
    30.383181575991074
  ],
  [
    104.45275068283081,
    30.38079829146946
  ],
  [
    104.45320129394531,
    30.37835478602933
  ],
  [
    104.45440292358398,
    30.375837171077578
  ],
  [
    104.45508956909178,
    30.37465238866319
  ],
  [
    104.45508956909178,
    30.37331949128317
  ],
  [
    104.45405960083008,
    30.37272708661425
  ],
  [
    104.45062637329102,
    30.373171390452463
  ],
  [
    104.44822311401367,
    30.37198657573015
  ],
  [
    104.4466781616211,
    30.370653642004587
  ],
  [
    104.44547653198242,
    30.36858015342299
  ],
  [
    104.44787979125975,
    30.365914174923507
  ],
  [
    104.45096969604492,
    30.36561795060364
  ],
  [
    104.45405960083008,
    30.364729272260305
  ],
  [
    104.45508956909178,
    30.362655658054585
  ],
  [
    104.45594787597656,
    30.360878239457048
  ],
  [
    104.46006774902344,
    30.360137638841763
  ],
  [
    104.46126937866211,
    30.36117447813302
  ],
  [
    104.46281433105469,
    30.361766952793353
  ],
  [
    104.46453094482422,
    30.359989518045797
  ],
  [
    104.46624755859374,
    30.359248910701528
  ],
  [
    104.46779251098633,
    30.36117447813302
  ],
  [
    104.46950912475586,
    30.362655658054585
  ],
  [
    104.4693374633789,
    30.364729272260305
  ],
  [
    104.47225570678711,
    30.36206318877766
  ],
  [
    104.47448730468749,
    30.36206318877766
  ],
  [
    104.47723388671875,
    30.362507541071825
  ],
  [
    104.47843551635741,
    30.360137638841763
  ],
  [
    104.47912216186523,
    30.358212050999125
  ],
  [
    104.47826385498047,
    30.355990171771825
  ],
  [
    104.47998046875,
    30.35406450228875
  ],
  [
    104.48324203491211,
    30.35406450228875
  ],
  [
    104.48856353759766,
    30.354953277529233
  ],
  [
    104.49165344238281,
    30.354953277529233
  ],
  [
    104.49560165405273,
    30.35569391739534
  ],
  [
    104.49800491333008,
    30.35376824208106
  ],
  [
    104.4990348815918,
    30.351694395509025
  ],
  [
    104.50006484985352,
    30.349472368261814
  ],
  [
    104.50246810913085,
    30.34784284954548
  ],
  [
    104.50504302978516,
    30.34784284954548
  ],
  [
    104.50624465942383,
    30.349472368261814
  ],
  [
    104.50658798217773,
    30.351101859843464
  ],
  [
    104.50847625732422,
    30.35213879490344
  ],
  [
    104.51087951660156,
    30.35213879490344
  ],
  [
    104.51465606689453,
    30.3499167777479
  ],
  [
    104.51620101928711,
    30.34769471013487
  ],
  [
    104.5180892944336,
    30.34813912769397
  ],
  [
    104.51997756958008,
    30.349176094149833
  ],
  [
    104.52375411987305,
    30.34887981914083
  ],
  [
    104.52512741088866,
    30.346213303695375
  ],
  [
    104.52701568603516,
    30.34399115199148
  ],
  [
    104.52890396118164,
    30.345028162398517
  ],
  [
    104.53130722045898,
    30.34562073484083
  ],
  [
    104.53336715698241,
    30.34325042354528
  ],
  [
    104.53113555908203,
    30.339546697231103
  ],
  [
    104.53371047973633,
    30.337324394179017
  ],
  [
    104.53611373901367,
    30.336731771512106
  ],
  [
    104.53817367553711,
    30.335250199151083
  ],
  [
    104.53920364379883,
    30.334064925120494
  ],
  [
    104.53903198242188,
    30.331546170166447
  ],
  [
    104.54263687133789,
    30.330509016942763
  ],
  [
    104.54401016235352,
    30.327545661492763
  ],
  [
    104.54401016235352,
    30.325026738862697
  ],
  [
    104.53765869140625,
    30.324434041770782
  ],
  [
    104.53353881835938,
    30.320581423259316
  ],
  [
    104.53130722045898,
    30.320285061713502
  ],
  [
    104.53130722045898,
    30.317321396948888
  ],
  [
    104.5323371887207,
    30.31524677827466
  ],
  [
    104.53096389770508,
    30.31302392380996
  ],
  [
    104.52117919921874,
    30.314802211415493
  ],
  [
    104.52083587646483,
    30.314061262168238
  ],
  [
    104.52169418334961,
    30.312134767906088
  ],
  [
    104.52272415161133,
    30.310356431896864
  ],
  [
    104.52306747436523,
    30.30828166577001
  ],
  [
    104.52255249023438,
    30.305762247858855
  ],
  [
    104.52220916748047,
    30.3036873844871
  ],
  [
    104.52255249023438,
    30.2996857382589
  ],
  [
    104.52049255371094,
    30.299389313522617
  ],
  [
    104.51791763305663,
    30.300130373683043
  ],
  [
    104.51293945312499,
    30.299982162099035
  ],
  [
    104.51019287109375,
    30.299092887890204
  ],
  [
    104.50950622558594,
    30.297166099436673
  ],
  [
    104.51139450073242,
    30.2934606306162
  ],
  [
    104.51379776000977,
    30.291385506922204
  ],
  [
    104.51448440551758,
    30.289013883226843
  ],
  [
    104.51139450073242,
    30.285604573707126
  ],
  [
    104.50899124145508,
    30.283677520264256
  ],
  [
    104.50830459594727,
    30.278933842846154
  ],
  [
    104.50967788696288,
    30.278044377800153
  ],
  [
    104.51139450073242,
    30.275968928008236
  ],
  [
    104.5103645324707,
    30.27359693164586
  ],
  [
    104.50950622558594,
    30.27003882962186
  ],
  [
    104.51208114624023,
    30.266184073558826
  ],
  [
    104.51345443725586,
    30.262180897456524
  ],
  [
    104.51379776000977,
    30.258474107402265
  ],
  [
    104.51602935791016,
    30.2558051319317
  ],
  [
    104.51602935791016,
    30.25298780136615
  ],
  [
    104.51517105102539,
    30.25061524979676
  ],
  [
    104.51190948486328,
    30.248984087355694
  ],
  [
    104.50761795043945,
    30.246166561171986
  ],
  [
    104.5071029663086,
    30.243052359289663
  ],
  [
    104.50899124145508,
    30.239196544037164
  ],
  [
    104.50727462768555,
    30.236230429370867
  ],
  [
    104.50521469116211,
    30.238306719035243
  ],
  [
    104.5016098022461,
    30.24053126643564
  ],
  [
    104.50075149536133,
    30.24156937132543
  ],
  [
    104.49560165405273,
    30.24231086810482
  ],
  [
    104.49182510375977,
    30.241272771047235
  ],
  [
    104.49148178100586,
    30.239641453517258
  ],
  [
    104.48684692382812,
    30.24067956780551
  ],
  [
    104.48392868041992,
    30.241272771047235
  ],
  [
    104.47998046875,
    30.240827868951598
  ],
  [
    104.47792053222656,
    30.238455023761645
  ],
  [
    104.47483062744139,
    30.235933812981585
  ],
  [
    104.468994140625,
    30.236082121288117
  ],
  [
    104.46556091308594,
    30.232670973629617
  ],
  [
    104.46560382843016,
    30.231484459743548
  ],
  [
    104.46577548980713,
    30.228258552732147
  ],
  [
    104.46564674377441,
    30.22714614646456
  ],
  [
    104.47105407714844,
    30.200333349994086
  ],
  [
    104.47174072265625,
    30.190244210264005
  ],
  [
    104.47174072265625,
    30.183715392538552
  ],
  [
    104.46968078613281,
    30.177186142075502
  ],
  [
    104.46762084960938,
    30.165907326727396
  ],
  [
    104.46487426757812,
    30.159970589718387
  ],














[
    104.46487426757812,
    30.15996595150285
  ],
  [
    104.46472406387329,
    30.159497490609695
  ],
  [
    104.46464896202087,
    30.159154260423787
  ],
  [
    104.46448802947998,
    30.158644051182723
  ],
  [
    104.4642949104309,
    30.15805034983354
  ],
  [
    104.4636297225952,
    30.157187621189507
  ],
  [
    104.46290016174316,
    30.15644548298369
  ],
  [
    104.46212768554688,
    30.155666231856706
  ],
  [
    104.4614839553833,
    30.154998297418526
  ],
  [
    104.4605827331543,
    30.154033495243763
  ],
  [
    104.45985317230225,
    30.15318000852932
  ],
  [
    104.45916652679443,
    30.152103862579715
  ],
  [
    104.45508956909178,
    30.146314738085053
  ],
  [
    104.45234298706055,
    30.14067372591087
  ],
  [
    104.45114135742186,
    30.134735469964642
  ],
  [
    104.45148468017577,
    30.130875412002318
  ],
  [
    104.44942474365234,
    30.12597589029753
  ],
  [
    104.4466781616211,
    30.124045708929074
  ],
  [
    104.44496154785156,
    30.120927643939744
  ],
  [
    104.44478988647461,
    30.117364020544315
  ],
  [
    104.4418716430664,
    30.114542727501153
  ],
  [
    104.4411849975586,
    30.109939392178905
  ],
  [
    104.43946838378906,
    30.108899899678804
  ],
  [
    104.43569183349608,
    30.10637537236278
  ],
  [
    104.4331169128418,
    30.107563393251965
  ],
  [
    104.43260192871094,
    30.109345397803718
  ],
  [
    104.43105697631835,
    30.111127370215993
  ],
  [
    104.42968368530273,
    30.113206297408112
  ],
  [
    104.42693710327148,
    30.115730650183107
  ],
  [
    104.42264556884766,
    30.116176117505724
  ],
  [
    104.42075729370117,
    30.11587913951386
  ],
  [
    104.41801071166992,
    30.116770070810812
  ],
  [
    104.41629409790038,
    30.118848879252113
  ],
  [
    104.41543579101562,
    30.121670049295695
  ],
  [
    104.40290451049805,
    30.124342662364686
  ],
  [
    104.39552307128906,
    30.125085042046717
  ],
  [
    104.3917465209961,
    30.130133075858343
  ],
  [
    104.39002990722656,
    30.133399313136767
  ],
  [
    104.38591003417969,
    30.142009784255944
  ],
  [
    104.38556671142578,
    30.147799161369214
  ],
  [
    104.38333511352539,
    30.155517802372515
  ],
  [
    104.38076019287108,
    30.16041585739418
  ],
  [
    104.37664031982422,
    30.1656104983674
  ],
  [
    104.359130859375,
    30.182528288276167
  ],
  [
    104.35157775878906,
    30.18905718468536
  ],
  [
    104.32960510253906,
    30.197959527771424
  ],
  [
    104.32273864746094,
    30.203893976001527
  ],
  [
    104.30763244628906,
    30.208047876887466
  ],
  [
    104.29389953613281,
    30.21576179908697
  ],
  [
    104.28085327148438,
    30.217541849095714
  ],
  [
    104.27810668945312,
    30.21902519949919
  ],
  [
    104.27656173706055,
    30.22036019573785
  ],
  [
    104.27398681640625,
    30.21976686631304
  ],
  [
    104.26986694335938,
    30.21976686631304
  ],
  [
    104.26832199096678,
    30.22095352158375
  ],
  [
    104.26797866821288,
    30.223326789176987
  ],
  [
    104.26763534545897,
    30.225848323247707
  ],
  [
    104.26849365234375,
    30.229259707613494
  ],
  [
    104.26832199096678,
    30.231632774762375
  ],
  [
    104.26815032958984,
    30.234154095850688
  ],
  [
    104.26712036132812,
    30.23712027316867
  ],
  [
    104.26660537719727,
    30.238751632543142
  ],
  [
    104.26351547241211,
    30.239789756229758
  ],
  [
    104.25956726074219,
    30.239789756229758
  ],
  [
    104.25596237182617,
    30.24067956780551
  ],
  [
    104.2547607421875,
    30.24067956780551
  ],
  [
    104.25184249877928,
    30.241124470572473
  ],
  [
    104.25046920776367,
    30.24156937132543
  ],
  [
    104.24737930297852,
    30.243348954197124
  ],
  [
    104.24480438232422,
    30.244535324875464
  ],
  [
    104.24154281616211,
    30.244535324875464
  ],
  [
    104.23604965209961,
    30.244535324875464
  ],
  [
    104.2334747314453,
    30.24586997476859
  ],
  [
    104.23107147216797,
    30.24779777038891
  ],
  [
    104.23038482666016,
    30.25002210295159
  ],
  [
    104.23089981079102,
    30.25298780136615
  ],
  [
    104.23158645629883,
    30.254322336443444
  ],
  [
    104.23381805419922,
    30.25743618111515
  ],
  [
    104.23604965209961,
    30.260105112263386
  ],
  [
    104.23999786376953,
    30.26292223867753
  ],
  [
    104.24703598022461,
    30.264849699658892
  ],
  [
    104.24772262573242,
    30.266184073558826
  ],
  [
    104.24720764160156,
    30.27018708644781
  ],
  [
    104.24531936645508,
    30.273300428069934
  ],
  [
    104.24308776855469,
    30.27522768530293
  ],
  [
    104.23845291137695,
    30.276265423522855
  ],
  [
    104.23553466796875,
    30.28056450782511
  ],
  [
    104.23313140869139,
    30.28041626667403
  ],
  [
    104.22935485839844,
    30.280119783700034
  ],
  [
    104.22609329223633,
    30.280860989455412
  ],
  [
    104.22489166259764,
    30.284863403785405
  ],
  [
    104.22523498535155,
    30.287679819699456
  ],
  [
    104.22918319702147,
    30.29790717639933
  ],
  [
    104.22883987426756,
    30.30131605828983
  ],
  [
    104.22832489013672,
    30.305317637976145
  ],
  [
    104.2276382446289,
    30.308726262208744
  ],
  [
    104.22592163085938,
    30.313468498736643
  ],
  [
    104.22540664672852,
    30.316580466750533
  ],
  [
    104.22231674194336,
    30.31880324053713
  ],
  [
    104.21957015991211,
    30.31895142366329
  ],
  [
    104.21459197998047,
    30.319247789243317
  ],
  [
    104.21236038208008,
    30.319544153926838
  ],
  [
    104.21133041381836,
    30.32102596389705
  ],
  [
    104.20755386352539,
    30.322804106276315
  ],
  [
    104.205322265625,
    30.322063217540613
  ],
  [
    104.20377731323242,
    30.321174143661388
  ],
  [
    104.20120239257811,
    30.32102596389705
  ],
  [
    104.19965744018555,
    30.320433242598465
  ],
  [
    104.19656753540039,
    30.320136880604423
  ],
  [
    104.19536590576172,
    30.3217668604774
  ],
  [
    104.19210433959961,
    30.3217668604774
  ],
  [
    104.1891860961914,
    30.32147050251765
  ],
  [
    104.18781280517578,
    30.32265592897744
  ],
  [
    104.18575286865233,
    30.324137691879947
  ],
  [
    104.18554097414017,
    30.32420946420142
  ]
  ]
  
  
  
  
  
]


export{
  gxxq,
  gxdq,
  gxnq,
  tfxq,
  gxq
}