<template>
  <div v-show="show">
    <div v-if="isChengdu" class="key-indicator-chengdu-right-rank">
      <el-row style="height: 32px;line-height: 32px">
        <span style="float: right;margin-right: 10px;cursor: pointer;font-size: 22px;" @click="handleClose">×</span>
      </el-row>
      <el-table
        class="rank-table2"
        :data="rankData"
        :default-sort="{prop: 'amount', order: 'descending'}">
        <el-table-column
          align="center"
          label="序号"
          width="48">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          label="区域"
          width="70"
          align="center"
          prop="Name" />
        <el-table-column
          width="68"
          label="报警"
          align="center"
          prop="RecodeNumber"
          sortable>
          <template slot-scope="scope">
            {{ `${scope.row.RecodeNumber}` }}
          </template>
        </el-table-column>
        <el-table-column
          width="68"
          label="派单"
          align="center"
          prop="SendNumber"
          sortable>
          <template slot-scope="scope">
            {{ `${scope.row.SendNumber}` }}
          </template>
        </el-table-column>
        <el-table-column
          width="92"
          label="巡查核实"
          align="center"
          prop="VerifyNumber"
          sortable>
          <template slot-scope="scope">
            {{ `${scope.row.VerifyNumber}` }}
          </template>
        </el-table-column>
        <el-table-column
          width="92"
          label="执法处理"
          align="center"
          prop="HandleNumber"
          sortable>
          <template slot-scope="scope">
            {{ `${scope.row.HandleNumber}` }}
          </template>
        </el-table-column>
        <el-table-column
          width="92"
          label="设备维护"
          align="center"
          prop="MaintenanceNumber"
          sortable>
          <template slot-scope="scope">
            {{ `${scope.row.MaintenanceNumber}` }}
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex;align-items: center;justify-content:center;">
        <div style="text-align:center;" v-if="showRecode">
          <dv-active-ring-chart
            :key="key1"
            :config="recodeConfig"
            class="region-compare-chart"
          />
          <span style="font-size: 14rem;color: #ffffff;">报警数量占比</span>
        </div>
        <div style="text-align:center;" v-if="showSend">
          <dv-active-ring-chart
            v-if="showSend"
            :key="key2"
            :config="sendConfig"
            class="region-compare-chart"
          />
          <span style="font-size: 14rem;color: #ffffff;">派单数量占比</span>
        </div>
        <div style="text-align:center;" v-if="showVerify">
          <dv-active-ring-chart
            v-if="showVerify"
            :key="key3"
            :config="verifyConfig"
            class="region-compare-chart"
          />
          <span style="font-size: 14rem;color: #ffffff;">核实数量占比</span>
        </div>
        <div style="text-align:center;" v-if="showHandle">
          <dv-active-ring-chart
            v-if="showHandle"
            :key="key4"
            :config="handleConfig"
            class="region-compare-chart"
          />
          <span style="font-size: 14rem;color: #ffffff;">处理数量占比</span>
        </div>
        <div style="text-align:center;" v-if="showMaintenance">
          <dv-active-ring-chart
            v-if="showMaintenance"
            :key="key5"
            :config="maintenanceConfig"
            class="region-compare-chart"
          />
         <span style="font-size: 14rem;color: #ffffff;">维保数量占比</span>
        </div>
      </div>
    </div>
    <div v-else class="reduce-mr-rank">
      <el-row style="height: 32px;line-height: 32px;">
        <span class="key-rank-title">{{ type }}排名</span>
        <span style="float: right;margin-right: 10px;cursor: pointer;font-size: 22px;" @click="handleClose">×</span>
      </el-row>
      <el-table
        class="rank-table1"
        :data="listData"
        :default-sort="{prop: 'V', order: 'descending'}">
        <el-table-column
          type="index"
          align="center"
          width="40">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          width="70"
          align="center"
          prop="Name"/>
        <el-table-column
          width="88"
          align="center"
          prop="V"
          sortable>
          <template slot-scope="scope">
            {{ `${scope.row.V.toFixed(2) + ' ' + suffix}` }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import countTo from "vue-count-to";
import {
  getUserInfo
}  from '@/util/index'

export default {
  name: 'reduceRank',
  props: {
    sumData: {
      type: Object,
      default: () => {
        return {}
      }
    },

    show: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: ''
    }
  },
  components: {
    countTo
  },
  data() {
    return {
      listData: [],
      suffix: '',
      rankData: [],
      isChengdu: false,
      config: {
        radius: "65%",
        activeRadius: "80%",
        lineWidth: 20,
        digitalFlopStyle: {
          fontSize: 12,
          fill: "#fff",
        },
        data: [],
      },
      recodeConfig: null,
      showRecode: false,
      handleConfig: null,
      showHandle: false,
      sendConfig: null,
      showSend: false,
      verifyConfig: null,
      showVerify: false,
      maintenanceConfig: null,
      showMaintenance: false,
      key1: 10,
      key2: 20,
      key3: 30,
      key4: 40,
      key5: 50,
    }
  },
  created() {
    this.user = getUserInfo()
    if (this.user.Info && this.user.Info.pid && this.user.Info.pid.slice(0,6) == "/1199/") {
      this.isChengdu = true
    }
  },
  mounted() {
    this.getData()
    moment.locale('zh-cn');
  },
  watch: {
    'type': {
      handler: function () {
        this.getData()
      },
      deep: true,
      immediate: true
    },
    sumData: {
      handler: function () {
        this.getData()
      },
      deep: true,
    }
  },
  methods: {
    getData() {
      if (this.sumData) {
        if (this.sumData.sumDataDaily) {
          this.rankData = this.sumData.sumDataDaily
          this.$nextTick(() => {
            this.init()
          })
          return
        }
        if (this.sumData.streetData) {
          this.rankData = this.sumData.streetData
          this.initConfig()
          this.$nextTick(() => {
            var _this = this
            window.addEventListener('resize', function(e){
              _this.$nextTick(()=>{
                _this.key1++
                _this.key2++
                _this.key3++
                _this.key4++
                _this.key5++
              })
            })
          })
          return
        }
      }
    },
    init() {
      this.listData = []
      if (this.type === '减排累计') {
        this.listData = this.rankData.map(e => {
          return {
            Name: e.Name,
            V: e.EmissionsAmount + e.RedPm25Amount + e.RedPm10Amount + e.RedVocsAmount
          }
        }).sort(function (a, b) {
          return b - a
        })
        this.suffix = 'kg'
      } else if (this.type === 'PM2.5昨日减排') {
        this.listData = this.rankData.map(e => {
          return {
            Name: e.Name,
            V: e.RedPm25Amount
          }
        }).sort(function (a, b) {
          return b - a
        })
        this.suffix = 'kg'
      } else if (this.type === 'PM10昨日减排') {
        this.listData = this.rankData.map(e => {
          return {
            Name: e.Name,
            V: e.RedPm10Amount
          }
        }).sort(function (a, b) {
          return b - a
        })
        this.suffix = 'kg'
      } else if (this.type === '油烟减排') {
        this.listData = this.rankData.map(e => {
          return {
            Name: e.Name,
            V: e.EmissionsAmount
          }
        }).sort(function (a, b) {
          return b - a
        })
        this.suffix = 'kg'
      } else if (this.type === '非甲烷减排') {
        this.listData = this.rankData.map(e => {
          return {
            Name: e.Name,
            V: e.RedVocsAmount
          }
        }).sort(function (a, b) {
          return b - a
        })
        this.suffix = 'kg'
      }
    },
    initConfig() {
      this.getRecodeConfig()
      this.getSendConfig()
      this.getVerifyConfig()
      this.getHandleConfig()
      this.getMaintenanceConfig()
    },
    getRecodeConfig() {
      this.showRecode = false
      const config = JSON.parse(JSON.stringify(this.config));
      const recodeData = this.rankData ? JSON.parse(JSON.stringify(this.rankData)): [];
      config.data = recodeData.map((item) => {
        if (item.RecodeNumber > 0) {
          this.showRecode = true
        }
        item.value = item.RecodeNumber;
        item.name = item.Name;
        return item;
      });
      if (this.showRecode) {
        config.color = [
          "#FFA91F",
          "#ef4764",
          "#facc14",
          "#1233ff",
          "#623143",
        ]
        config.lineWidth = 16
        this.recodeConfig = config;
        this.key1++;
      }
    },
    getSendConfig() {
      this.showSend = false
      const config = JSON.parse(JSON.stringify(this.config));
      const sendData = this.rankData ? JSON.parse(JSON.stringify(this.rankData)): [];
      config.data = sendData.map((item) => {
        if (item.SendNumber > 0) {
          this.showSend = true
        }
        item.value = item.SendNumber;
        item.name = item.Name;
        return item;
      });
      if (this.showSend) {
        config.color = [
          "#3f8ffd",
          "#11c2c1",
          "#1a419d",
          "#2ee3ff",
          "#6235ff",
        ]
        config.lineWidth = 16
        this.sendConfig = config;
        this.key2++;
      }
    },
    getVerifyConfig() {
      this.showVerify = false
      const config = JSON.parse(JSON.stringify(this.config));
      const verifyData = this.rankData ? JSON.parse(JSON.stringify(this.rankData)): [];
      config.data = verifyData.map((item) => {
        if (item.VerifyNumber > 0) {
          this.showVerify = true
        }
        item.value = item.VerifyNumber;
        item.name = item.Name;
        return item;
      });
      if (this.showVerify) {
        config.color = [
          "#FF5D1D",
          "#FFA91F",
          "#FFF803",
          "#9DF90D",
          "#11EA00",
        ]
        config.lineWidth = 16
        this.verifyConfig = config;
        this.key3++;
      }
    },
    getHandleConfig() {
      this.showHandle = false
      const config = JSON.parse(JSON.stringify(this.config));
      const handleData = this.rankData ? JSON.parse(JSON.stringify(this.rankData)): [];
      config.data = handleData.map((item) => {
        if (item.HandleNumber > 0) {
          this.showHandle = true
        }
        item.value = item.HandleNumber;
        item.name = item.Name;
        return item;
      });
      if (this.showHandle) {
        config.color = [
          "#b620e0",
          "#eb2f95",
          "#ef4764",
          "#6235ff",
          "#ff8c00",
        ]
        config.lineWidth = 16
        this.handleConfig = config;
        this.key4++;
      } 
    },
    getMaintenanceConfig() {
      this.showMaintenance = false
      const config = JSON.parse(JSON.stringify(this.config));
      const maintenanceData = this.rankData ? JSON.parse(JSON.stringify(this.rankData)): [];
      config.data = maintenanceData.map((item) => {
        if (item.MaintenanceNumber > 0) {
          this.showMaintenance = true
        }
        item.value = item.MaintenanceNumber;
        item.name = item.Name;
        return item;
      });
      if(this.showMaintenance) {
        config.color = [
          "#ff8c00",
          "#facc14",
          "#a0d911",
          "#11c2c1",
          "#6235ff",
        ]
        config.lineWidth = 16
        this.maintenanceConfig = config;
        this.key5++;
      }
    },
    handleClose() {
      this.$emit("handle-close")
    }
  }
}
</script>

<style lang="scss">
.reduce-mr-rank {
  position: absolute;
  right: 445rem;
  top: 156rem;
  z-index: 8;
  background: rgba(21, 55, 135, 0.9);
  .el-table tbody tr:hover>td {
    background-color:#164184 !important //修改成自己想要的颜色即可
  }
  .key-rank-title {
    padding-left: 10rem !important;
    padding-top: 10rem !important;
    font-size: 14px !important;
    padding-bottom: 10rem !important;
  }
  .el-table::before {
    height: 0px !important;
  }
  .rank-table1 {
    background-color: transparent;
    min-height: 40vh;
    max-height: 57vh;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #3a92ed;
    }
    
    th {
      display: none !important;
    }
    tr {
      background: rgba(21, 55, 135, 0.8);
      color: #ffffff;
      font-size: 12px !important;
    }
  }
}


.key-indicator-chengdu-right-rank {
  border: none;
  position: absolute;
  right: 445rem;
  top: 156rem;
  z-index: 8;
  background: rgba(19, 31, 53, 0.8);
  .rank-table2 {
    background-color: transparent;
    min-height: 40vh;
    max-height: 57vh;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #223e61;
    }
    th, tr {
      background: linear-gradient(#19253e, #223e61);
      color: #ffffff;
      font-size: 12px !important;
      font-weight: 400;
    }
  }
  .el-table tbody tr:hover>td {
    background-color:#19253e !important
  }
  .el-table::before {
    height: 0px !important;
  }
  .region-compare-chart {
    width: 100rem;
    height: 100rem;
  }
}


</style>
