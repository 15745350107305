<template>
  <div class="region_compare_anji">
    <div class="region-compare-title">
      <div style="float:left;  display: flex;align-items: center;height:26rem;"><img style="width:20rem;margin-right: 3rem;padding-left:10rem;" src="./img/per.png" /></div>占比情况
    </div>
    <ul>
      <li>
        <dv-active-ring-chart
          :key="key1"
          :config="installNumConfig"
          class="region-compare-chart"
        />
        <span style="font-size: 14rem;color: #ffffff;">各街道安装数量占比</span>
      </li>
      <li>
        <dv-active-ring-chart
          :config="enterInstallConfig"
          :key="key2"
          class="region-compare-chart"
        />
        <span style="font-size: 14rem;color: #ffffff;">大中小型企业安装占比</span>
      </li>
    </ul>
  </div>
</template>

<script>
import decBlockTitle from "../decorations/blockTitle2";
import {
  getUserInfo
}  from '@/util/index'

export default {
  name: "regionAnjiCompare",
  components: {
    decBlockTitle,
  },
  props: ['onlineDeviceData'],
  data() {
    return {
      loading: true,
      config: {
        radius: "65%",
        activeRadius: "80%",
        lineWidth: 20,
        digitalFlopStyle: {
          fontSize: 12,
          fill: "#fff",
        },
        data: [],
      },
      installNumConfig: null,
      enterInstallConfig: null,
      key1: 1,
      key2: 1,
      option:{}
    };
  },
  computed: {},
  watch: {
    onlineDeviceData: {
      handler: function () {
        this.getData()
      },
      deep: true
    },
  },
  created() {
    
  },
  mounted() {
    this.getData()
    var _this=this
    window.addEventListener('resize', function(e){
      _this.$nextTick(()=>{
        _this.key1++
        _this.key2++
      })
    })
  },
  methods: {
    getData() {
      if (this.onlineDeviceData) {
        this.getinstallNumConfig();
        this.getenterInstallConfig();
        this.loading = false
      }
    },
    // 安装数量占比
    getinstallNumConfig() {
      const config = JSON.parse(JSON.stringify(this.config));
      const device = this.onlineDeviceData.device
        ? JSON.parse(JSON.stringify(this.onlineDeviceData.device))
        : [];
      config.data = device.map((item) => {
        item.value = item.V;
        item.name = item.N;
        return item;
      });
      config.color = [
        "#3f8ffd",
        "#11c2c1",
        "#1a419d",
        "#2ee3ff",
        "#6235ff",
      ]
      config.lineWidth = 15
      this.installNumConfig = config;
      this.key1++;
    },
    // 大中小型企业安装占比
    getenterInstallConfig() {
      const config = JSON.parse(JSON.stringify(this.config));
      const enterprise = this.onlineDeviceData.enterprise
        ? JSON.parse(JSON.stringify(this.onlineDeviceData.enterprise))
        : [];
      config.data = enterprise.map((item) => {
        item.value = item.V;
        item.name = item.K + "型";
        return item;
      });
      config.color = [
        "#FF5D1D",
        "#FFA91F",
        "#FFF803",
        "#9DF90D",
        "#11EA00",
      ]
      config.lineWidth = 15
      this.enterInstallConfig = config;
      this.key2++;
    },
  }
};
</script>

<style lang="scss">
.region_compare_anji {
  width: 100%;
  box-sizing: border-box;
  ul {
    height: 10vh;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-inline-start: 0px !important;
    li {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 12vh;
      > span {
        font-size: 12rem;
        color: #b2f2f6;
        text-align: center;
      }
    }
  }
}

.region-compare-title {
  font-size: 16rem;
  font-weight:bold;
}

.region-compare-chart {
  width: 120rem;
  height: 120rem;
}

</style>
