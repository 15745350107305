<template>
<div>
  <div style="display: flex;justify-content: space-between;margin-top:10rem;margin-right:10rem;">
    <el-row>
      <div style="font-size: 16rem;font-weight:bold;float:left;  display: flex;align-items: center;height:40rem;"><img style="width:22rem;margin-right: 3rem;padding-left:10rem;" src="./img/log.png" /><span style="white-space: nowrap; overflow: hidden;text-overflow:ellipsis;">事件处理记录</span><el-image 
        v-if="isAnji"
        style="width:20rem;height:20rem;margin-right: 3rem;padding-left:6rem;"
        :z-index="10000"
        :preview-src-list="[require('./img/notice3.png')]"
        :src="require('./img/wenhao.png')">
        </el-image></div>
    </el-row>
    <div style="position:relative;display: flex;justify-content: space-between;z-index:10;height: 32px;display: flex;align-items: center;">
      <el-select v-model="selectEvent" placeholder="事件类型" size="mini" @change=changeData()>
        <el-option label="红码处理" :value="1"></el-option>
        <el-option label="报警管理" :value="2"></el-option>
      </el-select>
      <el-select style="padding-left:2px;" v-model="selectType" placeholder="状态选择" size="mini" @change=changeData()>
        <el-option label="待整改" :value="1"></el-option>
        <el-option label="整改中" :value="2"></el-option>
        <el-option label="整改成功" :value="3"></el-option>
        <el-option label="执法中" :value="4"></el-option>
        <el-option label="已完成" :value="5"></el-option>
      </el-select>
    </div>
  </div>
  <div style="margin-left: 10rem;margin-right: 10rem;">
    <el-carousel trigger="click" indicator-position="none">
      <el-carousel-item v-for="item in alarmData" :key="item.Id"  @click.native="getInfo(item)">
        <div style="background: rgba(1,29,54, 1);">
          <div style="padding:2rem 12rem;font-size: 12rem;">
            <p>
              <span style="color:#ffffff;">事件类型：</span>
              <span style="color:#3f8ffd">{{item.EventTyp}}</span>
            </p>
            <p style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
              <span style="color:#ffffff;">点位名称单位：</span>
              <span style="color:#c7c7cc">{{item.LocaleName}}</span>
            </p>
            <p style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
              <span style="color:#ffffff;">报警地址：</span>
              <span style="color:#c7c7cc;">{{item.Addr}}</span>
            </p>
            <p>
              <span style="color:#ffffff;">发起时间：</span>
              <span style="color:#c7c7cc">{{formaterTime(item.StartAt)}}</span>
            </p>
            <p>
              <span style="color:#ffffff;">事件状态：</span>
              <span style="color:green">{{item.EventStatus}}</span>
            </p>
            <p style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
              <span style="color:#ffffff;">处理措施：</span>
              <span style="color:#c7c7cc">{{item.Cause}}</span>
            </p>
            <div style="height:60rem;overflow:hidden;">
              <el-image v-for="item in item.Pics" :key="item" :src="item" style="width: 60rem;height: 60rem;margin-left:6rem;border-radius: 3px;" />
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
  <div class="reduce-anji-rank" v-if="showData">
    <div style="padding: 2rem 8rem;font-size:12rem;" v-if="selectEvent == 1">
      <el-row style="height: 32px;line-height: 32px;">
        <span style="font-size:12rem;color:#f1f1f1;">{{ healthCodeDetail.LocaleName }}</span>
        <span style="float: right;margin-right: 10px;cursor: pointer;font-size: 16rem;" @click="closeInfo">×</span>
      </el-row>
      <div class="anji-health-code">
        <div style="border-bottom: 1px dashed #ffffff;"></div>
        <p>
          <span style="color:#ffffff;">所属单位：</span>
          <span style="color:#c7c7cc">{{healthCodeDetail.Owner}}</span>
        </p>
        <p>
          <span style="color:#ffffff;">报警类型：</span>
          <span style="color:yellow">红码事件</span>
        </p>
        <p>
          <span style="color:#ffffff;">发起时间：</span>
          <span style="color:#c7c7cc">{{formaterTime(healthCodeDetail.CreateAt)}}</span>
        </p>
        <p>
          <span style="color:#ffffff;">事件状态：</span>
          <span style="color:#c7c7cc">
            <template v-if="healthCodeDetail.Status == 'WAIT_TO_VERIFY'">待整改</template>
            <template v-else-if="healthCodeDetail.Status == 'WAIT_TO_RECTIFY'">整改中</template>
            <template v-else-if="healthCodeDetail.Status == 'WAIT_TO_HANDLE'">处理中</template>
            <template v-else>已完结</template>
          </span>
        </p>
        <template v-if="healthCodeDetail.RectifyStatus > 0">
          <div style="border-bottom: 1px dashed #ffffff;"></div>
          <p>
            <span style="color:#ffffff;">核实情况</span>
          </p>
          <p>
            <span style="color:#ffffff;">核实时间：</span>
            <span style="color:#c7c7cc">{{formaterTime(healthCodeDetail.VerifyAt)}}</span>
          </p>
          <p>
            <span style="color:#ffffff;">核实人员：</span>
            <span style="color:#c7c7cc">{{healthCodeDetail.Verifier}}</span>
          </p>
          <p>
            <span style="color:#ffffff;">是否属实：</span>
            <template v-if="healthCodeDetail.RectifyMessage == '不属实'">
              <span style="color:#c7c7cc">不属实</span>
            </template>
            <template v-else>
              <span style="color:#c7c7cc">属实</span>
            </template>
          </p>
          <p>
            <span style="color:#ffffff;">红码原因：</span>
            <span style="color:#c7c7cc">{{healthCodeDetail.Cause}}</span>
          </p>
          <p>
            <span style="color:#ffffff;">核实照片：</span>
          </p>
          <div>
            <el-image v-for="item in checkPics" :key="item" :src="item" style="width: 66px;height: 66px;margin-left:6px;border-radius: 3px;" />
          </div>
          <div style="border-bottom: 1px dashed #ffffff;"></div>
          <p>
            <span style="color:#ffffff;">整改情况</span>
          </p>
          <p>
            <span style="color:#ffffff;">整改状态：</span>
            <template v-if="healthCodeDetail.RectifyStatus == 1"><span style="color:#aaaaaa;">整改中</span></template><template v-else-if="healthCodeDetail.RectifyStatus == 2"><span style="color:#4091ff;">整改完成</span></template><template v-else><span style="color:#660000;">整改失败</span></template>
          </p>
          <p>
            <span style="color:#ffffff;">整改日期：</span>
            <span style="color:#c7c7cc;">{{fixTime(healthCodeDetail.VerifyAt, 1)}}&nbsp&nbsp~&nbsp&nbsp{{fixTime(healthCodeDetail.VerifyAt, healthCodeDetail.RectifyDate)}}</span>
          </p>
             
          <el-table
            :data="sumDataDaily"
            size="mini"
            border
          >
            <el-table-column
              prop="AcquitDate"
              label="数据日期"
              align="center"
              width="80"
              header-align="center"
            />
            <el-table-column
              prop="Status"
              label="状态"
              width="66"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <span>
                  <template v-if="scope.row.Status == 1">正常</template>
                  <template v-else-if="scope.row.Status == 2">超标</template>
                  <template v-else-if="scope.row.Status == 3">正常离线</template>
                  <template v-else>异常离线</template>
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="FilterAbnormallyUsedDays"
              label="净化器使用状态"
              align="center"
              width="100"
              header-align="center"
            >
              <template slot-scope="scope">
                <span>
                  {{
                    scope.row.FilterAbnormallyUsed === null
                      ? "-"
                      : scope.row.FilterAbnormallyUsed
                        ? "不正常"
                        : "正常"
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="CEmissions"
              label="油烟浓度"
              width="66"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.CEmissions || "-" }}</span>
              </template>
            </el-table-column>
          </el-table>
          <div style="border-bottom: 1px dashed #ffffff;margin-top:8px;"></div>
        </template>
        <template v-if="healthCodeDetail.RectifyStatus == 3">
          <p>
            <span style="color:#ffffff;">事件处理</span>
          </p>
           <p>
            <span style="color:#ffffff;">处理状态：</span>
            <span style="color:green"><template v-if="healthCodeDetail.Status == 'WAIT_TO_HANDLE'">处理中</template><template v-else>已完成</template></span>
          </p>
          <template v-if="healthCodeDetail.Status == 'FINISHED'">
            <p>
              <span style="color:#ffffff;">处理日期：</span>
              <span style="color:#c7c7cc">{{formaterTime(healthCodeDetail.HandleAt)}}</span>
            </p>
            <p>
              <span style="color:#ffffff;">处理人员：</span>
              <span style="color:#c7c7cc">{{healthCodeDetail.Handler}}</span>
            </p>
            <p>
              <span style="color:#ffffff;">处理措施：</span>
              <span style="color:#c7c7cc">{{healthCodeDetail.Resolution}}</span>
            </p>
            <p>
              <span style="color:#ffffff;">核实照片：</span>
            </p>
            <div>
              <el-image v-for="item in handlePics" :key="item" :src="item" style="width: 66px;height: 66px;margin-left:6px;border-radius: 3px;" />
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import moment from 'moment'
import {
  dateFormater,
  getUserInfo
} from '@/util/index'
export default {
  name: 'eventAnjiData',
  props: {
    eventData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      dataLoading: true,
      showData: false,
      healthCodeDetail: {},
      managementDetail: {},
      alarmData: [],
      selectType: 1,
      selectEvent: 1,
      handleHealthData: [], //健康码待处理 执法中
      handleRecodeData: [], //报警事件待处理  执法中
      handleFinishData: [], //整改成功
      handleOnData: [], //整改中
      healthCodeData: [], //待整改
      handleCompleteData: [], //整改完成
      checkPics:[],
      handlePics:[],
      sumDataDaily: [],
      isAnji: false,
    }
  },
  mounted() {
    this.user = getUserInfo()
    this.getData()
    if (this.user.Info  && (this.user.Info.id == 688 || (this.user.Info.pid && this.user.Info.pid.slice(0,5) == "/688/"))) {
      this.isAnji = true
    }
    moment.locale('zh-cn');
  },
  watch: {
    eventData: {
      handler: function () {
        this.getData()
      },
      deep: true
    },
  },

  methods: {
    formaterTime(time) {
      return dateFormater(time*1000, false, false)
    },
    changeData(){
      this.updateData()
    },

    getData() {
      this.dataLoading = true
      if (this.eventData) {
        this.handleHealthData = this.eventData.handle_force_data
        this.handleFinishData = this.eventData.handle_finish_data
        this.handleOnData = this.eventData.handle_on_data
        this.healthCodeData = this.eventData.handle_wait_data
        this.handleCompleteData = this.eventData.handle_complete_data
        this.updateData();
        this.dataLoading = false
      }
    },
    updateData() {
      this.alarmData = []
      var list = []
      if (this.selectType == 1) {
        list = this.healthCodeData
      } else if(this.selectType == 2) {
        list = this.handleOnData
      } else if(this.selectType == 3) {
        list = this.handleFinishData
      } else if(this.selectType == 4) {
        list = this.handleHealthData
      } else {
        list = this.handleCompleteData
      }
      for(var i = 0; i < list.length; i++) {
        if (this.selectEvent == 1) {
          if (list[i].EventTyp == "红码处理"){
            this.alarmData.push(list[i])
          }
        } else {
          if (list[i].EventTyp == "报警处理"){
            this.alarmData.push(list[i])
          }
        }
      }
    },
    getInfo(item){
      this.healthCodeDetail = {}
      this.managementDetail = {}
      if(item.EventTyp == "红码处理") {
        this.checkPics = []
        this.handlePics = []
        this.$get('admin/listHealthCode/' + item.Id).then(res => {
          for (var i = 0; i < res.MaintenancePics.length; i++) {
            if (res.MaintenancePics[i].Type == 1) {
              this.checkPics.push(res.MaintenancePics[i].PicUrl)
            } else {
              this.handlePics.push(res.MaintenancePics[i].PicUrl)
            }
          }
          this.healthCodeDetail = res
          if (this.healthCodeDetail.RectifyStatus > 0) {
            this.getDailyData()
          }
        })
      } else {
        this.$get('admin/alarmManagement/info/' + item.Id).then(res => {
          var proPics = res.info.process_pics.split(',')
          for (var i = 0; i < proPics.length; i++) {
            if (proPics[i]) {
              this.checkPics.push(proPics[i])
            }
          }
          var handlePics = res.info.handle_pics.split(',')
          var images = []
          for (var i = 0; i < handlePics.length; i++) {
            if (handlePics[i]) {
              this.handlePics.push(handlePics[i])
            }
          }
          this.managementDetail = res.info
          console.log("报警管理",this.managementDetail)
        })
      }
      this.showData = true
    },
    closeInfo() {
      this.showData = false
    },
    fixTimestamp(time, n) {
      var date = new Date(time * 1000);
      date.setHours(0, 0, 0, 0); // 设置为凌晨0点
      return date.getTime() / 1000 + n * 86400
    },
    fixTime(time, n) {
      if (time > 0) {
        var timestamp = time  + n * 86400
        const date = new Date(timestamp * 1000); // 将时间戳转换成毫秒
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${year}-${month}-${day}`;
      }
      return "-"
    },
    getDailyData() {
      this.sumDataDaily = []
      const tpl = { localeId: this.healthCodeDetail.LocaleId }
      tpl['AcquitAtBegin'] = this.fixTimestamp(this.healthCodeDetail.VerifyAt, 0)
      tpl['AcquitAtEnd'] = this.fixTimestamp(this.healthCodeDetail.VerifyAt, this.healthCodeDetail.RectifyDate + 1) - 1
      this.$post('admin/queryDataDetectorDaily', tpl).then((res) => {
        for(var i = 0; i < res.length; i++) {
          let arr = res[i].AcquitDate.split(" ")
          res[i].AcquitDate = arr[0]
          this.sumDataDaily.unshift(res[i])
        }
      })
    },
  }
}
</script>

<style lang="scss">

.reduce-anji-rank {
  position: absolute;
  right: 445rem;
  top: 40vh;
  z-index: 8;
  width:372rem;
  background: rgba(21, 55, 135, 1);
}
.anji-health-code{
  min-height: 20vh;
  max-height: 41.8vh;
  padding-bottom: 10rem;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 1px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #3a92ed;
  }
  .el-table{
    background: rgba(21, 55, 135, 1);
  }
  th, tr {
    background: rgba(21, 55, 135, 1);
    color: #ffffff;
    font-size: 10px !important;
  }
  .el-table tbody tr:hover > td {
    background-color:#164184 !important //修改成自己想要的颜色即可
  }
  .el-table::before {
    height: 0px !important;
  }
}

</style>
