<template>
  <div :class="[showMore ? 'unfold' : 'fold', 'exceed_content']" @click="showMore = !showMore">
    <dec-block-title>
      {{ contentData.level === 'district' ? '街道信息展示' : '区县信息展示' }}
      <span style="float:right">{{ showMore ? '点击关闭' : '点击展开' }}</span>
    </dec-block-title>
    <ul class="ul_head" v-show="showMore">
      <li class="li_item">
        <span class="item1">{{ contentData.level === 'district' ? '街道' : '区县' }}</span>
        <span class="item1">监测点数</span>
        <span class="item3">在线数</span>
        <span class="item3">在线率</span>
        <span class="item3">达标数</span>
        <span class="item3">达标率</span>
        <span class="item3">超标数</span>
        <span class="item3">超标率</span>
      </li>
    </ul>
    <ul class="ul_cont" v-show="showMore">
      <li class="li_item" v-for="(item, index) in ulDataFormatter(contentData.sumData.streetData)" :key="index">
        <span class="item1">{{ item.Name }}</span>
        <span class="item1">{{ item.Number || '-' }}</span>
        <span class="item3">{{ item.OnlineDeviceCount || '-' }}</span>
        <span class="item3">{{ item.OnlinePercent || '-' }}</span>
        <span class="item3">{{ item.NomalDeviceCount || '-' }}</span>
        <span class="item3">{{ item.NomalPercent || '-' }}</span>
        <span class="item3">{{ item.ExcessDeviceCount || '-' }}</span>
        <span class="item3">{{ item.ExcessPercent || '-' }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import conf from '@/config'
import decBlockTitle from '../decorations/blockTitle2'

export default {
  name: 'SubDistrictInfoPanel',
  props: ['contentData'],
  components: {
    decBlockTitle
  },
  data() {
    return {
      showMore: false
    }
  },
  methods: {
    ulDataFormatter(data) {
      if (this.contentData.level === 'district') {
        return data
      }
      return data.filter(e => conf.areaListOrder.includes(e.Name))
    }
  }
}
</script>

<style lang="scss" scoped>
div:first-child {
  z-index: 5;
}
.unfold {
  position: absolute;
  bottom: calc(120px + 8px);
  left: 1060px;
  width: 400px !important;
}
.fold {
  position: absolute;
  bottom: calc(120px + 8px);
  left: 1260px;
}
.exceed_content {
  font-size: 14px;
  color: #ffffff;
  min-width: 200px;
  background-color: #1a419d;
  box-shadow: 0 8px 20px 0 #00071a;
  border-radius: 4px;
  .ul_head {
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #67e0e3;
    }
    .li_item {
      display: flex;
      justify-content: space-around;
      height: 37.5px;
      line-height: 37.5px;
      span {
        text-align: center;
        display: inline-block;
      }
      .item1 {
        width: 40%;
      }
      .item2,
      .item3 {
        width: 30%;
      }
    }
  }
  .ul_cont {
    margin: 0;
    padding: 0;
    height: 28vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #67e0e3;
    }
    .li_item {
      display: flex;
      justify-content: space-around;
      height: 37.5px;
      line-height: 37.5px;
      span {
        text-align: center;
        display: inline-block;
      }
      .item1 {
        width: 40%;
      }
      .item2,
      .item3 {
        width: 30%;
      }
    }
  }
}
.exceed_content:hover {
  cursor: pointer;
}
</style>
