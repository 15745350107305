<template>
  <div v-if="isData" v-show="show">
    <div class="key-indicator-chengdu-rank">
      <div class="event-message">
        <div size="mini" style="position:relative;display: flex;justify-content: space-between;z-index:10;background-color:#131E36;height: 32px;display: flex;align-items: center;padding-top:8px;padding-bottom: 8px;">
          <el-select style="width: 100px;padding-left:10px;" v-model="selectType" placeholder="请选择" size="mini" @change=changeData()>
            <el-option label="待整改" value="1"></el-option>
            <el-option label="整改中" value="2"></el-option>
            <el-option label="整改成功" value="3"></el-option>
            <el-option label="执法中" value="4"></el-option>
          </el-select>
          <div style="display: flex;align-items: center;">
            <span style="float: right;margin-left: 6px;margin-right: 6px;cursor: pointer;font-size: 22px;" @click="handleClose">×</span>
          </div>
        </div>
        <div>
          <vue-seamless-scroll :data="alarmData"  :class-option="optionHover" ref="eventScroll">
            <div class="event-item" v-for="(item, index) in pagedData">
              <el-row>
                <el-col class="indexTag" :span="2">{{index+1}}</el-col>
                <el-col :span="22">
                  <el-row>
                    <el-col :span="6">
                      <span>事件类型:</span>
                    </el-col>
                    <el-col :span="18">
                      <span>{{ item.EventTyp }}</span>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="6">
                      <span>点位名称:</span>
                    </el-col>
                    <el-col :span="18">
                      <span>{{ item.LocaleName }}</span>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="6">
                      <span>地址:</span>
                    </el-col>
                    <el-col :span="18">
                      <span>{{ item.Addr }}</span>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="6">
                      <span>报警时间</span>
                    </el-col>
                    <el-col :span="18">
                      <span>{{ formaterTime(item.StartAt) }}</span>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="6">
                      <span>派单时间</span>
                    </el-col>
                    <el-col :span="18">
                      <span>{{ formaterTime(item.CreateAt) }}</span>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="6">
                      <span>事件状态</span>
                    </el-col>
                    <el-col :span="18">
                      <span style="color: red;">{{ item.EventStatus }}</span>
                    </el-col>
                  </el-row>     
                </el-col>
              </el-row>    
            </div>
          </vue-seamless-scroll>
        </div>
      </div>
      <div class="indexPage">
        <el-pagination
          background
          :small="isData"
          @current-change="handleCurrentChange"
          :hide-on-single-page="hiddenPage"
          :current-page.sync="currentPage"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
  <div v-else>
    <div :class="isChengdu?'key-indicator-chengdu-rank':'key-indicator-rank'" v-show="show">
      <el-row style="height: 32px;line-height: 32px">
        <span class="key-rank-title">{{ type }}排名</span>
        <span style="float: right;margin-right: 10px;cursor: pointer;font-size: 22px;" @click="handleClose">×</span>
      </el-row>
      <el-table
        class="rank-table2"
        :data="listData"
        :default-sort="{prop: 'amount', order: 'descending'}">
        <el-table-column
          align="center"
          label="序号"
          width="48">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          label="区域"
          width="70"
          align="center"
          prop="name" />
        <el-table-column
          width="68"
          label="数量"
          align="center"
          prop="amount"
          sortable>
          <template slot-scope="scope">
            {{ `${scope.row.amount + ' ' + suffix}` }}
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex;align-items: center;justify-content:center;" v-if="isChengdu">
        <dv-active-ring-chart
          :key="key"
          :config="installNumConfig"
          class="region-compare-chart"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import countTo from "vue-count-to";
import vueSeamlessScroll from 'vue-seamless-scroll'
import {
  dateFormater,
  isEmptyObj,
  getUserInfo
} from '@/util/index'

export default {
  name: 'keyIndicatorRank',
  props: {
    sumData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    eventData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    show: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: ''
    }
  },
  components: {
    countTo, vueSeamlessScroll
  },
  computed: {
    optionHover() {
      return {
        limitMoveNum: 3,
        step: 0.3, // 数值越大速度滚动越快
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  data() {
    return {
      listData: [],
      suffix: '',
      rankData: undefined,
      isChengdu: false,
      isData: false,
      alarmData: [],
      pagedData: [],
      total: 0,
      selectType: '1',
      currentPage: 1,
      hiddenPage: false,
      handleHealthData: [], //健康码待处理 执法中
      handleFinishData: [], //整改成功
      handleOnData: [], //整改中
      healthCodeData: [], //待整改
      key: 1,
      installNumConfig: null,
      config: {
        radius: "65%",
        activeRadius: "80%",
        lineWidth: 20,
        digitalFlopStyle: {
          fontSize: 12,
          fill: "#fff",
        },
        data: [],
      },
    }
  },
  created() {
    this.user = getUserInfo()
    if (this.user.Info && this.user.Info.pid && this.user.Info.pid.slice(0,6) == "/1199/") {
      this.isChengdu = true
    }
  },
  mounted() {
    this.getData()
    moment.locale('zh-cn');
    var _this = this
    window.addEventListener('resize', function(e){
      _this.$nextTick(()=>{
        _this.key++
      })
    })
  },
  watch: {
    sumData: {
      handler: function () {
        this.isData = false
        this.getData()
      },
      deep: true,
      immediate: true
    },
    eventData: {
      handler: function () {
        this.isData = false
        this.getData()
      },
      deep: true,
      immediate: true
    },
    type: {
      handler: function (data) {
        this.isData = false
        this.getData()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getinstallConfig() {
      const config = JSON.parse(JSON.stringify(this.config));
      const device = this.sumData.streetData
        ? JSON.parse(JSON.stringify(this.sumData.streetData))
        : [];
      if (this.type === '红码') {
        config.data = device.map((item) => {
          item.value = item.RedNum;
          item.name = item.Name;
          return item;
        });
      } else if (this.type === '黄码') {
        config.data = device.map((item) => {
          item.value = item.YellowNum;
          item.name = item.Name;
          return item;
        });
      } else if (this.type === '绿码') {
        config.data = device.map((item) => {
          item.value = item.GreenNum;
          item.name = item.Name;
          return item;
        });
      } else {
        return
      }
      config.color = [
        "#3f8ffd",
        "#11c2c1",
        "#1a419d",
        "#2ee3ff",
        "#6235ff",
        "#b620e0",
        "#eb2f95",
        "#ef4764",
        "#6235ff",
        "#ff8c00"
      ]
      config.lineWidth = 16
      this.installNumConfig = config;
      this.key++;
    },
    getData() {
      if (this.type) {
        if (this.type == "待整改" || this.type == "整改中" || this.type == "整改成功" || this.type == "执法中") {
          if (isEmptyObj(this.eventData)) {
            return
          }
          if (this.type == "待整改") {
            this.selectType = "1"
          } else if (this.type == "整改中") {
            this.selectType = "2"
          } else if (this.type == "整改成功") {
            this.selectType = "3"
          } else {
            this.selectType = "4"
          }
          this.isData = true
          this.handleHealthData = this.eventData.handle_force_data
          this.handleFinishData = this.eventData.handle_finish_data
          this.handleOnData = this.eventData.handle_on_data
          this.healthCodeData = this.eventData.handle_wait_data
          this.updateData();
        } else {
          if (this.sumData) {
            this.rankData = this.sumData.streetData
            this.init();
          }
        }
      }
    },
    formaterTime(time) {
      return dateFormater(time*1000, false, false)
    },
    changeData(){
      this.updateData()
    },
    switchData(type) {
      this.tabStatus = type
      this.updateData()
    },
    updateData() {
      this.alarmData = []
      this.pagedData = []
      if (this.selectType == 1) {
        this.alarmData = this.healthCodeData
      } else if(this.selectType == 2) {
        this.alarmData = this.handleOnData
      } else if(this.selectType == 3) {
        this.alarmData = this.handleFinishData
      } else {
        this.alarmData = this.handleHealthData
      }
      this.total = this.alarmData.length
      if (this.total <= 10) {
        this.pagedData = this.alarmData
        this.hiddenPage = true
      } else {
        const start = (this.currentPage - 1) * 10;
        const end = start + 10;
        this.pagedData = this.alarmData.slice(start, end);
        this.hiddenPage = false
      }
      this.optionHover.limitMoveNum = this.pagedData.length
      if (this.$refs.eventScroll) {
        this.$refs.eventScroll.reset()
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.updateData();
    },
    init() {
      this.listData = []
      if (this.type === '监测点数') {
        for (let i = 0; i < this.rankData.length; i++) {
          this.listData.push({
            index: i + 1,
            name: this.rankData[i].Name,
            amount: this.rankData[i].OnlineDeviceCount
          })
          this.suffix = ''
        }
      } else if (this.type === '在线率') {
        for (let i = 0; i < this.rankData.length; i++) {
          this.listData.push({
            index: i + 1,
            name: this.rankData[i].Name,
            amount: this.rankData[i].OnlinePercent
          })
          this.suffix = '%'
        }
      } else if (this.type === '达标率') {
        for (let i = 0; i < this.rankData.length; i++) {
          this.listData.push({
            index: i + 1,
            name: this.rankData[i].Name,
            amount: this.rankData[i].NomalPercent
          })
          this.suffix = '%'
        }
      } else if (this.type === '红码') {
        for (let i = 0; i < this.rankData.length; i++) {
          this.listData.push({
            index: i + 1,
            name: this.rankData[i].Name,
            amount: this.rankData[i].RedNum
          })
          this.suffix = ''
        }
        if(this.isChengdu) {
          this.getinstallConfig()
        }
      } else if (this.type === '黄码') {
        for (let i = 0; i < this.rankData.length; i++) {
          this.listData.push({
            index: i + 1,
            name: this.rankData[i].Name,
            amount: this.rankData[i].YellowNum
          })
          this.suffix = ''
        }
        if(this.isChengdu) {
          this.getinstallConfig()
        }
      } else if (this.type === '绿码') {
        for (let i = 0; i < this.rankData.length; i++) {
          this.listData.push({
            index: i + 1,
            name: this.rankData[i].Name,
            amount: this.rankData[i].GreenNum
          })
          this.suffix = ''
        }
        if(this.isChengdu) {
          this.getinstallConfig()
        }
      }
    },
    handleClose() {
      this.$emit("handle-close")
    }
  }
}
</script>

<style lang="scss">
.key-indicator-rank {
  position: absolute;
  left: 445rem;
  top: 156rem;
  z-index: 8;
  background: rgba(21, 55, 135, 0.9);
  .rank-table2 {
    background-color: transparent;
    min-height: 40vh;
    max-height: 57vh;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #3a92ed;
    }
    
    th, tr {
      background: rgba(21, 55, 135, 0.8);
      color: #ffffff;
      font-size: 12px !important;
      font-weight: 400;
    }
  }
  .el-table tbody tr:hover > td {
    background-color:#164184 !important //修改成自己想要的颜色即可
  }
  .el-table::before {
    height: 0px !important;
  }

}

.key-rank-title {
  padding-left: 10rem !important;
  padding-top: 10rem !important;
  font-size: 14px !important;
  padding-bottom: 10rem !important;
}

.region-compare-chart {
  width: 120rem;
  height: 100rem;
}

.key-indicator-chengdu-rank {
  position: absolute;
  left: 445rem;
  top: 156rem;
  z-index: 8;
  background: rgba(19, 31, 53, 0.8);
  .event-message {
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    font-size: 16rem;
    min-height: 40vh;
    max-height: 57vh;
    width: 360rem;
    .el-radio-button__inner:focus.is-checked,
    .el-radio-button__inner.is-checked {
      border-color: #104D88 !important; /* 修改边框颜色 */
      box-shadow: -1px 0 0 0 #104D88 inset !important; /* 修改内阴影颜色 */
      color: #104D88 !important; /* 修改文本颜色 */
      border: 1px solid #42bddc !important;
    }
    
    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background-color: #104D88 !important; /* 修改背景颜色 */
      border-color: #104D88 !important; /* 修改边框颜色 */
      border: 1px solid #42bddc !important;
    }
    .el-radio-button__inner {
      background: #131E36 !important;
      border: 1px solid #42bddc !important;
      color: #fff;
    }
    /deep/ .el-input__inner {
      color: #fff!important;
      border: 1px solid #326AFF;
      background: #04308D !important;
    }
  }

  .event-item{
    background: linear-gradient(#19253e, #223e61);  
    border-radius:16px;
    margin-top:10rem;
    padding-top: 6rem;
    padding-bottom:6 rem;
    font-size:12rem;
    line-height: 28rem;
  }
  .event-item:hover {
    box-shadow: inset 0 0 32px #25a6ff;
  }

  .indexTag{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16rem;
  }

  .indexPage{
    display: flex;
    justify-content: center;
    margin-top:12rem;
    color: #fff;
    font-size: 12rem;
    width: 360rem;
    .el-pagination {
      font-size: 12px !important;
      font-weight: 400 !important;
      .el-pagination__total{
        font-size: 12px !important;
        color:#f1f1f1 !important;
      }
      .el-pagination__jump{
        color:#f1f1f1 !important;
        font-size: 12px !important;
      }
    }
  }


  .rank-table2 {
    background-color: transparent;
    min-height: 40vh;
    max-height: 57vh;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #223e61;
    }
    th, tr {
      background: linear-gradient(#19253e, #223e61);
      color: #ffffff;
      font-size: 12px !important;
      font-weight: 400;
    }
  }
  .el-table::before {
    height: 0px !important;
  }
  .el-table tbody tr:hover>td {
    background-color:#19253e !important
  }
}




</style>
