import smallGreen from '@/asset/img/MapIcon/SmallGreen.png'
import smallRed from '@/asset/img/MapIcon/SmallRed.png'
import exceedOrange from '@/asset/img/MapIcon/ExceedOrange.png'
import smallYellow from '@/asset/img/MapIcon/SmallYellow.png'
import smallGrey from '@/asset/img/MapIcon/SmallGrey.png'
import smallBlack from '@/asset/img/MapIcon/SmallBlack.png'
import mediumGreen from '@/asset/img/MapIcon/MediumGreen.png'
import mediumRed from '@/asset/img/MapIcon/MediumRed.png'
import mediumYellow from '@/asset/img/MapIcon/MediumYellow.png'
import mediumGrey from '@/asset/img/MapIcon/MediumGrey.png'
import mediumBlack from '@/asset/img/MapIcon/MediumBlack.png'
import bigGreen from '@/asset/img/MapIcon/BigGreen.png'
import bigRed from '@/asset/img/MapIcon/BigRed.png'
import bigYellow from '@/asset/img/MapIcon/BigYellow.png'
import bigGrey from '@/asset/img/MapIcon/BigGrey.png'
import bigBlack from '@/asset/img/MapIcon/BigBlack.png'

export function getLabelPosition(center, scale, index) {
  const _scale = 4096
  let k = scale / _scale
  if (k < 1) {
    k *= (1 - k) * 1.5 // 调整缩放比例，以防过小，大于1不需要调整（应该
  }
  const X_outermost = 5.2 * k
  const Y_outermost = 2.0 * k
  const Y_outermost_offset = 0.66 * k
  const X_outer_2nd = 4.0 * k
  const Y_outer_2nd = 2.3 * k
  const X_outer_3rd = 2.8 * k
  const Y_outer_3rd = 2.4 * k
  const X_outer_4th = 1.6 * k
  const Y_outer_4th = Y_outer_3rd
  if (index < 7) {
    return [center[0] - X_outermost, center[1] + Y_outermost - (index * Y_outermost_offset)]
  } else if (index >= 7 && index < 14) {
    index = index - 7
    return [center[0] + X_outermost, center[1] + Y_outermost - (index * Y_outermost_offset)]
  } else if (index >= 14 && index < 16) {
    index = index - 14
    return [center[0] - X_outer_2nd, center[1] + Y_outer_2nd * (index % 2 ? 1 : -1)]
  } else if (index >= 16 && index < 18) {
    index = index - 18
    return [center[0] + X_outer_2nd, center[1] + Y_outer_2nd * (index % 2 ? 1 : -1)]
  } else if (index >= 18 && index < 20) {
    return [center[0] + X_outer_3rd * (index % 2 ? 1 : -1), center[1] + Y_outer_3rd]
  } else if (index >= 20 && index < 22) {
    return [center[0] + X_outer_3rd * (index % 2 ? 1 : -1), center[1] - Y_outer_3rd]
  } else if (index >= 22 && index < 25) {
    return [center[0] + X_outer_4th * (index % 2 ? 1 : -1), center[1] + Y_outer_4th]
  } else if (index >= 25 && index < 27) {
    return [center[0] + X_outer_4th * (index % 2 ? 1 : -1), center[1] - Y_outer_4th]
  }
}

const iconSize = new AMap.Size(12, 12)
const largeIconSize = new AMap.Size(14, 14)
const smallGreenIcon = new AMap.Icon({
  size: iconSize,
  image: smallGreen,
  imageSize: iconSize
})

const smallRedIcon = new AMap.Icon({
  size: iconSize,
  image: smallRed,
  imageSize: iconSize
})

const exceedOrangeIcon = new AMap.Icon({
  size: largeIconSize,
  image: exceedOrange,
  imageSize: largeIconSize
})

const smallYellowIcon = new AMap.Icon({
  size: largeIconSize,
  image: smallYellow,
  imageSize: largeIconSize
})
const smallGreyIcon = new AMap.Icon({
  size: largeIconSize,
  image: smallGrey,
  imageSize: largeIconSize
})
const smallBlackIcon = new AMap.Icon({
  size: largeIconSize,
  image: smallBlack,
  imageSize: largeIconSize
})

const bigGreenIcon = new AMap.Icon({
  size: largeIconSize,
  image: bigGreen,
  imageSize: largeIconSize
})

const bigRedIcon = new AMap.Icon({
  size: largeIconSize,
  image: bigRed,
  imageSize: largeIconSize
})
const bigYellowIcon = new AMap.Icon({
  size: largeIconSize,
  image: bigYellow,
  imageSize: largeIconSize
})
const bigGreyIcon = new AMap.Icon({
  size: largeIconSize,
  image: bigGrey,
  imageSize: largeIconSize
})
const bigBlackIcon = new AMap.Icon({
  size: largeIconSize,
  image: bigBlack,
  imageSize: largeIconSize
})

const mediumGreenIcon = new AMap.Icon({
  size: iconSize,
  image: mediumGreen,
  imageSize: iconSize
})

const mediumRedIcon = new AMap.Icon({
  size: iconSize,
  image: mediumRed,
  imageSize: iconSize
})
const mediumYellowIcon = new AMap.Icon({
  size: largeIconSize,
  image: mediumYellow,
  imageSize: largeIconSize
})
const mediumGreyIcon = new AMap.Icon({
  size: largeIconSize,
  image: mediumGrey,
  imageSize: largeIconSize
})
const mediumBlackIcon = new AMap.Icon({
  size: largeIconSize,
  image: mediumBlack,
  imageSize: largeIconSize
})

export const iconListSvgPath = {
  big: 'path://M25,0l6.25,18.594L50,19.1l-14.887,12L40.451,50,25,38.82,9.549,50l5.338-18.906L0,19.1l18.75-.5Z',
  medium: 'path://M25.000,0.000 L50.000,50.000 L-0.000,50.000 L25.000,0.000 Z',
  small: 'path://M25.000,0.000 C38.807,0.000 50.000,11.193 50.000,25.000 C50.000,38.807 38.807,50.000 25.000,50.000 C11.193,50.000 0.000,38.807 0.000,25.000 C0.000,11.193 11.193,0.000 25.000,0.000 Z'
}

export const iconList = {
  exceed: exceedOrangeIcon,
  big: [bigRedIcon, bigGreenIcon, bigYellowIcon, bigGreyIcon, bigBlackIcon],
  medium: [mediumRedIcon, mediumGreenIcon, mediumYellowIcon, mediumGreyIcon, mediumBlackIcon],
  small: [smallRedIcon, smallGreenIcon, smallYellowIcon, smallGreyIcon, smallBlackIcon]
}

export const cityData = [
  {
    label: '成都市',
    level: 'city',
    value: '成都市',
    coordinate: [103.9, 30.77],
    zoom: 9,
    code: '510100'
  }, {
    label: '天府新区',
    level: 'district',
    value: '天府新区',
    coordinate: [104.105458654939, 30.4],
    zoom: 11.2,
    code: 'tfxq'
  }, {
    label: '高新东区',
    level: 'district',
    value: '高新东区',
    coordinate: [104.35539761978275, 30.31],
    zoom: 11.8,
    code: 'gxdq'
  }, {
    label: '高新南区',
    level: 'district',
    value: '高新南区',
    coordinate: [104.05761649614874, 30.582],
    zoom: 12.8,
    code: 'gxnq'
  }, {
    label: '高新西区',
    level: 'district',
    value: '高新西区',
    coordinate: [103.914, 30.765],
    zoom: 13.2,
    code: 'gxxq'
  }, {
    label: '简阳市',
    level: 'district',
    value: '简阳市',
    coordinate: [104.55588774472115, 30.37],
    zoom: 10.6,
    code: '510185'
  }, {
    label: '金堂县',
    level: 'district',
    value: '金堂县',
    coordinate: [104.61904144114612, 30.715],
    zoom: 10.8,
    code: '510121'
  }, {
    label: '青白江区',
    level: 'district',
    value: '青白江区',
    coordinate: [104.32836141376559, 30.785],
    zoom: 11.7,
    code: '510113'
  }, {
    label: '龙泉驿区',
    level: 'district',
    value: '龙泉驿区',
    coordinate: [104.28077986166474, 30.595],
    zoom: 11.7,
    code: '510112'
  }, {
    label: '新都区',
    level: 'district',
    value: '新都区',
    coordinate: [104.1266924921273, 30.823],
    zoom: 11.5,
    code: '510114'
  }, {
    label: '金牛区',
    level: 'district',
    value: '金牛区',
    coordinate: [104.05315736615307, 30.7385],
    zoom: 12.5,
    code: '510106'
  }, {
    label: '锦江区',
    level: 'district',
    value: '锦江区',
    coordinate: [104.11323478228155, 30.611],
    zoom: 12.75,
    code: '510104'
  }, {
    label: '武侯区',
    level: 'district',
    value: '武侯区',
    coordinate: [104.013, 30.622],
    zoom: 13.1,
    code: '510107'
  }, {
    label: '青羊区',
    level: 'district',
    value: '青羊区',
    coordinate: [103.99032523638311, 30.679426749225662],
    zoom: 13.1,
    code: '510105'
  }, {
    label: '双流区',
    level: 'district',
    value: '双流区',
    coordinate: [103.93814017778936, 30.475],
    zoom: 11.1,
    code: '510116'
  }, {
    label: '新津县',
    level: 'district',
    value: '新津县',
    coordinate: [103.82141044146124, 30.428],
    zoom: 12.1,
    code: '510118'
  }, {
    label: '彭州市',
    level: 'district',
    value: '彭州市',
    coordinate: [103.87381862856397, 31.18],
    zoom: 10.65,
    code: '510182'
  }, {
    label: '郫都区',
    level: 'district',
    value: '郫都区',
    coordinate: [103.87381862856387, 30.844265900830614],
    zoom: 11.8,
    code: '510117'
  }, {
    label: '温江区',
    level: 'district',
    value: '温江区',
    coordinate: [103.8089346938807, 30.749],
    zoom: 11.6,
    code: '510115'
  }, {
    label: '蒲江县',
    level: 'district',
    value: '蒲江县',
    coordinate: [103.49316621175612, 30.22],
    zoom: 11.75,
    code: '510131'
  }, {
    label: '邛崃市',
    level: 'district',
    value: '邛崃市',
    coordinate: [103.416, 30.38],
    zoom: 11.2,
    code: '510183'
  }, {
    label: '大邑县',
    level: 'district',
    value: '大邑县',
    coordinate: [103.375, 30.625],
    zoom: 11.05,
    code: '510129'
  }, {
    label: '崇州市',
    level: 'district',
    value: '崇州市',
    coordinate: [103.476, 30.69],
    zoom: 11.1,
    code: '510184'
  }, {
    label: '都江堰市',
    level: 'district',
    value: '都江堰市',
    coordinate: [103.63211732112838, 31.055],
    zoom: 10.4,
    code: '510181'
  }
]
