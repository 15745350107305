<template>
<div style="height: calc(100% - 45px);">
  <div class="event-message">
    <div size="mini" style="position:relative;display: flex;justify-content: space-between;z-index:10;background-color:#131E36;height: 32px;display: flex;align-items: center;padding-top:8px;padding-bottom: 8px;">
      <el-select style="width: 100px;padding-left:10px;" v-model="selectType" placeholder="请选择" size="mini" @change=changeData()>
        <el-option label="待整改" value="1"></el-option>
        <el-option label="整改中" value="2"></el-option>
        <el-option label="整改成功" value="3"></el-option>
        <el-option label="执法中" value="4"></el-option>
      </el-select>
    </div>
    <div>
    <vue-seamless-scroll :data="alarmData"  :class-option="optionHover" ref="seamlessScroll">
      <div class="event-list" v-loading="dataLoading" element-loading-background="rgb(19, 31, 54)">
        <div class="event-item" v-for="(item, index) in pagedData">
          <el-row>
            <el-col class="indexTag" :span="2">{{index+1}}</el-col>
            <el-col :span="22">
              <el-row>
                <el-col :span="6">
                  <span>事件类型:</span>
                </el-col>
                <el-col :span="18">
                  <span>{{ item.EventTyp }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <span>点位名称:</span>
                </el-col>
                <el-col :span="18">
                  <span>{{ item.LocaleName }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <span>地址:</span>
                </el-col>
                <el-col :span="18">
                  <span>{{ item.Addr }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <span>报警时间</span>
                </el-col>
                <el-col :span="18">
                  <span>{{ formaterTime(item.StartAt) }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <span>派单时间</span>
                </el-col>
                <el-col :span="18">
                  <span>{{ formaterTime(item.CreateAt) }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <span>事件状态</span>
                </el-col>
                <el-col :span="18">
                  <span style="color: red;">{{ item.EventStatus }}</span>
                </el-col>
              </el-row>     
            </el-col>
          </el-row>    
        </div>
      </div>
    </vue-seamless-scroll>
    </div>
  </div>
  <div class="indexPage">
    <el-pagination
      background
      :small="small"
      @current-change="handleCurrentChange"
      :hide-on-single-page="hiddenPage"
      :current-page.sync="currentPage"
      :page-size="10"
      layout="total, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</div>
</template>

<script>
import moment from 'moment'
import vueSeamlessScroll from 'vue-seamless-scroll'
import {
  dateFormater
} from '@/util/index'
export default {
  name: 'eventData',
  props: {
    eventData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    vueSeamlessScroll
  },
  data() {
    return {
      small: true,
      dataLoading: true,
      alarmData: [],
      pagedData: [],
      total: 0,
      selectType: '1',
      currentPage: 1,
      hiddenPage: false,
      handleHealthData: [], //健康码待处理 执法中
      handleRecodeData: [], //报警事件待处理  执法中
      handleFinishData: [], //整改成功
      handleOnData: [], //整改中
      healthCodeData: [], //待整改
    }
  },
  mounted() {
    this.getData()
    moment.locale('zh-cn');
  },
  watch: {
    eventData: {
      handler: function () {
        this.getData()
      },
      deep: true
    },
  },
  computed: {
    noMore() {
      return this.count >= this.alarmData.length
    },
    optionHover() {
      return {
        limitMoveNum: 3,
        step: 0.25, // 数值越大速度滚动越快
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },

  methods: {
    formaterTime(time) {
      return dateFormater(time*1000, false, false)
    },
    changeData(){
      this.updateData()
    },
    switchData(type) {
      this.tabStatus = type
      this.updateData()
    },

    getData() {
      this.dataLoading = true
      if (this.eventData) {
        this.handleHealthData = this.eventData.handle_force_data
        this.handleFinishData = this.eventData.handle_finish_data
        this.handleOnData = this.eventData.handle_on_data
        this.healthCodeData = this.eventData.handle_wait_data
        this.updateData();
        this.dataLoading = false
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.updateData();
    },
    updateData() {
      this.alarmData = []
      this.pagedData = []
      if (this.selectType == 1) {
        this.alarmData = this.healthCodeData
      } else if(this.selectType == 2) {
        this.alarmData = this.handleOnData
      } else if(this.selectType == 3) {
        this.alarmData = this.handleFinishData
      } else {
        this.alarmData = this.handleHealthData
      }
      this.total = this.alarmData.length
      if (this.total <= 10) {
        this.pagedData = this.alarmData
        this.hiddenPage = true
      } else {
        const start = (this.currentPage - 1) * 10;
        const end = start + 10;
        this.pagedData = this.alarmData.slice(start, end);
        this.hiddenPage = false
      }
      console.log(this.pagedData)
      this.optionHover.limitMoveNum = this.pagedData.length
      if (this.$refs.seamlessScroll) {
        this.$refs.seamlessScroll.reset()
      }
    },
  }
}
</script>

<style lang="scss">
.event-message {
  box-sizing: border-box;
  width: 100%;
  z-index: 5;
  overflow: hidden;
  font-size: 16rem;
  min-height: 40vh;
  max-height: 57vh;
  .event-item{
    background: linear-gradient(#19253e, #223e61);  
    border-radius:16px;
    margin-top:10rem;
    padding-top: 6rem;
    padding-bottom:6 rem;
    font-size:12rem;
    line-height: 28rem;
  }
  .event-item:hover {
    box-shadow: inset 0 0 32px #25a6ff;
  }

  .indexTag{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16rem;
    height: 170rem;
  }
}

.indexPage{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:12rem;
  color: #fff;
  font-size: 12rem;
  .el-pagination {
    font-size: 12px !important;
    font-weight: 400 !important;
    .el-pagination__total{
      font-size: 12px !important;
      color:#f1f1f1 !important;
    }
    .el-pagination__jump{
      color:#f1f1f1 !important;
      font-size: 12px !important;
    }
  }
}
</style>
