<template>
  <div class="health-code">
    <el-row>
      <div v-if="isChengdu" style="font-size: 16rem;font-weight:bold;float:left;  display: flex;align-items: center;height:40rem;"><img style="width:20rem;margin-right: 3rem;padding-left:10rem;" src="./img/left.png" /><span>油烟健康码</span></div>
      <div v-else style="font-size: 16rem;font-weight:bold;float:left;  display: flex;align-items: center;height:40rem;"><img style="width:20rem;margin-right: 3rem;padding-left:10rem;" src="./img/scan.png" /><span>油烟健康码</span></div>
    </el-row>
    <template v-if="isChengdu">
      <el-row class="box" style="text-align: center" v-loading="loading" element-loading-background="rgb(19, 31, 54)">
      <el-col :span="8">
        <div class="code-data" @click="handleTypeChange('2')">
          <div>绿码数</div>
          <template v-if ="greenNum == 0">
            <div style="color: #06b204;font-weight:bold;font-size: 20rem;text-align: center">{{greenNum}}</div>
          </template>
          <template v-else>
            <count-to :startVal='0'
              :endVal='greenNum'
              :duration='greenNum'
              :separator='null'
              style="color: #06b204;font-weight:bold;font-size: 20rem;text-align: center"></count-to>
          </template>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="code-data" @click="handleTypeChange('8')">
          <div>黄码数</div>
          <template v-if ="yellowNum == 0">
            <div style="color: yellow;font-weight:bold;font-size: 20rem;text-align: center">{{yellowNum}}</div>
          </template>
          <template v-else>
            <count-to :startVal='0'
              :endVal='yellowNum'
              :duration='yellowNum'
              :separator='null'
              style="color: yellow;font-weight:bold;font-size: 20rem;text-align: center"></count-to>
          </template>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="code-data" @click="handleTypeChange('1')">
          <div>红码数</div>
          <template v-if ="redNum == 0">
            <div style="color: red;font-weight:bold;font-size: 20rem;text-align: center">{{redNum}}</div>
          </template>
          <template v-else>
            <count-to :startVal='0'
              :endVal='redNum'
              :duration='redNum'
              :separator='null'
              style="color: red;font-weight:bold;font-size: 20rem;text-align: center"></count-to>
          </template>
        </div>
      </el-col>
    </el-row>

    </template>
    <template v-else>
      <el-row style="text-align: center" v-loading="loading" element-loading-background="rgb(44, 75, 147)">
        <el-col :span="8">
          <div class="code-data" @click="handleTypeChange('2')">
            <div>绿码数</div>
            <template v-if ="greenNum == 0">
              <div style="color: #06b204;font-weight:bold;font-size: 20rem;text-align: center">{{greenNum}}</div>
            </template>
            <template v-else>
              <count-to :startVal='0'
                :endVal='greenNum'
                :duration='greenNum'
                :separator='null'
                style="color: #06b204;font-weight:bold;font-size: 20rem;text-align: center"></count-to>
            </template>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="code-data" @click="handleTypeChange('8')">
            <div>黄码数</div>
            <template v-if ="yellowNum == 0">
              <div style="color: yellow;font-weight:bold;font-size: 20rem;text-align: center">{{yellowNum}}</div>
            </template>
            <template v-else>
              <count-to :startVal='0'
                :endVal='yellowNum'
                :duration='yellowNum'
                :separator='null'
                style="color: yellow;font-weight:bold;font-size: 20rem;text-align: center"></count-to>
            </template>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="code-data" @click="handleTypeChange('1')">
            <div>红码数</div>
            <template v-if ="redNum == 0">
              <div style="color: red;font-weight:bold;font-size: 20rem;text-align: center">{{redNum}}</div>
            </template>
            <template v-else>
              <count-to :startVal='0'
                :endVal='redNum'
                :duration='redNum'
                :separator='null'
                style="color: red;font-weight:bold;font-size: 20rem;text-align: center"></count-to>
            </template>
          </div>
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import countTo from "vue-count-to";
import {
  getUserInfo
} from '@/util/index'
export default {
  name: 'healthCode',
  props: {
    sumData: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  watch: {
    sumData: {
      handler: function () {
        this.getData()
      },
      deep: true
    }
  },
  components: {
    countTo
  },
  data() {
    return {
      greenNum: 0,
      redNum: 0,
      yellowNum: 0,
      dotData: undefined,
      loading: true,
      showRed: false,
      showGreen: false,
      showYellow: false,
      isChengdu: false,
    }
  },
  created() {
    this.user = getUserInfo()
    if (this.user.Info && this.user.Info.pid && this.user.Info.pid.slice(0,6) == "/1199/") {
      this.isChengdu = true
    }
  },
  mounted() {
    this.getData();
    moment.locale('zh-cn');
  },
  methods: {
    getData() {
      this.loading = true
      if (this.sumData) {
        this.dotData = this.sumData.streetData
        this.init();
        this.loading = false
      }
    },
    init() {
      this.showRed = false,
      this.showGreen = false,
      this.showYellow = false,
      this.greenNum = 0
      this.redNum = 0
      this.yellowNum = 0
      for (let i = 0; i < this.dotData.length; i++) {
        const item = this.dotData[i]
        this.redNum += item.RedNum
        this.greenNum += item.GreenNum
        this.yellowNum += item.YellowNum
      }
      if (!this.dotData || this.dotData.length === 0) {
        return false
      }
    },
    handleTypeChange(type) {
      if (type == '1') {
        this.showRed = !this.showRed
        this.showGreen = false
        this.showYellow = false
        if (!this.showRed) {
          type = '0'
        }
      }
      if (type == '2') {
        this.showGreen = !this.showGreen
        this.showRed = false
         this.showYellow = false
        if (!this.showGreen) {
          type = '0'
        }
      }
      if (type == '8') {
        this.showYellow = !this.showYellow
        this.showGreen = false
        this.showRed = false
        if (!this.showYellow) {
          type = '0'
        }
      }
      this.$emit('type-change', type)
    }
  }
}
</script>

<style lang="scss">
.health-code {
  box-sizing: border-box;
  width: 100%;
  z-index: 5;
  font-size: 16rem;
  margin-top:6rem;
}

.code-title {
  padding-left: 20px;
  font-size: 16rem;
  font-weight:bold;
}

.code-data {
  line-height: 2.8vh;
  font-size: 14rem;
  padding-bottom: 12rem;
}

.box{
  background: linear-gradient(#19253e, #223e61);  
  border-radius:16px;
}
.box:hover {
  box-shadow: inset 0 0 32px #25a6ff;
}
</style>
